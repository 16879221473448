import { AddDataSource, SampleDataIcon } from "../../../../assets/images";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";

import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import AddColumnIcon from "../../../../assets/svgs/AddColumnSvg.js";
import AddDatasetSvg from "../../../../assets/svgs/AddDatasetSvg.js";
import AppendDataSvg from "../../../../assets/svgs/AppendDataSvg.js";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CommaIcon from "../../../../assets/svgs/CommaSvg.js";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DataviewIcon from "../../../../assets/svgs/DataviewSvg.js";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo/DemoContainer.js";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ExcelIcon from "../../../../assets/svgs/Excelsvg";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import NewReportIcon from "../../../../assets/svgs/NewReportSvg.js";
import PageColorIcon from "../../../../assets/svgs/PageColorSvg.js";
import PageMarginIcon from "../../../../assets/svgs/PageMarginSvg.js";
import PageSizeIcon from "../../../../assets/svgs/PageSizeSvg.js";
import Pagination from "../../../../components/Pagination/Pagination.jsx";
import PercentageIcon from "../../../../assets/svgs/PercentageSvg.js";
import PostAddIcon from "@mui/icons-material/PostAdd";
import React, { useMemo } from "react";
import RelationIcon from "../../../../assets/svgs/RelationSvg.js";
import ReportIcon from "../../../../assets/svgs/ReportSvg.js";
import ReportOpen from "../../../../assets/svgs/Report_open_icon.js";
import SQLIcon from "../../../../assets/svgs/Sqlsvg";
import SaveIcon from "../../../../assets/svgs/SaveSvg.js";
import SaveasIcon from "../../../../assets/svgs/SaveasSvg.js";
import ScheduleReportIcon from "../../../../assets/svgs/ScheduleReportSvg.js";
import SearchIcon from "@mui/icons-material/Search";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import Tab from "@mui/material/Tab";
import TableChartIcon from "@mui/icons-material/TableChart";
import Tabs from "@mui/material/Tabs";
import { Tooltip } from "@mui/material";
import dayjs from "dayjs";
import classNames from "classnames";
import useGetPermissions from "../../../../hooks/useGetPermissions.js";
import ViewQuerySvg from '../../../../assets/svgs/ViewQuerySvg.js'
import { usePermissionsStore } from "../../../../context/permissions.store";

function Menu({
  activeNavItem,
  selectedComponent,
  isTables,
  fetchTableData,
  count,
  selectedColumn,
  setSelectedColumn,
  isExpand,
  setIsExpand,
  onPropertyChange,
  setSelectedComponent,
  handleNewColumn,
  handleAddDataColumn,
  showAlert,
  hadleSaveHit,
  handleAppendData,
  handleUploadExcelFile,
  isReportBuilder,
  setIsnewReport,
  reportId,
  setIsSaveAsReport,
  toggleDrawer,
  handleNewReport,
  handleSaveHit,
  reportProperty,
  setReportProperty,
  pageSizes,
  marginOptions,
  exportPDF,
  hasValidData,
  islistViewScreen,
  filteredReport,
  components,
  reports,
  dateformat,
  handleReportGet,
  setDateRMenu,
  dateRMenu,
  setDateRangeMenu,
  dateRangeMenu,
  setSearchValue,
  searchValue,
  dateOptions,
  handleViewFilteredReport,
  dateColumn,
  handleListViewFilter,
  listViewData,
  tables,
  handleAddNewDatabaseConnection,
  setActiveNavItem,
  handleAddDataset,
  superset,
  isDashboardBuilder,
  exportDashboardPdf,
  setIsQueryModalOpen,
  isTableloading
}) {
  const {
    hasDBCreationPermission,
    hasDataviewCreationPermission,
    hasDatasourceCreationPermission,
  } = useGetPermissions();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [formatdropdownOpen, setFormatDropdownOpen] = React.useState(false);
  const [newcolumndropdown, setNewcolumndropdown] = React.useState(false);
  const [showPageSizeDropdown, setShowPageSizeDropdown] = React.useState(false);
  const [dataTypeDropdownOpen, setDataTypeDropdownOpen] = React.useState(false);
  const [showMarginDropdown, setShowMarginDropdown] = React.useState(false);
  const [message, setMessage] = React.useState("Something went wrong");
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("error");
  const dataTypeDropdownRef = React.useRef();
  const [tablename, setTableName] = React.useState("");
  const dropdownRef = React.useRef(null);
  const isDropdownRef = React.useRef(null);
  const formatDropdownRef = React.useRef(null);
  const newcolumnDropdownRef = React.useRef(null);
  const pageSizeDropdownRef = React.useRef(null);
  const pageMarginDropdownRef = React.useRef(null);
  const EnableIconColor = "#075985";
  const DisableIconColor = "#c5c8cb";
  const fileInputRef = React.useRef(null);
  const fileInputRefY = React.useRef(null);
  const [isApplied, setIsApplied] = React.useState(false);
  const [limitDropdown, setLimitDropdown] = React.useState(false);
  const [selectedLimit, setSelectedLimit] = React.useState("");
  const limitOptions = [10, 100, 1000, 10000];
  const limitDropdownRef = React.useRef(null);

  const FormatIntegeroption = [
    "General",
    "Currency",
    "Whole Number",
    "Decimal",
    "Percentage",
  ];

  const currencyOptions = [
    { name: "US Dollar", symbol: "$" },
    { name: "Euro", symbol: "€" },
    { name: "Japanese Yen", symbol: "¥" },
    { name: "Pound Sterling", symbol: "£" },
    { name: "Australian Dollar", symbol: "A$" },
    { name: "Canadian Dollar", symbol: "C$" },
    { name: "Swiss Franc", symbol: "CHF" },
    { name: "Chinese Renminbi", symbol: "¥" },
    { name: "Indian Rupees", symbol: "₹" },
  ];
  // const dateformat = [
  //     { format: "General Date" },
  //     { format: "Long Date" },
  //     { format: "MM/DD/YYYY" },
  //     { format: "DD/MM/YYYY" },
  //     { format: "YYYY-MM-DD" },
  //     { format: "YYYY-MM" }
  // ]

  // const pageSizes = [
  //     { name: "Letter", width: "21.59", height: "27.94" },
  //     { name: "Legal", width: "21.59", height: "35.56" },
  //     { name: "A3", width: "29.7", height: "42.0" },
  //     { name: "A4", width: "21.0", height: "29.7" },
  //     { name: "A5", width: "14.8", height: "21.0" }
  // ];
  // const marginOptions = [
  //     { name: "Normal", top: "2.54", bottom: "2.54", left: "2.54", right: "2.54" },
  //     { name: "Narrow", top: "1.27", bottom: "1.27", left: "1.27", right: "1.27" },
  //     { name: "Moderate", top: "2.54", bottom: "2.54", left: "1.91", right: "1.91" },
  //     { name: "Wide", top: "2.54", bottom: "2.54", left: "5.08", right: "5.08" },
  // ];
  const today = dayjs();
  const isCurrencySelected =
    selectedComponent?.columnProperties?.find(
      (item) => item?.name === selectedColumn?.name
    )?.format === "Currency";

  const isPercentageSelected =
    selectedComponent?.columnProperties?.find(
      (item) => item?.name === selectedColumn?.name
    )?.format === "Percentage";

  const toggleDropdownforcurrency = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setFormatDropdownOpen(false);
  };
  const toggleMenu = () => {
    setIsExpand(!isExpand);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const togglenewcolumndropdown = () => {
    setNewcolumndropdown(!newcolumndropdown);
    // if (newColumn?.status === true) {
    //     setNewcolumndropdown(false);
    // }
  };
  const truncateString = (str, maxLength) => {
    if (!str || str?.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + "...";
  };

  const handleFormatChange = (event) => {
    const value = event?.target?.value;
    const isCurrency = value === "Currency";
    const isPercentage = value === "Percentage";
    const isDecimal = value === "Decimal";
    const newColPro = selectedComponent?.columnProperties?.map((item) => {
      if (item?.name === selectedColumn?.name) {
        return {
          ...item,
          format: value,
          isPercentage,
          currency: isCurrency ? item?.currency : "",
          decimal: isDecimal ? 2 : 0,
          isComma:
            value === "Whole Number" ||
              value === "Percentage" ||
              value === "Decimal" ||
              value === "Currency"
              ? item?.isComma
              : false,
        };
      }
      return item;
    });
    setSelectedComponent({
      ...selectedComponent,
      columnProperties: newColPro,
    });
    onPropertyChange({ columnProperties: newColPro }, null);
    setFormatDropdownOpen(false);
  };

  const handleCommaClick = () => {
    const newColPro = selectedComponent?.columnProperties?.map((item) => {
      if (item?.name === selectedColumn?.name) {
        return {
          ...item,
          format:
            !item?.format || item?.format === "" || item?.format === "General"
              ? "Whole Number"
              : item?.format,
          isComma: true,
        };
      }
      return item;
    });

    setSelectedComponent({
      ...selectedComponent,
      columnProperties: newColPro,
    });
    onPropertyChange({ columnProperties: newColPro }, null);
  };

  const handleCurrencySelect = (currency) => {
    const newColPro = selectedComponent?.columnProperties?.map((item) => {
      if (item?.name === selectedColumn?.name) {
        return {
          ...item,
          currency: `${currency?.symbol}`,
          format: "Currency",
          decimal: 0,
        };
      }
      return item;
    });

    setSelectedComponent({
      ...selectedComponent,
      columnProperties: newColPro,
    });

    onPropertyChange({ columnProperties: newColPro }, null);
    setIsDropdownOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (
      dropdownRef?.current &&
      !dropdownRef?.current?.contains(event?.target)
    ) {
      setDropdownOpen(false);
    }
    if (
      isDropdownRef?.current &&
      !isDropdownRef?.current?.contains(event?.target)
    ) {
      setIsDropdownOpen(false);
    }
    if (
      formatDropdownRef?.current &&
      !formatDropdownRef?.current?.contains(event?.target)
    ) {
      setFormatDropdownOpen(false);
    }
    if (
      newcolumnDropdownRef?.current &&
      !newcolumnDropdownRef?.current?.contains(event?.target)
    ) {
      setNewcolumndropdown(false);
    }
    if (
      limitDropdownRef?.current &&
      !limitDropdownRef?.current?.contains(event?.target)
    ) {
      setLimitDropdown(false);
    }
    if (
      pageSizeDropdownRef?.current &&
      !pageSizeDropdownRef?.current?.contains(event?.target)
    ) {
      setShowPageSizeDropdown(false);
    }
    if (
      pageMarginDropdownRef?.current &&
      !pageMarginDropdownRef?.current?.contains(event?.target)
    ) {
      setShowMarginDropdown(false);
    }
    if (
      dataTypeDropdownRef?.current &&
      !dataTypeDropdownRef?.current?.contains(event?.target)
    ) {
      setDataTypeDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // React.useEffect(() => {
  //     if (selectedComponent) {
  //         setTableName(selectedComponent?.new_table_name || selectedComponent?.table_name);
  //     }
  // }, [selectedComponent,]);

  // const handleNameChange = (newName) => {
  //     if (!selectedComponent) return;
  //     // const isDuplicate = components?.some(
  //     //     (component) => component?.new_table_name === newName && component?.id !== selectedComponent?.id
  //     // );

  //     // if (isDuplicate) {
  //     //     showAlert("Table name already exists. Please choose a different name.");
  //     //     return;
  //     // }
  //     // setTableName(newName);
  //     setComponents((prevComponents) => {
  //         const updatedComponents = prevComponents?.map((component) =>
  //             component?.id === selectedComponent?.id
  //                 ? { ...component, new_table_name: newName }
  //                 : component
  //         );
  //         const updatedSelectedComponent = updatedComponents?.find(
  //             (component) => component?.id === selectedComponent?.id
  //         );
  //         setSelectedComponent(updatedSelectedComponent);
  //         return updatedComponents;
  //     });
  // };

  // const handleColumnNameChange = (newColumnName) => {

  //     if (!selectedColumn || !selectedComponent) return;

  //     // const isDuplicate = selectedComponent?.columnProperties?.some(
  //     //     (column) => column?.name === newColumnName && column?.name !== selectedColumn?.name
  //     // );

  //     // if (isDuplicate) {
  //     //     showAlert("Column name already exists. Please choose a different name.");
  //     //     return;
  //     // }

  //     if (selectedColumn && selectedComponent) {
  //         setComponents((prevComponents) => {
  //             const updatedComponents = prevComponents?.map((component) =>
  //                 component?.id === selectedComponent?.id
  //                     ? {
  //                         ...component,
  //                         columnProperties: component?.columnProperties?.map((column) =>
  //                             column?.name === selectedColumn?.name
  //                                 ? {
  //                                     ...column,
  //                                     new_column_name: newColumnName,
  //                                 }
  //                                 : column
  //                         ),
  //                     }
  //                     : component
  //             );

  //             setSelectedComponent({
  //                 ...selectedComponent,
  //                 columnProperties: selectedComponent?.columnProperties?.map((item) => {
  //                     if (item?.name == selectedColumn?.name) {
  //                         return {
  //                             ...item,
  //                             new_column_name: newColumnName,
  //                         }
  //                     }
  //                     return item
  //                 })
  //             })
  //             return updatedComponents;
  //         });
  //     }
  // };

  const handleDateOptionChange = (event, newValue) => {
    if (!islistViewScreen || filteredReport?.status === true) return;
    const selectedValue = newValue;
    let startDate = today;

    switch (selectedValue) {
      case "WTD":
        startDate = today.startOf("week");
        break;
      case "MTD":
        startDate = today.startOf("month");
        break;
      case "YTD":
        startDate = today.startOf("year");
        break;
      default:
        startDate = today;
    }
    setDateRMenu(selectedValue);
    setDateRangeMenu([startDate, today]);
  };

  const handleNameChange = (newName) => {
    if (!selectedComponent) return;
    onPropertyChange(
      {
        new_table_name: newName,
      },
      null
    );

    // Update selectedComponent state directly
    setSelectedComponent((prevSelectedComponent) => ({
      ...prevSelectedComponent,
      new_table_name: newName,
    }));
  };
  const handleColumnNameChange = (newColumnName) => {
    if (!selectedColumn || !selectedComponent) return;
    const updatedColumnProperties = selectedComponent?.columnProperties?.map(
      (column) =>
        column?.name === selectedColumn?.name
          ? { ...column, new_column_name: newColumnName }
          : column
    );
    onPropertyChange(
      {
        columnProperties: updatedColumnProperties,
      },
      null
    );
    setSelectedComponent((prevSelectedComponent) => ({
      ...prevSelectedComponent,
      columnProperties: updatedColumnProperties,
    }));
  };

  const handleColumnTypeChange = (option) => {
    let newType;
    let newFormat = "";

    if (
      selectedColumn?.isDataColumn
        ? selectedColumn?.values
          ? true
          : false
        : true
    ) {
      if (option === "Number" && selectedColumn?.type === "varchar") {
        showAlert("Conversion from 'Text' to 'Number' is not allowed.");
        setDataTypeDropdownOpen(false);
        return;
      }
      if (
        option === "Boolean" &&
        selectedColumn?.type === "varchar" &&
        selectedColumn?.values?.length > 0
      ) {
        showAlert("Conversion from 'Text' to 'Boolean' is not allowed.");
        setDataTypeDropdownOpen(false);
        return;
      }
      if (option === "Date" && selectedColumn?.type === "varchar") {
        showAlert("Conversion from 'Text' to 'Date' is not allowed.");
        setDataTypeDropdownOpen(false);
        return;
      }
      if (option === "Boolean" && selectedColumn?.type === "int") {
        showAlert("Conversion from 'Number' to 'Boolean' is not allowed.");
        setDataTypeDropdownOpen(false);
        return;
      }
      if (option === "Date" && selectedColumn?.type === "int") {
        showAlert("Conversion from 'Number' to 'Date' is not allowed.");
        setDataTypeDropdownOpen(false);
        return;
      }
      if (option === "Number" && selectedColumn?.type === "date") {
        showAlert("Conversion from 'Date' to 'Number' is not allowed.");
        setDataTypeDropdownOpen(false);
        return;
      }
      if (option === "Boolean" && selectedColumn?.type === "date") {
        showAlert("Conversion from 'Date' to 'Boolean' is not allowed.");
        setDataTypeDropdownOpen(false);
        return;
      }
      if (option === "Date" && selectedColumn?.type === "Boolean") {
        showAlert("Conversion from 'Boolean' to 'Date' is not allowed.");
        setDataTypeDropdownOpen(false);
        return;
      }
      if (option === "Number" && selectedColumn?.type === "Boolean") {
        showAlert("Conversion from 'Boolean' to 'Number' is not allowed.");
        setDataTypeDropdownOpen(false);
        return;
      }
    }
    // if (option === 'Text' && selectedColumn?.type === 'Boolean') {
    //     showAlert("Conversion from 'Boolean' to 'Text' is not allowed.");
    //     setDataTypeDropdownOpen(false);
    //     return;
    // }
    if (option === "Number") {
      newType = "int";
      newFormat = "";
    } else if (option === "Boolean") {
      newType = "boolean";
      newFormat = "";
    } else if (option === "Date") {
      newType = "date";
      newFormat = "";
    } else if (option === "Text") {
      newType = "varchar";
      newFormat = "";
    } else {
      newType = "varchar";
    }

    const updatedColumnProperties = selectedComponent?.columnProperties?.map(
      (column) =>
        column?.name === selectedColumn?.name
          ? {
            ...column,
            type: newType,
            format: newFormat,
          }
          : column
    );

    setSelectedComponent((prev) => ({
      ...prev,
      columnProperties: updatedColumnProperties,
    }));
    onPropertyChange(
      {
        columnProperties: updatedColumnProperties,
      },
      null
    );
    setSelectedColumn({ ...selectedColumn, type: newType });
    setDataTypeDropdownOpen(false);
  };

  const cmToPx = (cm) => {
    return (parseFloat(cm) * 37.795275)?.toFixed(0);
  };
  const handlePageSizeSelect = (size) => {
    const widthInPx = cmToPx(size?.width);
    const heightInPx = cmToPx(size?.height);
    setReportProperty({
      ...reportProperty,
      size: {
        name: size?.name,
        width: widthInPx,
        height: heightInPx,
      },
    });
    setShowPageSizeDropdown(false);
  };
  const handleMarginSelection = (selectedMargin) => {
    setReportProperty((prev) => ({
      ...prev,
      margin: {
        name: selectedMargin.name,
        top: (selectedMargin.top * 37.795275).toFixed(0),
        bottom: (selectedMargin.bottom * 37.795275).toFixed(0),
        left: (selectedMargin.left * 37.795275).toFixed(0),
        right: (selectedMargin.right * 37.795275).toFixed(0),
      },
    }));
    setShowMarginDropdown(false);
  };
  const data = components
    ?.map((comp) => comp?.properties?.data)
    .filter(Boolean);
  const validdataforfilter = data?.some(
    (arr) => Array?.isArray(arr) && arr?.length > 0
  );
  const hasDateColumn = () => {
    const selectedTable = tables.find(
      (table) => table.tableMetaData.table_id === listViewData.tableId
    );
    if (selectedTable) {
      return selectedTable.columnMetaData.some(
        (column) =>
          column.column_type === "timestamp with time zone" ||
          column.column_type === "date"
      );
    }
    return false; // No selected table found
  };

  const canViewQuery =
    components?.some(
      (comp) =>
        comp.table_name === selectedComponent?.table_name &&
        comp.datasetProperties !== null
    ) && !isTableloading;

  return (
    <>
      <div
        className={`w-full bg-[#ECF4FA] flex justify-between pl-2 pt-2 ${isExpand ? "h-screen-10 " : "h-screen-5"
          } transition-all`}
        style={{
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 1.95px 2.6px",
        }}
      >
        <span className="flex">
          {activeNavItem === "Home" && (
            <>
              <span
                className={`flex flex-col w-fit ${isExpand ? "justify-between" : ""
                  }`}
              >
                <span className={`flex ${isExpand ? "gap-2" : "gap-0 pb-1"}`}>
                  <span>
                    <Tooltip title={isExpand ? "" : "Paste"}>
                      <ContentPasteIcon
                        className={`disable-icon-color cursor-not-allowed ${isExpand ? "expandedpasteicon" : "collapsedpasteicon"
                          }`}
                      />
                    </Tooltip>
                    {isExpand && (
                      <p className="menu-label-disable m-0">Paste</p>
                    )}
                  </span>
                  {!isExpand && (
                    <span ref={dropdownRef}>
                      <KeyboardArrowDownIcon
                        className="disable-icon-color cursor-pointer"
                        onClick={toggleDropdown}
                      />
                    </span>
                  )}
                  {dropdownOpen && !isExpand && (
                    <div className="absolute top-[120px] z-10 p-2 bg-white border rounded shadow-md">
                      <span className="flex items-center gap-2">
                        <ContentPasteIcon
                          className="disable-icon-color cursor-not-allowed"
                          style={{ width: "21px", height: "21px" }}
                        />
                        <p className="menu-label-disable m-0">Paste</p>
                      </span>
                      <span className="flex items-center gap-2 mt-1">
                        <ContentCopyIcon
                          className="disable-icon-color cursor-not-allowed"
                          style={{ width: "21px", height: "21px" }}
                        />
                        <p className="m-0 menu-label-disable">Copy</p>
                      </span>
                      <span className="flex items-center gap-2 mt-1">
                        <ContentCutIcon
                          className="disable-icon-color cursor-not-allowed"
                          style={{ width: "21px", height: "21px" }}
                        />
                        <p className="m-0 menu-label-disable">Cut</p>
                      </span>
                    </div>
                  )}
                  {isExpand && (
                    <span className="pl-1 flex flex-col gap-1">
                      <span className="flex items-center gap-1">
                        <ContentCopyIcon
                          className="disable-icon-color cursor-not-allowed"
                          style={{ height: "23px", width: "23px" }}
                        />
                        {isExpand && (
                          <p className="menu-label-disable m-0 pt-1">Copy</p>
                        )}
                      </span>
                      <span className="flex items-center gap-1">
                        <ContentCutIcon
                          className="disable-icon-color cursor-not-allowed"
                          style={{ height: "24px", width: "24px" }}
                        />
                        {isExpand && (
                          <p className="menu-label-disable m-0">Cut</p>
                        )}
                      </span>
                    </span>
                  )}
                </span>
                <span className="flex justify-center">
                  {isExpand && <p className="menu-name m-0">Clipboard</p>}
                </span>
              </span>
              <span className="right-line"></span>
            </>
          )}
          {activeNavItem !== "File" &&
            !isReportBuilder &&
            !isDashboardBuilder &&
            activeNavItem !== "Database Connections" && (
              <>
                <span className="flex flex-col w-fit items-center gap-1 pl-1">
                  <span className={`flex gap-3 ${isExpand ? "" : "pb-1"}`}>
                    <span className="flex flex-col items-center">
                      <span
                        className={`${isTables
                          ? "cursor-pointer selectedformat bg-white pl-[1px] pr-[1px] mb-[2px]"
                          : "cursor-not-allowed defaultselectedformat mb-[1px]"
                          }`}
                      >
                        <DataviewIcon
                          height={isExpand ? "27px" : "21px"}
                          width={isExpand ? "27px" : "21px"}
                          tooltipText={isExpand ? "" : "Data View"}
                          fill={isTables ? EnableIconColor : DisableIconColor}
                        />
                      </span>
                      {isExpand && (
                        <span
                          className={` ${isTables ? "menu-label" : "menu-label-disable"
                            } m-0 leading-[12px] max-w-[30px] text-center`}
                        >
                          Data View
                        </span>
                      )}
                    </span>
                    <span className="flex flex-col items-center mt-[2px]">
                      <span
                        // className={`${isTables ? 'cursor-pointer mb-[2px]' : 'cursor-not-allowed mb-[2px]'}`}
                        className="cursor-not-allowed mb-[2px]"
                      >
                        <RelationIcon
                          height={isExpand ? "27px" : "21px"}
                          width={isExpand ? "27px" : "21px"}
                          tooltipText={isExpand ? "" : "Model View"}
                          fill={DisableIconColor}
                        // fill={isTables ? EnableIconColor : DisableIconColor}
                        />
                      </span>
                      {isExpand && (
                        <span
                          // className={` ${isTables ? 'menu-label' : 'menu-label-disable'} m-0 leading-[12px] max-w-[43px] text-center`}
                          className="menu-label-disable m-0 leading-[12px] max-w-[43px] text-center"
                        >
                          Model Relation
                        </span>
                      )}
                    </span>
                    <span
                      // onClick={() => {
                      //     isTables && fileInputRefY?.current && (fileInputRefY.current.value = null, fileInputRefY.current.click());
                      // }}
                      onClick={() => {
                        if (!superset?.isDatasetView && isTables) {
                          fileInputRefY?.current &&
                            ((fileInputRefY.current.value = null),
                              fileInputRefY.current.click());
                        }
                      }}
                      className={`flex items-center ${superset?.isDatasetView || !isTables
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                        } relative ${isExpand ? "flex-col" : "flex-row "}`}
                    >
                      <AppendDataSvg
                        width={isExpand ? 31 : 23}
                        height={isExpand ? 31 : 23}
                        tooltipText={isExpand ? "" : "Append Data"}
                        // fill={
                        //     isTables
                        //         ? "#075985"
                        //         : "#c5c8cb"
                        // }
                        fill={
                          superset?.isDatasetView || !isTables
                            ? "#c5c8cb"
                            : "#075985"
                        }
                      />
                      {isExpand && (
                        <span
                          className={` ${superset?.isDatasetView || !isTables
                            ? "menu-label-disable"
                            : "menu-label"
                            } m-0 leading-[12px] max-w-[42px] text-center flex`}
                        >
                          {" "}
                          Append Data
                        </span>
                      )}
                    </span>
                    <input
                      ref={fileInputRefY}
                      type="file"
                      accept=".csv, .xlsx, .xls"
                      onChange={(event) => {
                        const files = event?.target?.files || [];
                        // handleImportFile(files[0]);
                        // handleUploadExcelFile(files[0]);
                        handleAppendData(files[0]);
                      }}
                      style={{ display: "none" }}
                    />
                  </span>
                  {isExpand && <span className="menu-name m-0">Data</span>}
                </span>
                <span className="right-line"></span>
              </>
            )}
          {(activeNavItem === "Home" ||
            activeNavItem === "Database Connections") &&
            !isReportBuilder &&
            !isDashboardBuilder && (
              <>
                <span className="flex flex-col w-fit items-center justify-between">
                  <span className="flex gap-3">
                    <input
                      disabled={!hasDatasourceCreationPermission}
                      ref={fileInputRef}
                      type="file"
                      accept=".csv, .xlsx, .xls"
                      onChange={(event) => {
                        const files = event?.target?.files || [];
                        // handleImportFile(files[0]);
                        handleUploadExcelFile(files[0]);
                      }}
                      style={{ display: "none" }}
                    />
                    <span
                      className={classNames(
                        "flex flex-col items-center",
                        !hasDatasourceCreationPermission
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      )}
                      onClick={() => {
                        if (!hasDatasourceCreationPermission) return;
                        fileInputRef?.current
                          ? (fileInputRef.current.value = null)
                          : "";
                        fileInputRef?.current?.click();
                      }}
                      style={{
                        display:
                          activeNavItem === "Database Connections"
                            ? "none"
                            : "",
                      }}
                    >
                      <ExcelIcon
                        width={isExpand ? 30 : 23}
                        height={isExpand ? 30 : 23}
                        strokeColor={
                          hasDatasourceCreationPermission
                            ? EnableIconColor
                            : DisableIconColor
                        }
                        tooltipText={isExpand ? "" : "Import Data from File"}
                      />
                      {isExpand && <p className="menu-label m-0">Excel</p>}
                    </span>
                    <span
                      onClick={
                        hasDBCreationPermission
                          ? handleAddNewDatabaseConnection
                          : null
                      }
                      className={classNames(
                        "flex flex-col items-center",
                        hasDBCreationPermission
                          ? "cursor-pointer"
                          : "cursor-not-allowed "
                      )}
                    >
                      <SQLIcon
                        width={isExpand ? 30 : 23}
                        fill={
                          hasDBCreationPermission
                            ? EnableIconColor
                            : DisableIconColor
                        }
                        height={isExpand ? 30 : 23}
                        tooltipText={isExpand ? "" : "Connect Database"}
                      />
                      {isExpand && (
                        <p
                          className={classNames(
                            "m-0 text-center",
                            hasDBCreationPermission
                              ? "menu-label"
                              : "menu-label-disable"
                          )}
                        >
                          Database
                        </p>
                      )}
                    </span>
                    <span
                      // className='flex flex-col items-center cursor-pointer'
                      className="flex flex-col items-center cursor-not-allowed"
                      style={{
                        display:
                          activeNavItem === "Database Connections"
                            ? "none"
                            : "",
                      }}
                    >
                      <AddDataSource
                        width={isExpand ? 30 : 23}
                        height={isExpand ? 30 : 23}
                        // fillColors={[EnableIconColor, EnableIconColor, EnableIconColor]}
                        fillColors={[
                          DisableIconColor,
                          DisableIconColor,
                          DisableIconColor,
                        ]}
                        tooltipText={isExpand ? "" : "Import Data from CareSMS"}
                      />
                      {isExpand && (
                        <p
                          // className="menu-label m-0 text-center"
                          className="menu-label-disable m-0 text-center"
                        >
                          CareSMS
                        </p>
                      )}
                    </span>
                    <span
                      // className='flex flex-col items-center cursor-pointer'
                      className="flex flex-col items-center cursor-not-allowed"
                      style={{
                        display:
                          activeNavItem === "Database Connections"
                            ? "none"
                            : "",
                      }}
                    >
                      <SampleDataIcon
                        width={isExpand ? 30 : 23}
                        height={isExpand ? 32 : 23}
                        // fill={EnableIconColor}
                        fill={DisableIconColor}
                        tooltipText={isExpand ? "" : "Import Data from Sample"}
                      />
                      {isExpand && (
                        <span
                          // className="menu-label m-0 leading-[12px] max-w-[38px] text-center"
                          className="menu-label-disable m-0 leading-[12px] max-w-[38px] text-center"
                        >
                          Sample Data
                        </span>
                      )}
                    </span>
                  </span>
                  {isExpand && <span className="menu-name m-0">Import</span>}
                </span>
              </>
            )}
          {(activeNavItem === "Table Tools" ||
            activeNavItem === "Column Tools") &&
            !superset?.isDatasetView && (
              <span
                className={`flex flex-col w-fit items-center ${isExpand ? "justify-end" : "flex-row"
                  } ${isExpand && activeNavItem === "Column Tools"
                    ? "gap-1"
                    : "gap-4"
                  } `}
              >
                <span
                  className={`flex gap-2 ${isExpand && activeNavItem === "Column Tools"
                    ? "ml-[20px] mb-1"
                    : "ml-0 pb-1"
                    }`}
                >
                  <p className="m-0 menu-label self-center">Name</p>
                  <Tooltip
                    title={
                      activeNavItem === "Table Tools"
                        ? selectedComponent?.new_table_name
                        : selectedComponent?.columnProperties?.find(
                          (item) => item?.name === selectedColumn?.name
                        )?.new_column_name
                    }
                  >
                    <input
                      type="text"
                      disabled={!selectedComponent || superset?.isDatasetView}
                      className="block truncate-name menu-value bg-white inputborder-custome p-[2px] pl-[3px] w-32 focus:outline-none focus:border-gray-600 rounded-[3px]"
                      // value={
                      //     activeNavItem === "Table Tools"
                      //         ? truncateString(selectedComponent?.table_name, 15) || ""
                      //         : truncateString(selectedColumn?.name, 15)
                      // }
                      value={
                        activeNavItem === "Table Tools"
                          ? selectedComponent?.new_table_name
                          : selectedComponent?.columnProperties?.find(
                            (item) => item?.name === selectedColumn?.name
                          )?.new_column_name
                      }
                      onChange={(e) => {
                        if (activeNavItem === "Table Tools") {
                          handleNameChange(e?.target?.value);
                        } else {
                          handleColumnNameChange(e?.target?.value);
                        }
                      }}
                    />
                  </Tooltip>
                </span>
                {activeNavItem === "Column Tools" && (
                  <span className={`flex gap-2 ${isExpand ? "" : "ml-1 pb-1"}`}>
                    <p className="m-0 menu-label self-center w-fit">
                      Data type
                    </p>
                    {/* <input
                                    type="text"
                                    className="block menu-value bg-white inputborder-custome pl-1 w-28 focus:outline-none focus:border-gray-600"
                                    value={selectedColumn?.type === 'INTEGER' ? 'Number'
                                        : selectedColumn?.type === 'DATE' || selectedColumn?.type === 'TIMESTAMP' ? 'Date'
                                            : selectedColumn?.type === 'BOOLEAN' ? 'true/false'
                                                : 'Text'
                                    }
                                /> */}
                    <span className="relative w-32" ref={dataTypeDropdownRef}>
                      <input
                        type="text"
                        readOnly
                        className={`block menu-value p-[2px] pl-[3px] inputborder-custome w-full focus:outline-none  rounded-[3px] ${(selectedColumn?.name === "id" ||
                          selectedColumn?.name?.endsWith("_id")) &&
                          selectedColumn?.type === "int"
                          ? "cursor-default"
                          : " cursor-pointer focus:border-gray-600"
                          }`}
                        value={
                          selectedColumn?.type === "int"
                            ? "Number"
                            : selectedColumn?.type === "boolean"
                              ? "Boolean"
                              : selectedColumn?.type === "date" ||
                                selectedColumn?.type === "timestamp"
                                ? "Date"
                                : "Text"
                        }
                        // onClick={() => setDataTypeDropdownOpen(!dataTypeDropdownOpen)}
                        onClick={() => {
                          if (
                            (selectedColumn?.name === "id" ||
                              selectedColumn?.name?.endsWith("_id")) &&
                            selectedColumn?.type === "int"
                          ) {
                            return;
                          }
                          setDataTypeDropdownOpen(!dataTypeDropdownOpen);
                        }}
                      />
                      <KeyboardArrowDownIcon
                        className={`absolute right-0 top-1/2 transform -translate-y-1/2 ${(selectedColumn?.name === "id" ||
                          selectedColumn?.name?.endsWith("_id")) &&
                          selectedColumn?.type === "int"
                          ? "text-[#c5c8cb] cursor-not-allowed"
                          : "text-black cursor-pointer"
                          }`}
                        // onClick={() => setDataTypeDropdownOpen(!dataTypeDropdownOpen)}
                        onClick={() => {
                          if (
                            (selectedColumn?.name === "id" ||
                              selectedColumn?.name?.endsWith("_id")) &&
                            selectedColumn?.type === "int"
                          ) {
                            return;
                          }
                          setDataTypeDropdownOpen(!dataTypeDropdownOpen);
                        }}
                      />
                      {dataTypeDropdownOpen && (
                        <span
                          className={`absolute z-10 bg-white border border-gray-400 pr-2 pl-2 pb-1 pt-1 rounded-md shadow-md mt-1 w-[113px] flex flex-col`}
                        >
                          {["Number", "Boolean", "Date", "Text"]?.map(
                            (option, index) => (
                              <span
                                key={index}
                                className="cursor-pointer hover:bg-gray-100 menu-value p-1"
                                onClick={() => handleColumnTypeChange(option)}
                              >
                                {option}
                              </span>
                            )
                          )}
                        </span>
                      )}
                    </span>
                  </span>
                )}
                {isExpand && <span className="menu-name m-0">Structure</span>}
              </span>
            )}
          {activeNavItem === "Column Tools" && (
            <>
              <span className="right-line"></span>
              <span className="flex flex-col items-center justify-between w-fit">
                <span
                  className={`flex ${isExpand ? "flex-col gap-1 " : "flex-row gap-2"
                    }`}
                >
                  <span
                    className={`flex gap-2  ${isExpand ? "mt-[2px]" : "items-center pb-1"
                      }`}
                  >
                    <p
                      className={`m-0 self-center ${(selectedColumn?.name === "id" ||
                        selectedColumn?.name?.endsWith("_id")) &&
                        selectedColumn?.type === "int"
                        ? "menu-label-disable"
                        : "menu-label"
                        }`}
                    >
                      Format
                    </p>
                    <span className="relative" ref={formatDropdownRef}>
                      <input
                        type="text"
                        readOnly
                        // className={`block menu-value p-[2px] pl-[3px] w-32 inputborder-custome  focus:outline-none focus:border-gray-600 rounded-[3px] cursor-pointer`}
                        className={`block menu-value p-[2px] pl-[3px] pr-[15px] text-ellipsis  w-32 focus:outline-none  rounded-[3px] ${(selectedColumn?.name === "id" ||
                          selectedColumn?.name?.endsWith("_id")) &&
                          selectedColumn?.type === "int"
                          ? "cursor-not-allowed border-[1px] border-[#dee2e6] "
                          : " cursor-pointer focus:border-gray-600 inputborder-custome"
                          }`}
                        value={
                          selectedComponent?.columnProperties?.find(
                            (item) => item?.name === selectedColumn?.name
                          )?.format || ""
                        }
                        onChange={handleFormatChange}
                        onClick={() => {
                          if (
                            (selectedColumn?.name === "id" ||
                              selectedColumn?.name?.endsWith("_id")) &&
                            selectedColumn?.type === "int"
                          ) {
                            return;
                          }
                          setFormatDropdownOpen(!formatdropdownOpen);
                          setIsDropdownOpen(false);
                        }}
                        placeholder={
                          (selectedColumn?.name === "id" ||
                            selectedColumn?.name?.endsWith("_id")) &&
                            selectedColumn?.type === "int"
                            ? ""
                            : "Select Format"
                        }
                      />
                      <KeyboardArrowDownIcon
                        className={`absolute right-0 top-1/2 transform -translate-y-1/2 ${(selectedColumn?.name === "id" ||
                          selectedColumn?.name?.endsWith("_id")) &&
                          selectedColumn?.type === "int"
                          ? "text-[#c5c8cb] cursor-not-allowed"
                          : "text-black cursor-pointer"
                          }`}
                        onClick={() => {
                          if (
                            (selectedColumn?.name === "id" ||
                              selectedColumn?.name?.endsWith("_id")) &&
                            selectedColumn?.type === "int"
                          ) {
                            return;
                          }
                          setFormatDropdownOpen(!formatdropdownOpen);
                          setIsDropdownOpen(false);
                        }}
                      />
                      {formatdropdownOpen && (
                        <span
                          className={`absolute z-10 bg-white border border-gray-400 pr-2 pl-2 pb-1 pt-1 rounded-md shadow-md  mt-1 w-32 flex flex-col max-h-80 overflow-auto custom-scrollbar`}
                        >
                          {selectedColumn?.type === "int" &&
                            FormatIntegeroption?.map((option, index) => (
                              <span
                                key={index}
                                className=" cursor-pointer hover:bg-gray-100 menu-value p-1"
                                onClick={() =>
                                  handleFormatChange({
                                    target: { value: option },
                                  })
                                }
                              >
                                {option}
                              </span>
                            ))}
                          {selectedColumn?.type === "varchar" && (
                            <span
                              className="p-1 cursor-pointer hover:bg-gray-100 menu-value"
                              onClick={() =>
                                handleFormatChange({
                                  target: { value: "Text" },
                                })
                              }
                            >
                              Text
                            </span>
                          )}
                          {selectedColumn?.type === "boolean" && (
                            <span
                              className="p-1 cursor-pointer hover:bg-gray-100 menu-value"
                              onClick={() =>
                                handleFormatChange({
                                  target: { value: "True / False" },
                                })
                              }
                            >
                              True / False
                            </span>
                          )}
                          {(selectedColumn?.type === "date" ||
                            selectedColumn?.type === "timestamp") &&
                            dateformat?.map((option, index) => (
                              <span
                                key={index}
                                className="cursor-pointer hover:bg-gray-100 menu-value p-1"
                                onClick={() =>
                                  handleFormatChange({
                                    target: { value: option.format },
                                  })
                                }
                              >
                                {option?.format}
                              </span>
                            ))}
                        </span>
                      )}
                    </span>
                  </span>
                  <span className="flex gap-1 pb-[2px]">
                    <span
                      className={`relative ${isCurrencySelected ? "selectedformat" : ""
                        }`}
                      ref={isDropdownRef}
                    >
                      <AttachMoneyIcon
                        className={`${selectedColumn?.name === "id" ||
                          (selectedColumn?.name?.endsWith("_id") &&
                            selectedColumn?.type === "int")
                          ? "disable-icon-color"
                          : selectedColumn?.type === "int"
                            ? "enable-icon-color"
                            : "disable-icon-color"
                          } ${isExpand ? "dollar-iconexpand" : "dollar-iconcollapse"
                          }`}
                      // className={`${selectedColumn?.type === "int" ? "enable-icon-color" : "disable-icon-color"} ${isExpand ? "dollar-iconexpand" : "dollar-iconcollapse"}`}
                      />
                      <KeyboardArrowDownIcon
                        className={`${selectedColumn?.name === "id" ||
                          (selectedColumn?.name?.endsWith("_id") &&
                            selectedColumn?.type === "int")
                          ? "disable-icon-color cursor-not-allowed"
                          : selectedColumn?.type === "int"
                            ? "enable-icon-color cursor-pointer"
                            : "disable-icon-color cursor-not-allowed"
                          } ${isExpand
                            ? "downarrow-iconexpand"
                            : "downarrow-iconcollapse"
                          }`}
                        // className={`${selectedColumn?.type === "int" ? "enable-icon-color cursor-pointer" : "disable-icon-color cursor-not-allowed"} ${isExpand ? "downarrow-iconexpand" : "downarrow-iconcollapse"}`}
                        // onClick={toggleDropdownforcurrency}
                        onClick={
                          selectedColumn?.type === "int" &&
                            !(
                              selectedColumn?.name === "id" ||
                              selectedColumn?.name?.endsWith("_id")
                            )
                            ? toggleDropdownforcurrency
                            : undefined
                        }
                      />
                      {isDropdownOpen && selectedColumn?.type === "int" && (
                        <span
                          className={`absolute z-10 bg-white border border-gray-400 p-2 rounded-md shadow-md w-32 ${isExpand ? "top-[25px] left-0" : "top-[25px] left-0"
                            }`}
                        >
                          {currencyOptions?.map((currency, index) => (
                            <span
                              key={index}
                              className="p-1 cursor-pointer hover:bg-gray-200 flex flex-col text-[10px] font-semibold"
                              onClick={() => handleCurrencySelect(currency)}
                            >
                              <span>
                                {currency?.symbol} {currency?.name}
                              </span>
                            </span>
                          ))}
                        </span>
                      )}
                    </span>
                    <span
                      // className={`${isPercentageSelected ? 'selectedformat flex items-center' : "defaultselectedformat flex items-center"} ${selectedColumn?.type === "int" ? "cursor-pointer" : "cursor-not-allowed"}`}
                      // onClick={selectedColumn?.type === "int" ? () => handleFormatChange({ target: { value: "Percentage" } }) : undefined}
                      className={`${isPercentageSelected
                        ? "selectedformat flex items-center"
                        : "defaultselectedformat flex items-center"
                        } ${selectedColumn?.type === "int" &&
                          !(
                            selectedColumn?.name === "id" ||
                            selectedColumn?.name?.endsWith("_id")
                          )
                          ? "cursor-pointer"
                          : "cursor-not-allowed"
                        }`}
                      onClick={
                        selectedColumn?.type === "int" &&
                          !(
                            selectedColumn?.name === "id" ||
                            selectedColumn?.name?.endsWith("_id")
                          )
                          ? () =>
                            handleFormatChange({
                              target: { value: "Percentage" },
                            })
                          : undefined
                      }
                    >
                      <PercentageIcon
                        height={isExpand ? "19px" : "19px"}
                        width={isExpand ? "19px" : "19px"}
                        // fill={selectedColumn?.type === "int" ? EnableIconColor : DisableIconColor}
                        fill={
                          selectedColumn?.type === "int" &&
                            !(
                              selectedColumn?.name === "id" ||
                              selectedColumn?.name?.endsWith("_id")
                            )
                            ? EnableIconColor
                            : DisableIconColor
                        }
                      />
                    </span>
                    <span
                      // className={`${selectedComponent?.columnProperties?.find(
                      //     (item) => item?.name === selectedColumn?.name
                      // )?.isComma
                      //     ? 'selectedformat'
                      //     : 'defaultselectedformat'
                      //     } ${selectedColumn?.type === "int" ? "cursor-pointer" : "cursor-not-allowed"}`}
                      // onClick={selectedColumn?.type === "int" ? handleCommaClick : null}
                      className={`${selectedComponent?.columnProperties?.find(
                        (item) => item?.name === selectedColumn?.name
                      )?.isComma
                        ? "selectedformat"
                        : "defaultselectedformat"
                        } ${selectedColumn?.type === "int" &&
                          !(
                            selectedColumn?.name === "id" ||
                            selectedColumn?.name?.endsWith("_id")
                          )
                          ? "cursor-pointer"
                          : "cursor-not-allowed"
                        }`}
                      onClick={
                        selectedColumn?.type === "int" &&
                          !(
                            selectedColumn?.name === "id" ||
                            selectedColumn?.name?.endsWith("_id")
                          )
                          ? handleCommaClick
                          : null
                      }
                    >
                      <CommaIcon
                        height={isExpand ? "25px" : "24px"}
                        width={isExpand ? "25px" : "24px"}
                        // fill={selectedColumn?.type === "int" ? EnableIconColor : DisableIconColor}
                        fill={
                          selectedColumn?.type === "int" &&
                            !(
                              selectedColumn?.name === "id" ||
                              selectedColumn?.name?.endsWith("_id")
                            )
                            ? EnableIconColor
                            : DisableIconColor
                        }
                      />
                    </span>
                    <span className={`${isExpand ? "" : "pb-[3.63px]"}`}>
                      <input
                        type="number"
                        min="2"
                        max="5"
                        className={`focus:outline-none focus:border-gray-600 w-[50px] text-[11px] font-semibold pl-1 pr-1 rounded-[3px] ${selectedColumn?.type !== "int" ||
                          selectedComponent?.columnProperties?.find(
                            (item) => item?.name === selectedColumn?.name
                          )?.format !== "Decimal"
                          ? "cursor-not-allowed border border-[#c5c8cb] text-[#c5c8cb]"
                          : "inputborder-custome text-[#43474b]"
                          }`}
                        disabled={
                          selectedColumn?.type !== "int" ||
                          selectedComponent?.columnProperties?.find(
                            (item) => item?.name === selectedColumn?.name
                          )?.format !== "Decimal"
                        }
                        value={
                          selectedComponent?.columnProperties?.find(
                            (item) => item?.name === selectedColumn?.name
                          )?.decimal || 0
                        }
                        onChange={(e) => {
                          let decimalValue =
                            parseInt(e?.target?.value, 10) || 0;
                          if (decimalValue < 2 || decimalValue > 5) {
                            decimalValue = 2;
                          }
                          const newColPro =
                            selectedComponent?.columnProperties?.map((item) => {
                              if (item?.name === selectedColumn?.name) {
                                return {
                                  ...item,
                                  decimal:
                                    item?.format === "Decimal"
                                      ? decimalValue
                                      : 0,
                                };
                              }
                              return item;
                            });
                          setSelectedComponent((prev) => ({
                            ...prev,
                            columnProperties: newColPro,
                          }));
                          setSelectedColumn((prev) => ({
                            ...prev,
                            decimal: decimalValue,
                          }));
                          onPropertyChange(
                            { columnProperties: newColPro },
                            null
                          );
                        }}
                      />
                    </span>
                  </span>
                </span>
                {isExpand && <span className="menu-name m-0">Formatting</span>}
              </span>
            </>
          )}
          {activeNavItem === "Table Tools" && (
            <>
              {!superset?.isDatasetView && <span className="right-line"></span>}
              <span
                className={`${isExpand ? "flex flex-col justify-between" : ""}`}
                // onClick={togglenewcolumndropdown}
                onClick={
                  !superset?.isDatasetView ? togglenewcolumndropdown : undefined
                }
                ref={newcolumnDropdownRef}
              >
                <span
                  className={`flex items-center  relative ${isExpand ? "flex-col" : "flex-row pb-1"
                    } ${superset?.isDatasetView
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                    }`}
                // onClick={() => setNewColumn({ status: true })}
                >
                  <AddColumnIcon
                    width={isExpand ? 32 : 26}
                    height={isExpand ? 32 : 25.63}
                    tooltipText={isExpand ? "" : "Add New Column"}
                    // fillColors={
                    //     isTables
                    //         ? ["#075985", "#075985", "#FFFFFF"]
                    //         : ["#c5c8cb", "#c5c8cb", "#ffffff"]
                    // }
                    fillColors={
                      isTables && !superset?.isDatasetView
                        ? ["#075985", "#075985", "#FFFFFF"]
                        : ["#c5c8cb", "#c5c8cb", "#ffffff"]
                    }
                  />
                  {!isExpand && (
                    <span>
                      <KeyboardArrowDownIcon
                        className={`arrowdown ${superset?.isDatasetView
                          ? "text-[#c5c8cb]"
                          : "text-[#075985]"
                          }`}
                      />
                    </span>
                  )}
                  {isExpand && (
                    <span
                      className={` ${isTables && !superset?.isDatasetView
                        ? "menu-label"
                        : "menu-label-disable"
                        } m-0 leading-[12px] max-w-[38px] text-center flex`}
                    >
                      {" "}
                      New Column
                      <span className="absolute left-[47px] top-[44px]">
                        <KeyboardArrowDownIcon className="arrowdown relative" />
                      </span>
                    </span>
                  )}
                  {newcolumndropdown && (
                    <div
                      className={`absolute z-10 p-2 w-32 bg-white border rounded shadow-md menu-value ${isExpand ? "top-[57px] left-0" : "top-[28px]"
                        } `}
                    >
                      <span
                        className="flex items-center gap-2 hover:bg-gray-200 p-1"
                        onClick={() => {
                          handleAddDataColumn();
                        }}
                      >
                        Data Column
                      </span>
                      <span
                        className="flex items-center gap-2 p-1 hover:bg-gray-200"
                        onClick={handleNewColumn}
                      >
                        Computed Column
                      </span>
                    </div>
                  )}
                </span>
                {isExpand && <span className="menu-name m-0">Calculation</span>}
              </span>
            </>
          )}
          {/* {
            activeNavItem === "Table Tools" && (
              <>
                <span className="right-line"></span>
                {console.log(selectedComponent, "selectcomppp")}
                <span
                  className={`flex flex-col w-fit items-center justify-between ${isExpand ? "" : "mt-[2px]"
                    }`}
                >
                  {console.log(components, "componentss")}
                  <span
                    className={`flex flex-col items-center ${superset?.datasetProperties !== null && !isTableloading ? 'cursor-pointer' : 'cursor-not-allowed'} `}
                    onClick={() => {
                      if (superset?.datasetProperties === null || isTableloading) return;
                      setIsQueryModalOpen(true);
                    }}
                  >
                    <ViewQuerySvg
                      width={isExpand ? 33 : 24}
                      height={isExpand ? 35 : 24}
                      fill={
                        superset?.datasetProperties !== null && !isTableloading
                          ? EnableIconColor
                          : DisableIconColor
                      }
                      // tooltipText={isExpand ? "" : "View Query"}
                      tooltipText={
                        superset?.datasetProperties === null
                          ? "Datasource not generated from SQL query"
                          : "View Query"
                      }
                    />
                    {isExpand && (
                      <span
                        className={classNames(
                          "m-0 leading-[12px] max-w-[32px] text-center",
                          superset?.datasetProperties !== null && !isTableloading
                            ? "menu-label "
                            : "menu-label-disable"
                        )}
                      >
                        View Query
                      </span>
                    )}
                  </span>

                  {isExpand && <span className="menu-name m-0">Query</span>}
                </span>
              </>
            )
          } */}

          {
            activeNavItem === "Table Tools" && (
              <>
                <span className="right-line"></span>
                {console.log(selectedComponent, "selectcomppp")}
                <span className={`flex flex-col w-fit items-center justify-between ${isExpand ? "" : "mt-[2px]"}`}>
                  <span
                    className={`flex flex-col items-center ${canViewQuery ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                    onClick={() => {
                      if (!canViewQuery) return;
                      setIsQueryModalOpen(true);
                    }}
                  >
                    <ViewQuerySvg
                      width={isExpand ? 33 : 24}
                      height={isExpand ? 35 : 24}
                      fill={canViewQuery ? EnableIconColor : DisableIconColor}
                      tooltipText={
                        canViewQuery
                          ? "View Query"
                          : "Datasource not generated from SQL query"
                      }
                    />
                    {isExpand && (
                      <span className={classNames(
                        "m-0 leading-[12px] max-w-[32px] text-center",
                        canViewQuery ? "menu-label" : "menu-label-disable"
                      )}>
                        View Query
                      </span>
                    )}
                  </span>

                  {isExpand && <span className="menu-name m-0">Query</span>}
                </span>

              </>
            )
          }
          {activeNavItem === "Home" && !isReportBuilder && (
            <>
              <span className="right-line"></span>

              <span
                className={`flex flex-col w-fit items-center justify-between ${isExpand ? "" : "mt-[2px]"
                  }`}
              >
                <span
                  className={classNames(
                    "flex flex-col items-center",
                    hasDataviewCreationPermission
                      ? "cursor-pointer"
                      : "cursor-not-allowed "
                  )}
                  onClick={
                    hasDataviewCreationPermission ? handleAddDataset : null
                  }
                >
                  <AddDatasetSvg
                    width={isExpand ? 33 : 23}
                    height={isExpand ? 35 : 23}
                    fill={
                      hasDataviewCreationPermission
                        ? EnableIconColor
                        : DisableIconColor
                    }
                    tooltipText={isExpand ? "" : "Add Dataview"}
                  />
                  {isExpand && (
                    <span
                      className={classNames(
                        "m-0 leading-[12px] max-w-[50px] text-center",
                        hasDataviewCreationPermission
                          ? "menu-label "
                          : "menu-label-disable"
                      )}
                    >
                      Add DataView
                    </span>
                  )}
                </span>

                {/* {isExpand && <span className="menu-name m-0">DataViews</span>} */}
              </span>
            </>
          )}
          {activeNavItem === "File" &&
            (isReportBuilder ? (
              <span className="flex">
                <span
                  // className='flex flex-col w-fit items-center pl-1 gap-2 pt-[2px]'
                  className={`flex flex-col w-fit items-center pl-1 justify-between`}
                >
                  <span className={`flex gap-2 ${isExpand ? "" : "pb-1"}`}>
                    <span
                      className="flex flex-col items-center"
                      onClick={() => {
                        // if (reportId === null) {
                        //     setIsnewReport(true)
                        // } else {
                        //     setIsSaveReportClicked(true);

                        //     handleSaveReport();
                        // }
                        // setIsnewReport(true);
                        // if (reportId === null) {
                        //     setIsSaveAsReport(true);
                        // }
                        if (islistViewScreen) {
                          return;
                        } else {
                          handleSaveHit();
                        }
                      }}
                    >
                      <span
                        className={`${islistViewScreen
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                          }`}
                      >
                        <SaveIcon
                          height={isExpand ? "32px" : "25px"}
                          width={isExpand ? "32.5px" : "25.63px"}
                          tooltipText={
                            !islistViewScreen
                              ? "You can also save using Ctrl + S"
                              : ""
                          }
                          // fill={EnableIconColor}
                          fill={`${islistViewScreen
                            ? DisableIconColor
                            : EnableIconColor
                            }`}
                        />
                      </span>
                      {isExpand && (
                        <span
                          className={`${islistViewScreen
                            ? "menu-label-disable cursor-not-allowed"
                            : "menu-label cursor-pointer"
                            }m-0`}
                        >
                          Save
                        </span>
                      )}
                    </span>
                    <span
                      className="flex flex-col items-center"
                      onClick={() => {
                        if (reportId === null) return;
                        if (islistViewScreen) return;
                        setIsnewReport(true);
                        setIsSaveAsReport(true);
                      }}
                    >
                      <span
                        className={`${reportId === null || islistViewScreen
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                          }`}
                      >
                        <SaveasIcon
                          height={isExpand ? "32px" : "25px"}
                          width={isExpand ? "32.5px" : "25.63px"}
                          tooltipText={isExpand ? "" : "Save as"}
                          // fill={EnableIconColor}
                          fill={
                            reportId === null || islistViewScreen
                              ? DisableIconColor
                              : EnableIconColor
                          }
                        />
                      </span>
                      {isExpand && (
                        <span
                          // className={`menu-label m-0`}
                          className={`${reportId === null || islistViewScreen
                            ? "menu-label-disable cursor-not-allowed"
                            : "menu-label cursor-pointer"
                            }`}
                        >
                          Save as
                        </span>
                      )}
                    </span>
                  </span>
                  {isExpand && <span className="menu-name">Save Data</span>}
                </span>
                <span className="right-line"></span>
                <span
                  className={`flex flex-col w-fit items-center justify-between`}
                >
                  {/* ${isExpand ? "" : "pb-1"} */}
                  <span className={`flex gap-3 `}>
                    {/* <span
                                            className='flex flex-col items-center'
                                        >
                                            <span
                                                // className="cursor-pointer"
                                                className={`${filteredReport?.status === true ? "cursor-not-allowed" : "cursor-pointer"}`}
                                                onClick={toggleDrawer(true)}
                                            >
                                                <ReportIcon
                                                    height={isExpand ? '32px' : '24px'}
                                                    width={isExpand ? '32.5px' : '25.63px'}
                                                    tooltipText={isExpand ? "" : "Reports"}
                                                    // fill={EnableIconColor}
                                                    fill={`${filteredReport?.status === true ? DisableIconColor : EnableIconColor}`}
                                                />
                                            </span>
                                            {isExpand && <span
                                                className={`${filteredReport?.status === true ? "menu-label-disable cursor-not-allowed" : "menu-label cursor-pointer"} m-0`}
                                            // className='cursor-pointer menu-label m-0'
                                            >
                                                Reports
                                            </span>}
                                        </span> */}
                    <span
                      className="flex flex-col items-center"
                      // onClick={handleNewReport}
                      onClick={() => {
                        if (islistViewScreen) {
                          return;
                        } else {
                          handleNewReport();
                        }
                      }}
                    >
                      <span
                        className={`${islistViewScreen
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                          }`}
                      >
                        <NewReportIcon
                          height={isExpand ? "32px" : "25px"}
                          width={isExpand ? "32.5px" : "25.63px"}
                          tooltipText={isExpand ? "" : "New Report"}
                          // fill={EnableIconColor}
                          fill={`${islistViewScreen
                            ? DisableIconColor
                            : EnableIconColor
                            }`}
                        />
                      </span>
                      {isExpand && (
                        <span
                          className={`pt-[2.5px] m-0 leading-[12px] max-w-[38px] text-center ${islistViewScreen
                            ? "menu-label-disable"
                            : "menu-label"
                            } m-0`}
                        >
                          New Report
                        </span>
                      )}
                    </span>
                    {reports?.slice(0, 3)?.map((report, index) => {
                      return (
                        <span className="flex flex-col items-center">
                          <span
                            // className="cursor-pointer"
                            className={`${filteredReport?.status === true
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                              } ${reportId === report?.id
                                ? "selectedformat bg-white"
                                : "defaultselectedformat"
                              }`}
                            // onClick={toggleDrawer(true)}
                            onClick={() =>
                              filteredReport?.status === false &&
                              handleReportGet(report)
                            }
                          >
                            {/* <ReportOpen
                                                            height={isExpand ? '28px' : '24px'}
                                                            width={isExpand ? '28.5px' : '25.63px'}
                                                            tooltipText={isExpand ? "" : `${report?.name}`}
                                                            // fill={EnableIconColor}
                                                            fill={`${filteredReport?.status === true ? DisableIconColor : EnableIconColor}`}
                                                        /> */}
                            <Tooltip title={report?.name}>
                              <DescriptionOutlinedIcon
                                style={{
                                  color:
                                    filteredReport?.status === true
                                      ? DisableIconColor
                                      : EnableIconColor,
                                }}
                                sx={{
                                  height: isExpand
                                    ? "28px !important"
                                    : "24px !important",
                                  width: isExpand
                                    ? "28px !important"
                                    : "24px !important",
                                  paddingBottom: isExpand ? "" : "1px",
                                }}
                              />
                            </Tooltip>
                          </span>
                          {isExpand && (
                            <Tooltip title={report?.name}>
                              <span
                                className={`pt-[5px] m-0 text-center ${filteredReport?.status === true
                                  ? "menu-label-disable cursor-not-allowed"
                                  : "menu-label "
                                  } m-0 `}
                              // className='cursor-pointer menu-label m-0'
                              >
                                {report?.name?.length > 10
                                  ? report?.name?.slice(0, 7) + "..."
                                  : report?.name}
                                {/* {report?.name} */}
                              </span>
                            </Tooltip>
                          )}
                        </span>
                      );
                    })}
                    {/* isExpand &&  */}
                    {reports.slice(3)?.length > 0 && (
                      <span
                        className="relative cursor-pointer"
                        onClick={() =>
                          filteredReport?.status === false &&
                          setNewcolumndropdown(!newcolumndropdown)
                        }
                        ref={newcolumnDropdownRef}
                      >
                        {!isExpand && (
                          <KeyboardArrowDownIcon
                            className="arrowdown text-[#075985]"
                            sx={{
                              height: "22px !important",
                              width: "22px !important",
                            }}
                          />
                        )}
                        {isExpand && (
                          <KeyboardArrowDownIcon
                            className="arrowdown text-[#075985]"
                            sx={{
                              height: "24px !important",
                              width: "24px !important",
                            }}
                          />
                        )}
                        {newcolumndropdown && (
                          <div
                            className={`absolute z-10 flex flex-col gap-2 p-2 w-36 bg-white border rounded shadow-md menu-value ${isExpand ? "top-[20px] left-0" : "top-[20px]"
                              } `}
                          >
                            {reports.slice(3).map((item) => {
                              return (
                                <span
                                  className="flex items-center gap-2 hover:bg-gray-200"
                                  onClick={() =>
                                    filteredReport?.status === false &&
                                    handleReportGet(item)
                                  }
                                >
                                  <DescriptionOutlinedIcon />
                                  <Tooltip title={item?.name}>
                                    <span className="text-[12px] truncate w-[calc(100%-2rem)] overflow-hidden whitespace-nowrap">
                                      {/* {item?.name?.length > 15 ? item?.name?.slice(0, 14) + '...' : item?.name} */}
                                      {item?.name}
                                    </span>
                                  </Tooltip>
                                </span>
                              );
                            })}
                          </div>
                        )}
                      </span>
                    )}
                  </span>
                  {isExpand && <span className="menu-name">Reports</span>}
                </span>
              </span>
            ) : (
              !isDashboardBuilder && (
                <span
                  className={`flex flex-col w-fit items-center pl-1 ${isExpand ? "gap-2 pt-[2px]" : ""
                    }`}
                >
                  <span className={`flex gap-2 ${isExpand ? "" : "pb-1"}`}>
                    <span
                      className="flex flex-col items-center"
                      onClick={() => {
                        hadleSaveHit();
                      }}
                    >
                      <span
                        className={`${selectedComponent?.id
                          ? "cursor-pointer"
                          : "cursor-not-allowed"
                          }`}
                      >
                        <SaveIcon
                          height={isExpand ? "32px" : "25px"}
                          width={isExpand ? "32.5px" : "25.63px"}
                          tooltipText={
                            isExpand
                              ? "You can also save using Ctrl + S"
                              : "You can also save using Ctrl + S"
                          }
                          fill={
                            selectedComponent?.id
                              ? EnableIconColor
                              : DisableIconColor
                          }
                        />
                      </span>
                      {isExpand && (
                        <span
                          className={`${selectedComponent?.id
                            ? "menu-label cursor-pointer"
                            : "menu-label-disable cursor-not-allowed"
                            } m-0`}
                        >
                          Save
                        </span>
                      )}
                    </span>
                  </span>
                  {isExpand && <span className="menu-name">Save Data</span>}
                </span>
              )
            ))}
          {activeNavItem === "File" && isDashboardBuilder && (
            <span className="flex">
              <span
                // className='flex flex-col w-fit items-center pl-1 gap-2 pt-[2px]'
                className={`flex flex-col w-fit items-center justify-between`}
              >
                <span className={`flex gap-2 ${isExpand ? "" : "pb-1"}`}>
                  <span
                    className="flex flex-col items-center"
                    onClick={() => {
                      // if (reportId === null) {
                      //     setIsnewReport(true)
                      // } else {
                      //     setIsSaveReportClicked(true);

                      //     handleSaveReport();
                      // }
                      // setIsnewReport(true);
                      // if (reportId === null) {
                      //     setIsSaveAsReport(true);
                      // }

                      handleSaveHit();
                    }}
                  >
                    <span className={`cursor-pointer`}>
                      <SaveIcon
                        height={isExpand ? "32px" : "25px"}
                        width={isExpand ? "32px" : "25.63px"}
                        tooltipText={
                          !islistViewScreen
                            ? "You can also save using Ctrl + S"
                            : ""
                        }
                        fill={EnableIconColor}
                      />
                    </span>
                    {isExpand && (
                      <span className={`menu-label cursor-pointer m-0`}>
                        Save
                      </span>
                    )}
                  </span>
                  <span
                    onClick={() => {
                      if (reportId === null) return;
                      setIsnewReport(true);
                      setIsSaveAsReport(true);
                    }}
                    className="flex flex-col items-center"
                  >
                    <span
                      className={`${reportId === null
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                        }`}
                    >
                      <SaveasIcon
                        height={isExpand ? "32px" : "25px"}
                        width={isExpand ? "32px" : "25.63px"}
                        tooltipText={isExpand ? "" : "Save as"}
                        fill={
                          reportId === null ? DisableIconColor : EnableIconColor
                        }
                      />
                    </span>
                    {isExpand && (
                      <span
                        className={`${reportId === null
                          ? "menu-label-disable cursor-not-allowed"
                          : "menu-label cursor-pointer"
                          }`}
                      >
                        Save as
                      </span>
                    )}
                  </span>
                </span>
                {isExpand && <span className="menu-name">Save Dashboard</span>}
              </span>
              <span className="right-line"></span>
              <span
                className={`flex flex-col w-fit items-center justify-between`}
              >
                <span className={`flex gap-3 `}>
                  <span
                    className="flex flex-col items-center"
                    onClick={() => {
                      handleNewReport();
                    }}
                  >
                    <span className={`cursor-pointer`}>
                      <NewReportIcon
                        height={isExpand ? "32px" : "25px"}
                        width={isExpand ? "32px" : "25.63px"}
                        tooltipText={isExpand ? "" : "New Dashboard"}
                        fill={EnableIconColor}
                      />
                    </span>

                    {isExpand && (
                      <span
                        className={`pt-[2px] m-0 leading-[12px] max-w-[57px] text-center menu-label flex`}
                      >
                        New Dashboard
                      </span>
                    )}
                  </span>
                  {reports?.slice(0, 3)?.map((report, index) => {
                    return (
                      <span className="flex flex-col items-center">
                        <span
                          // className="cursor-pointer"
                          className={`${filteredReport?.status === true
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                            } ${reportId === report?.id
                              ? "selectedformat bg-white"
                              : "defaultselectedformat"
                            }`}
                          // onClick={toggleDrawer(true)}
                          onClick={() => handleReportGet(report)}
                        >
                          {/* <ReportOpen
                                                            height={isExpand ? '28px' : '24px'}
                                                            width={isExpand ? '28.5px' : '25.63px'}
                                                            tooltipText={isExpand ? "" : `${report?.name}`}
                                                            // fill={EnableIconColor}
                                                            fill={`${filteredReport?.status === true ? DisableIconColor : EnableIconColor}`}
                                                        /> */}
                          <Tooltip title={report?.name}>
                            <DescriptionOutlinedIcon
                              style={{
                                color:
                                  filteredReport?.status === true
                                    ? DisableIconColor
                                    : EnableIconColor,
                              }}
                              sx={{
                                height: isExpand
                                  ? "28px !important"
                                  : "24px !important",
                                width: isExpand
                                  ? "28px !important"
                                  : "24px !important",
                                paddingBottom: isExpand ? "" : "1px",
                              }}
                            />
                          </Tooltip>
                        </span>
                        {isExpand && (
                          <Tooltip title={report?.name}>
                            <span
                              className={`pt-[5px] m-0 text-center ${filteredReport?.status === true
                                ? "menu-label-disable cursor-not-allowed"
                                : "menu-label "
                                } m-0 `}
                            // className='cursor-pointer menu-label m-0'
                            >
                              {report?.name?.length > 10
                                ? report?.name?.slice(0, 7) + "..."
                                : report?.name}
                              {/* {report?.name} */}
                            </span>
                          </Tooltip>
                        )}
                      </span>
                    );
                  })}
                  {/* isExpand &&  */}
                  {reports.slice(3)?.length > 0 && (
                    <span
                      className="relative cursor-pointer"
                      onClick={() => setNewcolumndropdown(!newcolumndropdown)}
                      ref={newcolumnDropdownRef}
                    >
                      {!isExpand && (
                        <KeyboardArrowDownIcon
                          className="arrowdown text-[#075985]"
                          sx={{
                            height: "22px !important",
                            width: "22px !important",
                          }}
                        />
                      )}
                      {isExpand && (
                        <KeyboardArrowDownIcon
                          className="arrowdown text-[#075985]"
                          sx={{
                            height: "24px !important",
                            width: "24px !important",
                          }}
                        />
                      )}
                      {newcolumndropdown && (
                        <div
                          className={`absolute z-10 flex flex-col gap-2 p-2 w-36 bg-white border rounded shadow-md menu-value ${isExpand ? "top-[20px] left-0" : "top-[20px]"
                            } `}
                        >
                          {reports.slice(3).map((item) => {
                            return (
                              <span
                                className="flex items-center gap-2 hover:bg-gray-200"
                                onClick={() => handleReportGet(item)}
                              >
                                <DescriptionOutlinedIcon />
                                <Tooltip title={item?.name}>
                                  <span className="text-[12px] truncate w-[calc(100%-2rem)] overflow-hidden whitespace-nowrap">
                                    {/* {item?.name?.length > 15 ? item?.name?.slice(0, 14) + '...' : item?.name} */}
                                    {item?.name}
                                  </span>
                                </Tooltip>
                              </span>
                            );
                          })}
                        </div>
                      )}
                    </span>
                  )}
                </span>
                {isExpand && <span className="menu-name">Dashboard</span>}
              </span>
              <span className="right-line"></span>
              {/* <span className={`flex flex-col w-fit items-center justify-between`}>
                                <span className='flex flex-col items-center cursor-pointer'
                                    onClick={() => {
                                        setSelectedComponent(null);
                                        exportDashboardPdf();
                                    }}
                                >
                                    <Tooltip title={`${isExpand ? "" : "Export"}`}>
                                        <FileDownloadIcon
                                            className={`${isExpand ? "fileicon" : "collapse-fileicon"}`}
                                            style={{ color: EnableIconColor }}
                                        />
                                    </Tooltip>

                                    {isExpand && <p className="menu-label  m-0 leading-[12px] max-w-[59px] pt-[2.5px] text-center">Export Dashboard</p>}
                                </span>
                                {isExpand && <span className='menu-name'>Export</span>}
                            </span> */}
              {/* <span className='right-line'></span>
                            <span className={`flex flex-col w-fit items-center justify-between`}>
                                <span className='flex flex-col items-center cursor-pointer'>
                                    <PageColorIcon
                                        width={isExpand ? 30 : 24.30}
                                        height={isExpand ? 30 : 23}
                                        fillColors={[EnableIconColor, EnableIconColor, EnableIconColor]}
                                        tooltipText={isExpand ? "" : "Dashboard Color"}
                                    />
                                    {isExpand && <p className="menu-label  m-0 leading-[12px] max-w-[59px] pt-[2.5px] text-center">Dashboard Color</p>}
                                </span>
                                {isExpand && <span className='menu-name'>Formatting</span>}
                            </span> */}
            </span>
          )}
          {activeNavItem === "Home" && isReportBuilder && (
            <span
              className={`flex flex-col w-fit items-center justify-between ${isExpand ? "mt-[2px]" : "pb-[1.3px]"
                }`}
            >
              <span className="flex gap-4">
                <span
                  className={`flex items-center ${islistViewScreen ? "cursor-not-allowed" : "cursor-pointer"
                    } relative ${isExpand ? "flex-col" : "flex-row pb-1"}`}
                  onClick={() => {
                    if (islistViewScreen) return;
                    setShowPageSizeDropdown(!showPageSizeDropdown);
                  }}
                  ref={pageSizeDropdownRef}
                >
                  <PageSizeIcon
                    width={isExpand ? 30 : 24.3}
                    height={isExpand ? 30 : 23}
                    // fill={EnableIconColor}
                    fill={`${islistViewScreen ? DisableIconColor : EnableIconColor
                      }`}
                    tooltipText={isExpand ? "" : "Page Size"}
                  />
                  {!isExpand && (
                    <span>
                      <KeyboardArrowDownIcon
                        className={`arrowdown ${islistViewScreen ? "text-[#c5c8cb]" : "text-[#075985]"
                          }`}
                      />
                    </span>
                  )}
                  {isExpand && (
                    <span className="flex flex-row">
                      <span
                        className={`${islistViewScreen ? "menu-label-disable" : "menu-label"
                          } m-0`}
                      >
                        {" "}
                        Size
                      </span>
                      <span className="absolute left-[25px] bottom-[-3px]">
                        <KeyboardArrowDownIcon
                          className={`arrowdown relative ${islistViewScreen
                            ? "text-[#c5c8cb]"
                            : "text-[#7e8387]"
                            }`}
                        />
                      </span>
                    </span>
                  )}
                  {showPageSizeDropdown && (
                    <ul
                      className={`absolute bg-white border border-gray-300 shadow-md rounded-md w-fit z-10 pagesize-list ${isExpand ? "top-[50px] left-[2px]" : "top-[26px]"
                        }`}
                    >
                      {pageSizes.map((size) => (
                        <li
                          key={size.name}
                          className={`px-2 py-2 text-sm ${reportProperty?.size?.name === size?.name
                            ? "bg-gray-200"
                            : ""
                            } hover:bg-gray-200 cursor-pointer flex flex-col`}
                          // onClick={() => {
                          //     setShowPageSizeDropdown(false);
                          // }}
                          onClick={() => handlePageSizeSelect(size)}
                        >
                          <span>{size.name}</span>
                          <span className="text-gray-500 text-xs flex flex-row space-x-1">
                            <span>{size.width}</span>
                            <span>×</span>
                            <span>{size.height}</span>
                            <span> cm</span>
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                </span>
                <span
                  className={`flex items-center ${islistViewScreen ? "cursor-not-allowed" : "cursor-pointer"
                    } relative ${isExpand ? "flex-col" : "flex-row pb-1"}`}
                  onClick={() => {
                    if (islistViewScreen) return;
                    setShowMarginDropdown(!showMarginDropdown);
                  }}
                  ref={pageMarginDropdownRef}
                >
                  <PageMarginIcon
                    width={isExpand ? 30 : 24.3}
                    height={isExpand ? 30 : 23}
                    fill={`${islistViewScreen ? DisableIconColor : EnableIconColor
                      }`}
                    tooltipText={isExpand ? "" : "Page Margins"}
                  />
                  {!isExpand && (
                    <span>
                      <KeyboardArrowDownIcon
                        className={`arrowdown ${islistViewScreen ? "text-[#c5c8cb]" : "text-[#075985]"
                          }`}
                      />
                    </span>
                  )}
                  {isExpand && (
                    <span className="flex flex-row">
                      <span
                        className={`${islistViewScreen ? "menu-label-disable" : "menu-label"
                          } m-0`}
                      >
                        {" "}
                        Margins
                      </span>
                      <span className="absolute left-[41px] bottom-[-3px]">
                        <KeyboardArrowDownIcon
                          className={`arrowdown relative ${islistViewScreen
                            ? "text-[#c5c8cb]"
                            : "text-[#7e8387]"
                            }`}
                        />
                      </span>
                    </span>
                  )}
                  {showMarginDropdown && (
                    <ul
                      className={`absolute bg-white border border-gray-300 shadow-md rounded-md w-fit z-10 pagesize-list ${isExpand ? "top-[50px] left-[0] " : "top-[26px]"
                        }`}
                    >
                      {marginOptions.map((margin) => (
                        <li
                          key={margin.name}
                          className={`px-2 py-2 text-sm ${reportProperty?.margin?.name === margin?.name
                            ? "bg-gray-200"
                            : ""
                            } hover:bg-gray-200 cursor-pointer flex flex-col`}
                          // onClick={() => {
                          //     setShowMarginDropdown(false);
                          // }}
                          onClick={() => handleMarginSelection(margin)}
                        >
                          <span>{margin.name}</span>
                          <span className="text-gray-500 text-xs flex flex-col">
                            <span className="flex gap-2">
                              <span className="flex gap-1">
                                <p>Top:</p>
                                <p>{margin.top}</p>
                                <p>cm</p>
                              </span>
                              <span className="flex gap-1">
                                <p>Bottom:</p>
                                <p>{margin.bottom}</p>
                                <p>cm</p>
                              </span>
                            </span>
                            <span className="flex gap-2">
                              <span className="flex gap-1">
                                <p>Left:</p>
                                <p>{margin.left}</p>
                                <p>cm</p>
                              </span>
                              <span className="flex gap-1">
                                <p>Right:</p>
                                <p>{margin.right}</p>
                                <p>cm</p>
                              </span>
                            </span>
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                </span>
                {/* <span className='flex flex-col items-center cursor-pointer'>
                                    <PageColorIcon

                                        width={isExpand ? 30 : 24.30}
                                        height={isExpand ? 30 : 23}
                                        fillColors={[EnableIconColor, EnableIconColor, EnableIconColor]}
                                        tooltipText={isExpand ? "" : "Page Color"}
                                    />

                                    {isExpand && <p className="menu-label  m-0 leading-[12px] max-w-[30px] pt-[2.5px] text-center">Page Color</p>}
                                </span> */}
              </span>
              {isExpand && <span className="menu-name m-0">Page Setup</span>}
            </span>
          )}
          {activeNavItem === "Home" && isReportBuilder && (
            <>
              <span className="right-line ml-3"></span>
              <span
                className={`${isExpand ? "flex flex-col justify-between" : ""}`}
              >
                <span
                  className={`flex items-center  relative ${isExpand ? "flex-col mt-[5px]" : "flex-row pb-1"
                    }   ${filteredReport?.status ||
                      (hasValidData && !islistViewScreen)
                      ? "cursor-pointer"
                      : "cursor-not-allowed"
                    }`}
                  // onClick={() => {
                  //     setSelectedComponent(null);
                  //     exportPDF();
                  // }}
                  onClick={() => {
                    // if (islistViewScreen) return;
                    if (
                      !filteredReport?.status &&
                      (islistViewScreen || !hasValidData)
                    )
                      return;
                    if (hasValidData) {
                      setSelectedComponent(null);
                      exportPDF();
                    }
                  }}
                >
                  <Tooltip title={`${isExpand ? "" : "Export"}`}>
                    <FileDownloadIcon
                      className={`${isExpand ? "fileicon" : "collapse-fileicon"
                        }`}
                      style={{
                        color:
                          !filteredReport?.status &&
                            (islistViewScreen || !hasValidData)
                            ? DisableIconColor
                            : EnableIconColor,
                      }}
                    />
                  </Tooltip>

                  {isExpand && (
                    <span
                      className={` m-0 leading-[13px] max-w-[42px] text-center flex ${!filteredReport?.status &&
                        (islistViewScreen || !hasValidData)
                        ? "menu-label-disable"
                        : "menu-label"
                        }`}
                    >
                      {" "}
                      Export
                    </span>
                  )}
                </span>
                {isExpand && (
                  <span className="menu-name m-0">Export Report</span>
                )}
              </span>
            </>
          )}
          {/* {activeNavItem === "Home" && isReportBuilder && (
                        <>
                            <span className='right-line ml-3'></span>

                            <span
                                className={`${isExpand ? "flex flex-col justify-between" : ""}`}

                            >
                                <span
                                    className={`flex items-center cursor-pointer relative ${isExpand ? "flex-col mt-[7px]" : "flex-row pb-1"}`}
                                >
                                    <ScheduleReportIcon
                                        width={isExpand ? 28 : 24.30}
                                        height={isExpand ? 28 : 23}
                                        tooltipText={isExpand ? "" : "Report Scheduling"}
                                    />
                                    {isExpand &&
                                        <span
                                            className={` m-0 leading-[12px] max-w-[42px] text-center flex menu-label pt-[2px]`}
                                        > schedule
                                        </span>}
                                </span>
                                {isExpand && <span className='menu-name m-0'> Report Scheduling</span>}
                            </span>
                        </>
                    )} */}
        </span>
        {isReportBuilder &&
          (isExpand || islistViewScreen) &&
          !(filteredReport?.status === true && !isExpand) && (
            <span
              // className='flex flex-col  gap-1 -mt-[20px]'
              className={` flex  ${isExpand ? "flex-col gap-1 -mt-[20px]" : "flex-row gap-1"
                }   ${!islistViewScreen || filteredReport?.status === true
                  ? "cursor-not-allowed"
                  : ""
                }`}
            >
              <span className=" relative flex flex-col w-30">
                <input
                  type="text"
                  value={islistViewScreen ? searchValue : ""}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  disabled={
                    !islistViewScreen || filteredReport?.status === true
                  }
                  // className="block outline-none px-1 py-1 sidebar-section-text bg-white input-border-custome rounded-[5px] h-[25.63px]"
                  className={`block outline-none px-1 py-1 sidebar-section-text rounded-[5px] ${isExpand ? "h-[25.63px]" : "h-[25px] mt-[2px]"
                    } ${!islistViewScreen || filteredReport?.status === true
                      ? "cursor-not-allowed bg-slate-200 border border-slate-200"
                      : "bg-white input-border-custome"
                    }`}
                  placeholder="Search..."
                />
                <SearchIcon
                  className={` search-icon-menu ${!islistViewScreen ? "cursor-not-allowed" : ""
                    }`}
                />
              </span>
              <span
                // className='flex mt-1'
                className={`${isExpand ? "flex mt-1" : "flex"}`}
              >
                {
                  <span className={` flex report-top-date-range`}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateRangePicker"]}>
                        <DateRangePicker
                          localeText={{ start: "From", end: "To" }}
                          calendars={2}
                          value={
                            islistViewScreen ? dateRangeMenu : [null, null]
                          }
                          onChange={(newValue) => {
                            setDateRangeMenu(newValue);
                            setDateRMenu(null);
                          }}
                          disabled={
                            !islistViewScreen ||
                            filteredReport?.status === true ||
                            !hasDateColumn()
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </span>
                }
                {/* <span className=' relative flex flex-col w-30'>
                            <input
                                type="text"
                                value={searchValue}
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                }}
                                className="block outline-none px-1 py-1 sidebar-section-text bg-white input-border-custome rounded-[5px] h-[25.63px]"
                                placeholder="Search..."
                            />
                            <SearchIcon className='search-icon-menu' />
                        </span> */}
                <span className="ml-1 flex">
                  <button
                    className={`px-2  ${isExpand ? "" : "h-fit mt-1"
                      } text-xs btn-bgcolor py-1 font-bold text-white rounded-md  disabled:cursor-not-allowed disabled:opacity-40`}
                    onClick={() => {
                      setIsApplied(true);
                      //  handleViewFilteredReport({}, true)
                      handleListViewFilter();
                    }}
                    disabled={
                      filteredReport?.status === true ||
                      (searchValue === "" &&
                        dateRMenu === null &&
                        dateRangeMenu[0] === null)
                    }
                  >
                    Apply
                  </button>
                  <button
                    className={`px-2 ${isExpand ? "" : "h-fit mt-1"
                      } text-xs btn-bgcolor py-1 font-bold text-white rounded-md  disabled:cursor-not-allowed disabled:opacity-40 ml-1`}
                    disabled={
                      filteredReport?.status === true ||
                      !isApplied ||
                      (dateRMenu === null &&
                        dateRangeMenu[0] == null &&
                        dateRangeMenu[1] == null &&
                        searchValue === "")
                    }
                    onClick={() => {
                      setIsApplied(false);
                      // handleViewFilteredReport({}, false, true)
                      handleListViewFilter({}, true);
                    }}
                  >
                    Clear
                  </button>
                </span>
              </span>
              <span className="report-designer mt-1 mb-1 d-flex justify-around">
                <Tabs
                  value={islistViewScreen ? dateRMenu : ""}
                  onChange={handleDateOptionChange}
                  textColor="primary"
                  indicatorColor="white"
                  aria-label="secondary tabs example"
                  className="mb-1"
                >
                  <Tab
                    label="Today"
                    value="TODAY"
                    disabled={
                      !islistViewScreen ||
                      filteredReport?.status === true ||
                      !hasDateColumn()
                    }
                  />
                  <Tab
                    label="Week to Date"
                    value="WTD"
                    disabled={
                      !islistViewScreen ||
                      filteredReport?.status === true ||
                      !hasDateColumn()
                    }
                  />
                  <Tab
                    label="Month to Date"
                    value="MTD"
                    disabled={
                      !islistViewScreen ||
                      filteredReport?.status === true ||
                      !hasDateColumn()
                    }
                  />
                  <Tab
                    label="Year to Date"
                    value="YTD"
                    disabled={
                      !islistViewScreen ||
                      filteredReport?.status === true ||
                      !hasDateColumn()
                    }
                  />
                </Tabs>
              </span>
            </span>
          )}
        {/* {isReportBuilder && validdataforfilter && !islistViewScreen && reportId === null &&
                    <span className='flex items-end gap-2 ml-20 mb-1'>
                        <span className='flex text-xs font-semibold input-border-custome rounded-[5px] bg-gray-300 px-2 py-1'>
                            <p className='m-0 pr-1'>Date:</p>{reportProperty?.data?.filters?.dateFilter?.fromDate}
                        </span>
                        {reportProperty?.data?.filters?.shift_type &&
                            <span className='flex text-xs font-semibold input-border-custome rounded-[5px] bg-gray-300 px-2 py-1'>
                                <p className='m-0 pr-1'>Shift Type:</p>{reportProperty?.data?.filters?.shift_type}
                            </span>
                        }

                    </span>
                } */}
        {/* {isReportBuilder && !islistViewScreen && reportId !== null &&
                    <span className='flex items-end gap-2 mb-1 mr-10'>
                        <span className='flex text-xs font-semibold input-border-custome rounded-[5px] bg-gray-300 px-2 py-1'>
                            <p className='m-0 pr-1'>Date:</p>{reportProperty?.filters?.dateFilter?.fromDate}
                        </span>
                        {reportProperty?.filters?.shift_type &&
                            <span className='flex text-xs font-semibold input-border-custome rounded-[5px] bg-gray-300 px-2 py-1'>
                                <p className='m-0 pr-1'>Shift Type:</p>{reportProperty?.filters?.shift_type}
                            </span>
                        }
                    </span>
                } */}

        <span
          // className='flex self-end pagination-style items-center'
          className={`${isExpand
            ? "flex self-end pagination-style items-center"
            : "flex  items-center pagination-style"
            }`}
        >
          {!isReportBuilder &&
            activeNavItem !== "Database Connections" &&
            !superset?.isDatasetView && (
              <span
                // className='d-flex flex-col'
                className={`${isExpand ? "flex flex-col" : "flex flex-row items-center"
                  }`}
              >
                {isTables && (
                  <Pagination
                    count={count}
                    api={fetchTableData}
                    defaultRowsPerPage={100}
                    disableRowsPerPageSelection={true}
                    tableKey={selectedComponent?.id}
                  />
                )}
                {isTables && (
                  <span className="text-sm pl-[24px]">
                    Total Records:{" "}
                    {count ? count : selectedComponent?.data?.length}
                  </span>
                )}
              </span>
            )}
          {/* {!isReportBuilder && isDatasetView && (
                        <span className="flex flex-row items-center relative gap-1" ref={limitDropdownRef}>
                            <span className='text-sm font-semibold text-[#075985] '>Limit: {selectedLimit || "Select limit"}</span>
                            <KeyboardArrowDownIcon
                                className="cursor-pointer text-[#075985]"
                                onClick={() => setLimitDropdown(!limitDropdown)}
                            />
                            {limitDropdown && (
                                <div
                                    className={`absolute z-10 p-2 w-32 bg-white border rounded shadow-md menu-value ${isExpand ? "top-[30px] right-0" : "top-[30px] right-0"}`}
                                >
                                    {limitOptions.map((limit) => (
                                        <span
                                            key={limit}
                                            className="flex items-center gap-2 p-1 hover:bg-gray-200 cursor-pointer"
                                            onClick={() => {
                                                setSelectedLimit(limit);
                                                setLimitDropdown(false);
                                            }}
                                        >
                                            {limit}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </span>
                    )} */}
          {/* {!isReportBuilder && superset?.isDatasetView && (
                        <button className='font-semibold text-white rounded btn-bgcolor text-xs px-2 py-1 mr-1 mb-1'
                            onClick={() => {
                                setActiveNavItem("Home")
                                superset?.setIsDatasetView(false)
                                superset?.setSelectedSchema(null)
                                superset?.setSelectedTable(null)
                                superset?.setselectedDatabse(null)
                                superset?.setTableMetadata({ metaData: [], tableData: [] })
                            }}
                        >
                            Close DatasetView
                        </button>
                    )} */}
          {isReportBuilder &&
            islistViewScreen &&
            !filteredReport?.status &&
            listViewData?.columns?.length > 0 && (
              <span className="d-flex flex-col items-center">
                <Pagination
                  count={count}
                  api={fetchTableData}
                  defaultRowsPerPage={100}
                  disableRowsPerPageSelection={true}
                  tableKey={selectedComponent?.id}
                />

                {/* {(<span className='text-sm pr-[10px] font-semibold'>Total Records: {count}</span>)} */}
                <span className="text-sm font-semibold">
                  Total Records: {count}
                </span>
              </span>
            )}

          <span className="self-end cursor-pointer">
            {isExpand ? (
              <Tooltip title={"Collapse"}>
                <KeyboardDoubleArrowUpIcon
                  style={{ height: "25px", width: "25px", color: "#075985" }}
                  onClick={toggleMenu}
                />
              </Tooltip>
            ) : (
              <Tooltip title={"Expand"}>
                <KeyboardDoubleArrowDownIcon
                  style={{ height: "25px", width: "25px", color: "#075985" }}
                  onClick={toggleMenu}
                />
              </Tooltip>
            )}
          </span>
        </span>
      </div>
    </>
  );
}

export default Menu;
