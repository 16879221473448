import React from "react";
import ReusableModal from "../../../../components/Modals/ReusableModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../../../Billing/components/PaymentForm";

const PaymentMethodModal = ({
  paymentFormOpen,
  setPaymentFormOpen,
  handleSuccess,
}) => {
  return (
    <div>
      <ReusableModal
        open={paymentFormOpen}
        onClose={() => setPaymentFormOpen(false)}
        title="Payment Method"
      >
        <Elements
          stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)}
        >
          <PaymentForm handleSuccess={handleSuccess} />
        </Elements>
      </ReusableModal>
    </div>
  );
};

export default PaymentMethodModal;
