import { createContext, useState, useContext, useEffect } from "react";

const SupersetContext = createContext();

export const SupersetProvider = ({ children }) => {
    const [isCreateCon, setIsCreateCon] = useState(false);
    const [credentials, setCredentials] = useState({
        host: "",
        port: "",
        username: "",
        password: "",
        displayName: '',
        database: ""
    });
    const [selectedDB, setSelectedDB] = useState(null);
    const [isDatabaseView, setIsDatabaseView] = useState(false);
    const [dbConnections, setDbConnections] = useState([]);
    const [isDatabseLoading, setIsDataBaseLoading] = useState(false);
    const [isDatasetView, setIsDatasetView] = useState(false);
    const [tableMetaData, setTableMetadata] = useState({ metaData: [], tableData: [] });
    const [selectedDatabase, setselectedDatabse] = useState(null);
    const [selectedSchema, setSelectedSchema] = useState(null);
    const [selectedTable, setSelectedTable] = useState(null);
    const [datasettableloading, setDatasettableloading] = useState(false);
    const [selectedLimit, setSelectedLimit] = useState("");
    const limitOptions = [10, 100, 1000, 10000];
    const [sqlQuery, setSqlQuery] = useState("SELECT * FROM \"\"");
    const [schemas, setSchemas] = useState([]);
    const [tables, setTables] = useState([]);
    const [datasetProperties, setDatasetProperties] = useState(null);
    // useEffect(() => {
    //     if (tableMetaData?.metaData?.name) {
    //         setSqlQuery(`SELECT * FROM ${selectedSchema}.${tableMetaData.metaData.name}`);
    //     }
    // }, [tableMetaData]);

    return (
        <SupersetContext.Provider value={{
            isCreateCon, setIsCreateCon,
            credentials, setCredentials,
            selectedTable, setSelectedTable,
            selectedSchema, setSelectedSchema,
            selectedDatabase, setselectedDatabse,
            tableMetaData, setTableMetadata,
            isDatasetView, setIsDatasetView,
            setIsDataBaseLoading, isDatabseLoading,
            setDbConnections, dbConnections,
            setIsDatabaseView, isDatabaseView,
            setSelectedDB, selectedDB,
            datasettableloading, setDatasettableloading,
            selectedLimit, setSelectedLimit,
            limitOptions,
            sqlQuery, setSqlQuery,
            schemas, setSchemas,
            tables, setTables,
            datasetProperties, setDatasetProperties
        }}>
            {children}
        </SupersetContext.Provider>
    );
};

export default SupersetContext;
