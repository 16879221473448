import * as d3 from 'd3';
import React, { Component, useEffect, useRef, useState } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import { Default_Chart } from '../../../../../assets/images';
import { big_number_default } from '../../../../../assets/images';
import ReusableReportModal from '../../../ReportBuilder/components/ReusableReportModal';
import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import { CircularProgress, Stack } from '@mui/material';

const EChartsBarChart = ({ option, properties, onChartClick }) => {
    const chartRef = useRef(null);
    useEffect(() => {
        if (chartRef.current) {
            let chartInstance = chartRef.current.getEchartsInstance();  // ✅ Correct way to get instance

            if (chartInstance) {

                //  chartInstance.setOption(option, true); // ✅ Keeps legend state
                chartRef?.current?.getEchartsInstance()?.setOption(option, true);

                chartInstance.off("click");
                chartInstance.on("click", 'series', (params) => {
                    // console.log("Chart Clicked:", params); // Debugging
                    if (onChartClick) {
                        onChartClick(params);
                    }
                    // if (params.seriesType === "line") {
                    //     console.log(`Clicked on line: ${params.seriesName} at ${params.name}`);

                    //     if (onChartClick) {
                    //         onChartClick(params);
                    //     }
                    // }
                });

                // ✅ Cleanup event listeners when unmounting
                return () => {
                    chartInstance.off("click");
                };

            } else {
                console.log("Chart instance is undefined!");
            }
        }
    }, [option, properties, onChartClick]);


    // return <ReactEcharts ref={chartRef} option={option} style={{ width: "100%", height: "400px" }} />;
    return <ReactEcharts ref={chartRef} option={option} style={{ width: "100%", height: "100%" }} className="custom-echart" opts={{ devicePixelRatio: 2 }} />
};

const ChartComponent = ({
    option,
    properties,
    showAlert,
    isAnimated,
    setIsAnimated,
    data,
    stopTableDrag,
    generateChartData,
    id,
    setFlag,
    setSelectedComponent,
    onPropertyChange,
    generatePieChartData,
    generateDoubleChartData,
    drillData,
    selectedId,
    pieDrillData,
    isListViewReport,
    handleApplyGlobalFilter
}) => {

    const chartRef = useRef();
    const [tooltip, setTooltip] = useState({ visible: false, name: '', borderColor: '#ccc', content: '', left: 0, top: 0 });
    const x0Ref = useRef(null);
    const yRef = useRef(null);
    const legendRef = useRef(null);
    const parentRef = useRef(null);
    const [marginBottom, setMarginBottom] = useState(80);
    const [legendVisibility, setLegendVisibility] = useState(
        option?.legend?.data?.reduce((acc, item) => ({ ...acc, [item]: true }), {})
    );
    // const [legendVisibility, setLegendVisibility] = useState(
    //     Array.isArray(option.legend?.data)
    //         ? option.legend?.data?.reduce((acc, item) => ({ ...acc, [item]: true }), {})
    //         : {}
    // );
    const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
    // const [drilledData, setDrilledData] = React.useState({});
    const [drilledColumns, setDrilledColumns] = React.useState([]);
    const breadcrumbsLength = properties?.chartType === 'pie' ? pieDrillData?.breadcrumbs?.length || 0 : drillData?.breadcrumbs?.length || 0;
    // const [drillData, setDrillData] = useState({ 
    //     level: 0,
    //     level1: { xAxis: "", yAxis: "" },
    //     level2: { xAxis: "", yAxis: "" },
    //     breadcrumbs: [{ level: 0, xAxisValue: "Main" }] // New property to track breadcrumbs
    // });

    const updateChartAxis = (option) => {
        let result;
        if (
            properties?.chartType === "bar" ||
            properties?.chartType === "barWithLine" ||
            properties?.chartType === "stacked" ||
            properties?.chartType === "line" ||
            properties?.chartType === "area" ||
            properties?.chartType === "scatter"
        ) {
            if (properties?.chartType !== "barWithLine") {
                result = generateChartData(
                    option?.data,
                    option?.xAxis,
                    option?.yAxis,
                    properties?.legends?.map(item => item?.columnName),
                    null,
                    false,
                    option?.isLogScaleY,
                    option?.isLogScaleZ,
                    id
                );
            } else {
                result = generateChartData(
                    option?.data,
                    option?.xAxis,
                    option?.yAxis,
                    properties?.legends?.map(item => item?.columnName),
                    option?.line,
                    false,
                    option?.isLogScaleY,
                    option?.isLogScaleZ,
                    id
                );
            }
            const newOption = {
                ...properties?.option,
                series: result?.series,
                xAxis: {
                    ...properties?.option?.xAxis,
                    data: result?.xValues,
                },
                // legend: { data: result?.series?.map((series) => series.name) },
            };
            onPropertyChange({
                option: newOption,
                xAxis: option?.xAxis,
                yAxis: option?.yAxis,
                zAxis: option?.zAxis,
                rowFields: option?.rowFields,
                colFields: option?.colFields,
                drillData: option?.drillData
            });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev?.properties,
                    option: newOption,
                    xAxis: option?.xAxis,
                    yAxis: option?.yAxis,
                    zAxis: option?.zAxis,
                    rowFields: option?.rowFields,
                    colFields: option?.colFields,
                    drillData: option?.drillData
                }
            }));
            setFlag(false);
            setIsAnimated(true);
        } else if (properties?.chartType === "double") {
            //  result = generateChartData( option?.xAxis, option?.yAxis, option?.zAxis, null, false);
            const result = generateDoubleChartData(
                option?.data,
                option?.xAxis,
                option?.yAxis,
                properties?.zAxis?.map(item => item?.column_name),
                true,
                option?.logColumns,
                id
            );
            const newOption = {
                ...properties?.option,
                series: result?.series,
                xAxis: {
                    ...properties?.option?.xAxis,
                    data: result?.xValues,
                },
                legend: { data: result?.series?.map((series) => series.name) },
            };
            onPropertyChange({
                option: newOption,
                xAxis: option?.xAxis,
                yAxis: option?.yAxis,
                line: option?.line,
                // zAxis: option?.zAxis,
                logColumns: {
                    ...properties?.logColumns,
                    double: option?.logColumns,
                },
                drillData: option?.drillData
            });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    option: newOption,
                    xAxis: option?.xAxis,
                    yAxis: option?.yAxis,
                    line: option?.line,
                    //  zAxis: option?.zAxis,
                    logColumns: {
                        ...properties?.logColumns,
                        double: option?.logColumns,
                    },
                    drillData: option?.drillData
                },
            }));
            // echartsRef.current.getEchartsInstance().setOption(newOption, true);
            setFlag(false);
            setIsAnimated(true);
        } else {
            let result = generatePieChartData(option?.data, option?.slice);
            const newOption = {
                ...properties?.option,
                series: result?.series,
                // xAxis: {
                //     ...component?.properties?.option?.xAxis,
                //     data: result?.xValues
                // },
                // yAxis: {
                //     ...properties.option.yAxis,
                //     interval: result?.interval,

                // },
                legend: { data: result?.series[0]?.data.map((item) => item?.name) },
            };
            onPropertyChange({
                option: newOption,
                chartType: "pie",
                //xAxis: option?.xAxis,
                pieDrillData: option?.drillData
            });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    option: newOption,
                    chartType: "pie",
                    // xAxis: option?.xAxis,
                    pieDrillData: option?.drillData
                },
            }));
            setIsAnimated(true);
            setFlag(false);
        }
    };

    const getDataForLevel = (crumb, value) => {
        //  const yAxisVal = properties?.yAxis;
        const matchedRecords = data?.filter(record =>
            record[value?.xAxis] === crumb?.xAxisValue
            && (properties?.legends?.length > 0 ? record[value?.yAxis] === crumb?.yAxisValue : true)
        );
        const matchedRecordsSec = matchedRecords?.filter(record =>
            record[value?.xAxis] === crumb?.xAxisValue && (properties?.legends?.length > 0 ? record[value?.yAxis] === crumb?.yAxisValue : true)
        );
        return (crumb?.level) == 1 ? matchedRecords : matchedRecordsSec;
    };
    const handleBreadcrumbClick = (crumb) => {
        if (crumb?.level == 0) {
            updateChartAxis({
                data: data,
                xAxis: properties?.colFields[0]?.columnName,
                yAxis: properties?.rowFields[0]?.columnName,
                zAxis: [],
                rowFields: properties?.rowFields,
                colFields: properties?.colFields,
                drillData: {
                    level: 0,
                    level1: { xAxis: "", yAxis: "" },
                    level2: { xAxis: "", yAxis: "" },
                    breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
                },
                line: properties?.line
            });
        } else {
            const axisValues = crumb?.level == 2 ? drillData?.level1 : { xAxis: properties?.colFields[0]?.columnName, yAxis: properties?.rowFields[0]?.columnName }
            const dataForLevel = getDataForLevel(crumb, axisValues);
            const newLevel = crumb?.level;
            const newBreadcrumbs = drillData.breadcrumbs.slice(0, newLevel + 1);
            const newDrillData = {
                //...drillData,
                level: crumb?.level,
                level1: crumb?.level == 0 ? { xAxis: drillData?.level === 0 ? properties?.colFields[1] : properties?.colFields[2], yAxis: properties?.rowFields[0] } : drillData?.level1,
                level2: crumb?.level == 1 ? { xAxis: drillData?.level === 0 ? properties?.colFields[1] : properties?.colFields[2], yAxis: properties?.rowFields[0] } : drillData?.level2,
                breadcrumbs: newBreadcrumbs
            }
            const axisValueForChart = drillData[`level${crumb?.level}`]
            updateChartAxis({
                data: dataForLevel,
                yAxis: axisValueForChart?.yAxis?.columnName,
                xAxis: axisValueForChart?.xAxis?.columnName,
                zAxis: [],
                rowFields: properties?.rowFields,
                colFields: properties?.colFields,
                drillData: newDrillData,
                line: properties?.line
            });
        }
    };
    const handleBreadCrumbPie = (crumb) => {
        if (crumb?.level == 0) {
            updateChartAxis({
                data: data,
                slice: properties?.slice[0]?.columnName,
                drillData: {
                    level: 0,
                    level1: { slice: "" },
                    level2: { slice: "" },
                    breadcrumbs: [{ level: 0, sliceValue: "Main" }]
                }
            });
        } else {
            const sliceValues = crumb?.level == 2 ? pieDrillData?.level1 : { slice: properties?.slice[0]?.columnName }
            const newLevel = crumb?.level;
            const newBreadcrumbs = pieDrillData.breadcrumbs.slice(0, newLevel + 1);

            const matchedRecords = data?.filter(record =>
                record[sliceValues?.slice] === crumb?.sliceValue
            );

            const matchedRecForSec = data?.filter(record =>
                record[properties?.slice[0]?.columnName] === pieDrillData?.breadcrumbs[1]?.sliceValue
            );

            const matchedRecordsSec = matchedRecForSec?.filter(record =>
                record[sliceValues?.slice] === crumb?.sliceValue
            );
            const newDrillData = {
                ...pieDrillData,
                level: crumb?.level,
                level1: crumb?.level == 0 ? { slice: pieDrillData?.level === 0 ? properties?.slice[1]?.columnName : properties?.slice[2]?.columnName } : pieDrillData?.level1,
                level2: crumb?.level == 1 ? { slice: pieDrillData?.level === 0 ? properties?.slice[1]?.columnName : properties?.slice[2]?.columnName } : pieDrillData?.level2,
                breadcrumbs: newBreadcrumbs
            }
            const axisValueForChart = pieDrillData[`level${crumb?.level}`]
            updateChartAxis({
                data: crumb?.level == 1 ? matchedRecords : matchedRecordsSec,
                slice: axisValueForChart?.slice,
                drillData: newDrillData
            });
        }
    }
    const formatNumber = (num) => {
        if (num == null) return;
        if (num >= 1000000000) return (num / 1000000000).toFixed(2) + "B";
        if (num >= 1000000) return (num / 1000000).toFixed(2) + "M";
        if (num >= 1000) return (num / 1000).toFixed(2) + "K";
        return num?.toString();
    };
    // const handleChartClick = (params) => {
    //     console.log(params, ">>> Parmeters of charts");
    //     handleApplyGlobalFilter({
    //         id: id,
    //         tableId: properties?.tableId,
    //         column: properties?.legends?.length > 0 ? properties?.legends[0]?.columnName : properties?.xAxis,
    //         value: properties?.legends?.length > 0 ? params?.seriesName : params?.name
    //     })
    // }
    const handleChartClick = (params) => {
        console.log(params, ">>> Parameters of charts");
        if (properties?.chartType === 'big_number_trendline') return;

        const isPieChart = properties?.chartType === 'pie';
        const isFunnelChart = properties?.chartType === 'funnel';
        const isGauge = properties?.chartType === 'gauge';


        // Check if filtering is already applied
        // const isFiltered = isPieChart
        //     ? properties?.option?.series[0]?.data?.some(s => s?.itemStyle?.opacity === 0.3)
        //     : properties?.option?.series?.some(s => s?.itemStyle?.opacity === 0.3);
        const isFiltered = isPieChart || isFunnelChart  || isGauge
            ? properties?.option?.series[0]?.data?.some(s => s?.itemStyle?.opacity === 0.3)
            : properties?.option?.series?.some(s => s?.itemStyle?.opacity === 0.3);

        if (isFiltered) {
            // Reset all bars/slices to original opacity
            properties.option.series = (isPieChart || isFunnelChart || isGauge)
                ? [{
                    ...properties.option.series[0],
                    data: properties.option.series[0]?.data.map(s => ({
                        ...s,
                        itemStyle: { ...s?.itemStyle, opacity: 1 }
                    }))
                }]
                : properties.option.series.map(s => ({
                    ...s,
                    itemStyle: { ...s?.itemStyle, opacity: 1 },
                    ...(s.lineStyle && {
                        lineStyle: { ...s?.lineStyle, opacity: 1 },
                    }),
                    ...(s.areaStyle && {
                        areaStyle: { ...s?.areaStyle, opacity: 0.4 },
                    }),
                    ...(s.type === "gauge" && {
                        axisLine: {
                            ...s?.axisLine,
                            lineStyle: {
                                ...s?.axisLine?.lineStyle,  // ✅ Corrected property reference
                                opacity: 1
                            }
                        }
                    })
                }));

            // Clear the global filter
            handleApplyGlobalFilter({
                id: id,
                tableId: properties?.tableId,
                isClear: true
            });

        } else {
            // Apply filter and update opacity
            let columns = [], values = [];

            if (isFunnelChart || properties?.chartType === 'gauge') {
                // Extract multiple series names and data names as arrays
                columns = properties?.chartType === 'gauge' ?
                    properties?.dimensions?.map(item => item?.columnName)
                    : params.seriesName.split(',').map(s => s.trim());

                values = params.name.split(',').map(s => s.trim());

            } else {
                columns = [isPieChart
                    ? params?.seriesName
                    : properties?.legends?.length > 0
                        ? (properties?.chartType === 'barWithLine' && params?.seriesType === 'line') ? properties?.line?.column_name : properties?.legends[0]?.columnName
                        : properties?.xAxis];

                values = [isPieChart
                    ? params?.name
                    : properties?.legends?.length > 0
                        ? params?.seriesName
                        : params?.name];
            }

            // Prepare an array of objects with column-value pairs
            const filterArray = columns.map((col, index) => ({
                column: col,
                value: values[index] || values[0]  // Ensure a matching value is picked
            }));

            handleApplyGlobalFilter({
                id: id,
                tableId: properties?.tableId,
                filters: filterArray
            });

            properties.option.series = (isPieChart || isFunnelChart 
                || isGauge
            )
                ? [{
                    ...properties.option.series[0],
                    data: properties.option.series[0]?.data.map(s => ({
                        ...s,
                        itemStyle: {
                            ...s?.itemStyle,
                            opacity: (s.name === params.seriesName || s.name === params.name) ? 1 : 0.3
                        },


                    }))
                }]
                : properties.option.series.map(s => ({
                    ...s,
                    itemStyle: {
                        ...s?.itemStyle,
                        opacity: (s.name === params.seriesName || s.name === params.name) ? 1 : 0.3
                    },
                    ...(s.lineStyle && {
                        lineStyle: { ...s?.lineStyle, opacity: (s.name === params.seriesName || s.name === params.name) ? 1 : 0.3 },
                    }),
                    ...(s.areaStyle && {
                        areaStyle: { ...s?.areaStyle, opacity: (s.name === params.seriesName || s.name === params.name) ? 0.4 : 0.1 },
                    }),
                    ...(s.type === "gauge" && {
                        axisLine: {
                            ...s?.axisLine,
                            lineStyle: {
                                ...s?.axisLine?.lineStyle,  // ✅ Corrected property reference
                                opacity: (s.name === params.seriesName || s.name === params.name) ? 1 : 0.3
                            }
                        }
                    })

                }));
        }

        setIsAnimated(true);
    };


    useEffect(() => {
        setLegendVisibility(option.legend?.data?.reduce((acc, item) => ({ ...acc, [item]: true }), {}));
    }, [option]);


    return (
        <>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                boxSizing: 'border-box',
                border: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                backgroundColor: `${properties?.option?.backgroundColor}`,
                // opacity: properties?.data?.length === 0 ? 0.3 : 1,
                position: 'relative',
                borderRadius: '3px'
                // borderRadius: `${properties?.borderRadius}px`
            }}
                className={`${selectedId == id ? "cursor-default" : "cursor-pointer"}`}
                onMouseOver={stopTableDrag()}
            >
                {
                    (properties?.chartType === 'big_number' && properties?.bigValue !== null) ? (
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                color: properties?.option?.textColor || '#333',
                                backgroundColor: properties?.option?.backgroundColor || 'transparent',
                                fontFamily: 'Inter',
                            }}
                            className='pt-1 flex flex-col items-center font-bold'
                        >
                            <span>
                                <p
                                    className='m-0 font-bold'
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                        color: `${properties?.option?.xAxis?.axisLabel?.color || '#4b5563'}`,
                                        fontFamily: `${properties?.option?.xAxis?.axisLabel?.fontFamily || "Inter"}`,
                                    }}
                                >
                                    {properties?.name}
                                </p>
                            </span>
                            <span className='text-center flex grow items-center'
                                style={{
                                    color: `${properties?.option?.xAxis?.axisLabel?.color || '#4b5563'}`,
                                    fontFamily: `${properties?.option?.xAxis?.axisLabel?.fontFamily || "Inter"}`,
                                    fontSize: `${option?.xAxis?.axisLabel?.fontSize || 20}px`,
                                }}
                            >
                                {formatNumber(properties?.bigValue)}
                            </span>

                        </div>

                    ) :

                        ((properties?.chartType === 'pie' && properties?.slice?.length > 0) ||
                            (properties?.chartType !== 'pie' &&
                                (properties?.rowFields?.length > 0 || properties?.colFields?.length > 0 || properties?.dimensions?.length > 0))) ?
                            <>
                                <span
                                    style={{
                                        // width: '200px', // Width for the legend container
                                        width: "100%",
                                        // backgroundColor: `${properties?.option?.backgroundColor}`,
                                        //borderRight: '1px solid #ccc',
                                        backgroundColor: 'transparent',
                                        fontSize: "12px",
                                        fontFamily: 'Inter',
                                        fontWeight: '600'
                                    }}>
                                    <div className='grid items-center '>
                                        <span className='text-[12px] text-left text-[#4b5563] pl-4 pt-1'>
                                            {properties?.chartType === 'pie' ?
                                                pieDrillData?.breadcrumbs?.length >= 2 && pieDrillData.breadcrumbs.map((crumb, index) => {
                                                    const isClickable = (breadcrumbsLength === 3 && index < 2) || (breadcrumbsLength === 2 && index === 0);
                                                    const color = isClickable ? '#105597' : '#4b5563';
                                                    const cursor = isClickable ? 'pointer' : 'default';
                                                    const textDecoration = isClickable ? 'underline' : 'none';
                                                    return (
                                                        <span key={index}>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    selectedId === id ?
                                                                        isClickable && handleBreadCrumbPie(crumb)
                                                                        : "";
                                                                }}
                                                                style={{
                                                                    background: 'transparent',
                                                                    border: 'none',
                                                                    color,
                                                                    cursor,
                                                                    textDecoration
                                                                }}
                                                                disabled={!isClickable}
                                                            >
                                                                {crumb?.sliceValue}
                                                            </button>
                                                            {index < breadcrumbsLength - 1 && ' / '}
                                                        </span>
                                                    )
                                                }) : drillData?.breadcrumbs?.length >= 2 && drillData.breadcrumbs.map((crumb, index) => {
                                                    const isClickable = (breadcrumbsLength === 3 && index < 2) || (breadcrumbsLength === 2 && index === 0);
                                                    const color = isClickable ? '#105597' : '#4b5563';
                                                    const cursor = isClickable ? 'pointer' : 'default';
                                                    const textDecoration = isClickable ? 'underline' : 'none';
                                                    return (
                                                        <span key={index}>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    selectedId === id ?
                                                                        isClickable && handleBreadcrumbClick(crumb)
                                                                        : "";
                                                                }}
                                                                style={{
                                                                    background: 'transparent',
                                                                    border: 'none',
                                                                    color,
                                                                    cursor,
                                                                    textDecoration
                                                                }}
                                                                disabled={!isClickable}
                                                            >
                                                                {crumb?.xAxisValue}
                                                            </button>
                                                            {index < breadcrumbsLength - 1 && ' / '}
                                                        </span>
                                                    )
                                                })}
                                        </span>
                                        {breadcrumbsLength <= 3 && (
                                            <p
                                                className='m-0 font-bold'
                                                style={{
                                                    textAlign: 'center',
                                                    fontSize: '15px',
                                                    color: `${properties?.option?.xAxis?.axisLabel?.color || '#4b5563'}`,
                                                    fontFamily: `${properties?.option?.xAxis?.axisLabel?.fontFamily || "Inter"}`,
                                                }}
                                            >
                                                {properties?.name}
                                            </p>
                                        )}
                                    </div>
                                    <div id="legend-container" style={{ overflowY: 'auto' }} />
                                </span>
                                <div
                                    className={` ${properties?.chartType !== 'pie' ? 'overflow-auto custom-scrollbar' : ''}`}
                                    style={{
                                        flex: 1, position: 'relative', width: '100%', display: 'flex',
                                    }}>
                                    {properties?.chartType !== 'pie' && (
                                        <div
                                            className='y-axis-label'
                                            style={{
                                                writingMode: 'vertical-rl',
                                                transform: 'rotate(180deg)',
                                                fontSize: `${option?.xAxis?.axisLabel?.fontSize || 11}px`,
                                                fontFamily: option?.xAxis?.axisLabel?.fontFamily || 'Inter',
                                                color: option?.xAxis?.axisLabel?.color || '#4b5563',
                                                // fontSize: `${option?.yAxis?.axisLabel?.fontSize || 11}px`,
                                                // fontFamily: option?.yAxis?.axisLabel?.fontFamily || 'Inter',
                                                // color: option?.yAxis?.axisLabel?.color || '#4b5563',
                                                textAlign: 'center',
                                                height: 'auto',
                                                paddingTop: '60px',
                                                paddingRight: '5px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {properties?.yAxis?.toString() || ''}
                                        </div>
                                    )}
                                    {/* <svg ref={chartRef} style={{ width: '100%', height: '100%' }} /> */}
                                    {properties?.chartType !== 'pie' ?
                                        (
                                            <div
                                                className='overflow-auto custom-scrollbar'
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    flex: 1,
                                                    position: 'relative',
                                                    width: "100%"
                                                }}
                                            >
                                                {/* <svg ref={chartRef} style={{ width: '100%', height: '100%' }} /> */}
                                                <EChartsBarChart
                                                    option={option}
                                                    properties={properties}
                                                    onChartClick={handleChartClick}
                                                />
                                                <div
                                                    style={{
                                                        fontSize: `${option?.xAxis?.axisLabel?.fontSize || 11}px`,
                                                        fontFamily: option?.xAxis?.axisLabel?.fontFamily || 'Inter',
                                                        color: option?.xAxis?.axisLabel?.color || '#4b5563',
                                                        textAlign: 'center',
                                                        paddingBottom: '5px',
                                                        height: 'auto',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {properties?.xAxis?.toString() || ''}
                                                </div>
                                            </div>
                                        )
                                        :
                                        // (<svg ref={chartRef} style={{ width: '100%', height: '100%' }} />)
                                        //    <EChartsBarChart option={option} properties={properties}/>
                                        <div
                                            className='overflow-auto custom-scrollbar'
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                flex: 1,
                                                position: 'relative',
                                                width: "100%"
                                            }}
                                        >
                                            <EChartsBarChart
                                                option={option}
                                                properties={properties}
                                                onChartClick={handleChartClick}
                                            />
                                            <div
                                                style={{
                                                    fontSize: `${option?.xAxis?.axisLabel?.fontSize || 12}px`,
                                                    fontFamily: option?.xAxis?.axisLabel?.fontFamily || 'Inter',
                                                    color: option?.xAxis?.axisLabel?.color || '#4b5563',
                                                    textAlign: 'center',
                                                    paddingBottom: '5px',
                                                    height: 'auto',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {properties?.option?.series[0]?.name}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </> :
                            <div style={{ position: 'relative', width: '100%', height: '100%', backgroundColor: 'white' }}>
                                {properties?.chartType === "big_number" ?
                                    (
                                        <img
                                            src={big_number_default}
                                            alt="Selected"
                                            style={{ width: '100%', height: '80%', objectFit: 'contain' }}
                                        />
                                    ) :
                                    (
                                        <img
                                            src={Default_Chart}
                                            alt="Selected"
                                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                        />
                                    )
                                }
                                {/* {properties?.data?.length === 0 && properties?.chartType === "big_number" && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '70%',
                                        left: '50%',
                                        width:'100%',
                                        transform: 'translate(-50%, -50%)',
                                        color: '#ccd1d8',
                                        pointerEvents: 'none',
                                        textAlign: 'center',
                                        fontSize: '11px',
                                        fontFamily: "Inter",
                                        zIndex: 1,
                                        backgroundColor: '#ffffff',
                                        paddingLeft:'10px',
                                        paddingRight:'10px',
                                        opacity: 1,                                     
                                        borderRadius: '5px'
                                    }}>
                                        'Drag columns from Data Source Panel and drop them into Dimensions Fields to create your chart'
                                    </div>
                                )}
                                {properties?.data?.length === 0 && properties?.chartType !== "big_number" && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: '#4b5563',
                                        pointerEvents: 'none',
                                        textAlign: 'center',
                                        fontSize: '13px',
                                        fontFamily: "Inter",
                                        zIndex: 1,
                                        backgroundColor: '#ffffff',
                                        opacity: 1,
                                        padding: '10px',
                                        borderRadius: '5px'
                                    }}>
                                        'Drag columns from Data Source Panel and drop them into X-axis/Y-axis Fields to create your chart'
                                    </div>
                                )} */}

                                {properties?.data?.length === 0 && (
                                    <div style={{
                                        position: 'absolute',
                                        top: properties?.chartType === "big_number" ? '70%' : '50%',
                                        left: '50%',
                                        width: properties?.chartType === "big_number" ? '100%' : 'auto',
                                        transform: 'translate(-50%, -50%)',
                                        color: properties?.chartType === "big_number" ? '#ccd1d8' : '#4b5563',
                                        pointerEvents: 'none',
                                        textAlign: 'center',
                                        fontSize: properties?.chartType === "big_number" ? '11px' : '13px',
                                        fontFamily: "Inter",
                                        zIndex: 1,
                                        backgroundColor: '#ffffff',
                                        padding: '10px',
                                        opacity: 1,
                                        borderRadius: '5px'
                                    }}>
                                        {properties?.chartType === "big_number"
                                            ? 'Drag columns from Data Source Panel and drop them into Dimensions Fields to create your chart'
                                            : 'Drag columns from Data Source Panel and drop them into X-axis/Y-axis Fields to create your chart'}
                                    </div>
                                )}
                            </div>
                }
            </div >
        </>
    );
};
export default ChartComponent;