import './dataManagementstyle.css'
import * as XLSX from "xlsx";
import { Alert, Snackbar } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import {
    useAddDatatsetMutation,
    useAppendDataMutation,
    useConnectDataBaseMutation,
    useCreateComputedColumnMutation,
    useCreateTableMutation,
    useFetchSchemaMutation,
    useFetchTableMetadataMutation,
    useFetchTablesMutation,
    useFilterTableDataSbMutation,
    useGetAllDatabaseMutation,
    useGetAllTableSkorBoardMutation,
    useGetDistValuesMutation,
    useGetTableDataMutation,
    useGetTableDataUsingQueryMutation,
    useRemoveConnectionMutation,
    userFetchTableMetadataMutation,
    useUpdateTableByNameMutation,
    useUploadExcelFileMutation,
    useValidateDataMutation
} from '../../../features/report/skorBoard'
import {
    useFilterTableDataMutation,
    useGetAllTablesMutation,
    useGetTableDataByNameMutation,
} from '../../../features/report/reportApiSlice'

import { Box } from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from '@mui/material/CircularProgress';
import MainContainer from './components/MainContainer'
import Menu from './components/Menu'
import Nav from './components/Nav'
import Papa from "papaparse";
import PropertiesPanel from './components/PropertiesPanel'
import ReusableDataModal from './components/ReusableDataModal'
import Stack from '@mui/material/Stack';
import moment from 'moment';
import SQLIcon from '../../../assets/svgs/Sqlsvg';
import SupersetContext from '../../../context/superset';
import ReusableReportModal from '../ReportDesigner/components/ReusableReportModal';



moment.parseTwoDigitYear = (year) => {
    return parseInt(year, 10) + (year < 35 ? 2000 : 1900); // Set pivot at 57
};

function DataManagement() {
    //States 
    const superset = useContext(SupersetContext);
    const databaseOptions = [
        {
            name: "PostgreSQL", icon: <SQLIcon
                width={30}
                fill={'#c5c8cb'}
                height={30} />, type: "postgresql"
        },
        {
            name: "MySQL", icon: <SQLIcon
                width={30}
                fill={'#c5c8cb'}
                height={30} />, type: "mysql"
        },
        {
            name: "Presto", icon: <SQLIcon
                width={30}
                fill={'#c5c8cb'}
                height={30} />, type: "presto"
        },
        {
            name: "SqLite", icon: <SQLIcon
                width={30}
                fill={'#c5c8cb'}
                height={30} />, type: "sqlite"
        },
        {
            name: "Sql Server", icon: <SQLIcon
                width={30}
                fill={'#c5c8cb'}
                height={30} />, type: "mssql"
        },
    ];
    const dateFormats = [
        'YYYY-MM-DDTHH:mm:ss.SSSZ',  // 2025-02-14T15:30:45.123Z
        'YYYY-MM-DDTHH:mm:ssZ',      // 2025-02-14T15:30:45Z
        'D/M/YY',
        'D/M/YYYY',
        'DD-MM-YYYY',        // 14-02-2025
        'MM-DD-YYYY',        // 02-14-2025
        'YYYY-MM-DD',        // 2025-02-14
        'DD/MM/YYYY',        // 14/02/2025
        'MM/DD/YYYY',        // 02/14/2025
        'YYYY/MM/DD',        // 2025/02/14
        'D/M/YY HH:mm',
        'D/M/YYYY HH:mm',
        'DD-MM-YYYY HH:mm',       // 14-02-2025 15:30
        'MM-DD-YYYY HH:mm',       // 02-14-2025 15:30
        'YYYY-MM-DD HH:mm',       // 2025-02-14 15:30
        'DD/MM/YYYY HH:mm',       // 14/02/2025 15:30
        'MM/DD/YYYY HH:mm',       // 02/14/2025 15:30
        'YYYY/MM/DD HH:mm',       // 2025/02/14 15:30
        'D/M/YY HH:mm:ss',
        'D/M/YYYY HH:mm:ss',
        'DD-MM-YYYY HH:mm:ss',    // 14-02-2025 15:30:45
        'YYYY-MM-DD HH:mm:ss',    // 2025-02-14 15:30:45
        'YYYY-MM-DDTHH:mm:ssZ',   // 2025-02-14T15:30:45Z (ISO 8601)
        'YYYY-MM-DDTHH:mm:ss.SSSZ',  // 2025-02-14T15:30:45.123Z
        'YYYY-MM-DDTHH:mm:ssZ',      // 2025-02-14T15:30:45Z
        'DD-MM-YY', 'MM-DD-YY', 'YY-MM-DD', // <-- Added for two-digit years
        'DD/MM/YY', 'MM/DD/YY', 'YY/MM/DD',
        'DD-MM-YY HH:mm', 'MM-DD-YY HH:mm', 'YY-MM-DD HH:mm', // <-- Two-digit year with time
        'DD/MM/YY HH:mm', 'MM/DD/YY HH:mm', 'YY/MM/DD HH:mm',
        'DD-MM-YY HH:mm:ss', 'YY-MM-DD HH:mm:ss',
        'dddd, MMMM Do YYYY',      // Friday, February 14th 2025
        'ddd, MMM D, YYYY',        // Fri, Feb 14, 2025
        'MMMM D, YYYY',            // February 14, 2025
        'Do MMMM YYYY',            // 14th February 2025
    ];
    // const supersetLoginToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6dHJ1ZSwiaWF0IjoxNzQxMDc4NDk0LCJqdGkiOiI3NTQyNzUzMC1iOWM5LTQ0OGEtYTVkYS00ZTY2YzEwMjc4ZjMiLCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoxLCJuYmYiOjE3NDEwNzg0OTQsImNzcmYiOiJlZTYzOTU3OS02NzI0LTQyNTUtODdiNi0wNjdmODFkZDAwYmQiLCJleHAiOjE3NDEwNzkzOTR9.NsCnaM27XKtxmhweujaweJjwndmQUG1fetjWeG-f-xU';

    // const supersetCsrfToken = 'IjIyZjhhZTg3ODA3YzRlZDlhOGZhZDliYmQyYzVmNWI1NDg3NzE3Mzki.Z8bAyA.C2vQrudFQmVz8O6d_hSdfFjnUmk'

    const [supersetLoginToken, setSupersetLoginToken] = useState(null);
    const [supersetCsrfToken, setSupersetCsrfToken] = useState(null);

    const [components, setComponents] = useState([]);
    const [tables, setTables] = useState([]);
    const [componentCount, setComponentCount] = useState(1);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [activeNavItem, setActiveNavItem] = useState("Home");
    const [isTableloading, setIsTableLoading] = useState(true);
    const [count, setCount] = React.useState(0);
    const [selectedColumn, setSelectedColumn] = React.useState(null);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(100);
    const [computedColumn, setComputedColumn] = React.useState({ status: false, name: "column", isNew: true, oldName: "" });
    const [isExpand, setIsExpand] = React.useState(true);
    const [isExcelUpload, setIsExcelUpload] = React.useState(true)
    const [message, setMessage] = React.useState("Something went wrong");
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState("error");
    const [label, setLabel] = useState('column');
    const [query, setQuery] = useState("");
    const [primaryProperties, setPrimaryProperties] = useState({});
    const [errorComputed, setErrorComputed] = React.useState({ status: false, message: "" });
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [validateModalOpen, setValidateModalOpen] = React.useState({ status: false, data: [], dataToAppend: [], typeErrors: [] });
    const [isLoading, setIsLoading] = React.useState(false);
    const [loadingStep, setLoadingStep] = React.useState("");
    const [isDatasetLoading, setIsDatasetLoading] = React.useState(false);
    const [datasetLoadingStep, setDatasetLoadingStep] = React.useState("")
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [tableNameFromUser, setTableNameFromUser] = React.useState({ name: null, description: null });
    const [isAddDatasetModalOpen, setIsAddDatasetModalOpen] = React.useState(false);
    const [isQueryModalOpen, setIsQueryModalOpen] = React.useState(false);

    // Superset //
    // const [isCreateCon, setIsCreateCon] = React.useState(false);
    // const [credentials, setCredentials] = useState({
    //     host: "",
    //     port: "",
    //     username: "",
    //     password: "",
    //     displayName: '',
    //     database: ""
    // });
    // const [selectedDB, setSelectedDB] = useState(null);
    // const [isDatabaseView, setIsDatabaseView] = useState(false);
    // const [dbConnections, setDbConnections] = useState([]);
    // const [isDatabseLoading, setIsDataBaseLoading] = useState(false);
    // const [isDatasetView, setIsDatasetView] = useState(false);
    // const [tableMetaData, setTableMetadata] = useState({ metaData: [], tableData: [] });
    // const [selectedDatabase, setselectedDatabse] = useState(null);
    // const [selectedSchema, setSelectedSchema] = useState(null);
    // const [selectedTable, setSelectedTable] = useState(null);
    // const [datasettableloading, setDatasettableloading] = React.useState(false);
    // const [selectedLimit, setSelectedLimit] = React.useState("");
    // const limitOptions = [10, 100, 1000, 10000];
    // const [sqlQuery, setSqlQuery] = useState(`SELECT * FROM "${tableMetaData?.metaData?.name}"`);
    // const [schemas, setSchemas] = useState([]);
    // const [supersetTables, setSupersetTables] = useState([]);


    // API Mutation
    const [getAllTables] = useGetAllTablesMutation();
    const [getTableDataByName] = useGetTableDataByNameMutation();
    const [filterData] = useFilterTableDataMutation();
    const [uplodExcel] = useUploadExcelFileMutation();
    const [getAllTablesSkorBoard] = useGetAllTableSkorBoardMutation();
    const [getTableDataSkorBoard] = useGetTableDataMutation();
    const [filteredDataSkorboard] = useFilterTableDataSbMutation();
    const [updateTableSkorboard] = useUpdateTableByNameMutation();
    const [createComputedColumn] = useCreateComputedColumnMutation();
    const [validateTableData] = useValidateDataMutation();
    const [appendData] = useAppendDataMutation();
    const [getDistValues] = useGetDistValuesMutation();
    const [createTableNew] = useCreateTableMutation();
    const [connectDbSuperset] = useConnectDataBaseMutation();
    const [removeConnection] = useRemoveConnectionMutation();
    const [getAllDatabaseConnections] = useGetAllDatabaseMutation();
    const [fetachChemas] = useFetchSchemaMutation();
    const [fetchTable] = useFetchTablesMutation();
    const [fetachTableMetaData] = useFetchTableMetadataMutation();
    const [getTableDataUsingQuery] = useGetTableDataUsingQueryMutation();
    const [createDataset] = useAddDatatsetMutation();

    // Handler 
    const handleOnImportCareSms = async () => {
        setIsExcelUpload(false);
        const result = await getAllTables().unwrap();
        setComponents(result?.data?.map((item, index) => {
            return {
                id: `component-${index + 1}`,
                table_name: item?.table_name,
                columns: item?.columns,
                data: [],
                columnProperties: item?.columns?.map((column) => ({ name: column })),
                filters: []
            }
        }))
        setComponentCount(result?.data?.length + 1);
    }
    const handleOnImportExcel = async (tableName) => {
        setIsExcelUpload(true);
        const result = await getAllTablesSkorBoard().unwrap();
        const ind = result?.data?.findIndex(obj => obj["table_name"] === tableName) == -1 ? 0 : result?.data?.findIndex(obj => obj["table_name"] === tableName);
        if (result?.data?.length > 0) {
            const tableData = await tableDataApi(result?.data[ind]?.table_name);
            const Distvalues = await getDistValues({
                tableId: tableData?.metaData?.tableMetaData?.table_id,
                skip: 0, limit: 20,

                filters: {
                    searchQuery: "",
                    dateFilter: {},
                    dateColumn: null,
                    columns: []
                    ,
                    columnFilters: {
                        column_name: null,
                        searchQuery: ""
                    }
                }
            })?.unwrap();

            const columnTypes = getColumnTypes(tableData?.columns);
            const defalult = tableData?.columns.map((item, index) => {
                return {
                    ...item,
                    type: columnTypes[item?.name] || "varchar",
                    format: "",
                    currency: "",
                    isPercentage: false,
                    decimal: 0,
                    isComma: false,
                    new_column_name: item?.name,
                    sequence_number: index,
                    allowNull:
                        item?.colName?.trim() == "id" ||
                            item?.colName?.trim()?.endsWith("_id") ? false : true,
                    values: Distvalues?.data == null ? [] : Distvalues?.data[item?.name]?.values,
                    totalCount: Distvalues?.data == null ? 0 : Distvalues?.data[item?.name]?.total_count
                };
            })
            const updatedPrope =
                tableData?.tableProperties ?
                    tableData?.tableProperties?.columnProperties?.map((item) => ({
                        ...item,
                        name: item?.new_column_name,
                        values: Distvalues?.data == null ? [] : Distvalues?.data[item?.new_column_name]?.values,
                        totalCount: Distvalues?.data == null ? 0 : Distvalues?.data[item?.new_column_name]?.total_count
                    })) : defalult;

            const comps = result?.data?.map((item, index) => {
                if (index == ind) {
                    setPrimaryProperties({
                        id: `component-${index + 1}`,
                        table_name: item?.table_name,
                        tableId: tableData?.metaData?.tableMetaData?.table_id,
                        new_table_name: item?.table_name,
                        data: tableData?.tableData,
                        columns: tableData.columns?.map((item) => item.name),
                        columnProperties: updatedPrope,
                        filters: [],
                    });
                    return {
                        id: `component-${index + 1}`,
                        table_name: item?.table_name,
                        tableId: tableData?.metaData?.tableMetaData?.table_id,
                        new_table_name: item?.table_name,
                        data: tableData?.tableData,
                        columns: tableData.columns?.map((item) => item.name),
                        columnProperties: updatedPrope,
                        filters: [],
                        tableData: [],
                        datasetProperties: item?.tableMetaData?.dataset_property,
                    }
                } else {
                    return {
                        id: `component-${index + 1}`,
                        table_name: item?.table_name,
                        tableId: tableData?.metaData?.tableMetaData?.table_id,
                        new_table_name: item?.table_name,
                        columns: item?.columns?.map((column) => column?.name),
                        data: [],
                        columnProperties: item?.columns?.map((column) => ({
                            name: column?.name,
                            values: Distvalues?.data == null ? [] : Distvalues?.data[column?.name]?.values,
                            totalCount: Distvalues?.data == null ? 0 : Distvalues?.data[column?.name]?.total_count
                        })),
                        filters: [],
                        tableData: [],
                        datasetProperties: item?.tableMetaData?.dataset_property,
                    }
                }
            })
            setComponents(comps);
            console.log(comps,"compsssss")
            setSelectedComponent(comps[ind]);
            setComponentCount(result?.data?.length + 1);
            setCount(tableData?.totalCount);
            superset?.setDatasetProperties(tableData?.datasetProperties)
            setIsTableLoading(false);
        }
        setIsTableLoading(false)
    }
    const handlePropertyChange = (propertyChange, id) => {
        const updatedComponents = components?.map((item) => {
            if (item?.id === (id !== null ? id : selectedComponent?.id)) {
                return {
                    ...item,
                    ...propertyChange,
                };
            }
            return item;
        });
        setComponents(updatedComponents);

    };
    const determineColumnType = (value) => {
        if (value === null || value === undefined || value === "" || value === 'character varying' || value === 'VARCHAR') {
            return "varchar";
        }
        if (value === 'integer' || value === 'numeric' || value === "INT" || value === 'double precision' || value === 'FLOAT') {
            return "int";
        }
        if (value === "boolean" || value === 'BOOLEAN') {
            return "boolean";
        }
        if (value === 'timestamp without time zone' || value === 'TIMESTAMPTZ' || value === 'TIMESTAMP') {
            return "timestamp";
            //  return "date"
        }
        // if (value === 'date' || value === 'DATE') {
        //     return "date"
        // }
        return "varchar";
    };
    const getColumnTypes = (data) => {
        const columnTypes = {};
        data.forEach((column) => {
            columnTypes[column?.name] = determineColumnType(column?.type);
        });
        return columnTypes;
    };
    const tableDataApi = async (tableName) => {
        const tableData = isExcelUpload ?
            await getTableDataSkorBoard({ name: tableName, skip: 0, limit: 100 })?.unwrap()
            : await getTableDataByName({ name: tableName, skip: 0 })?.unwrap();
        return tableData?.data
    }
    const getTableData = async (component, name,) => {
        setIsTableLoading(true)
        const tableData = await tableDataApi(name ? name : component?.table_name);
        const Distvalues = await getDistValues({
            tableId: tableData?.metaData?.tableMetaData?.table_id, skip: 0, limit: 20,
            filters: {
                searchQuery: "",
                dateFilter: {},
                dateColumn: null,
                columns: [],
                columnFilters: {
                    column_name: null,
                    searchQuery: ""
                }
            }
        })?.unwrap();

        const updatedPrope =
            tableData?.tableProperties ?
                tableData?.tableProperties?.columnProperties?.map((item) => ({
                    ...item,
                    name: item?.new_column_name,
                    values: Distvalues?.data == null ? [] : Distvalues?.data[item?.new_column_name]?.values,
                    totalCount: Distvalues?.data == null ? 0 : Distvalues?.data[item?.new_column_name]?.total_count
                })) : defalult;
        const columnTypes = getColumnTypes(tableData?.columns);
        const defalult = tableData?.columns.map((item, index) => {
            return {
                ...item,
                type: columnTypes[item?.name] || "varchar",
                format: "",
                currency: "",
                isPercentage: false,
                decimal: 0,
                isComma: false,
                new_column_name: item?.name,
                sequence_number: index,
                values: Distvalues?.data == null ? [] : Distvalues?.data[item?.name]?.values,
                totalCount: Distvalues?.data == null ? 0 : Distvalues?.data[item?.name]?.total_count
            };
        })
        setPrimaryProperties({
            table_name: name ? name : component?.table_name,
            tableId: tableData?.metaData?.tableMetaData?.table_id,
            columns: tableData.columns?.map((item) => item.name),
            columnProperties: updatedPrope,
            filters: []
        });
        setSelectedComponent({
            ...component,
            data: tableData?.tableData,
            table_name: name ? name : component?.table_name,
            tableId: tableData?.metaData?.tableMetaData?.table_id,
            columns: tableData.columns?.map((item) => item.name),
            columnProperties: updatedPrope,
            filters: [],
            tableData: []
        })
        handlePropertyChange({
            data: tableData?.tableData,
            table_name: name ? name : component?.table_name,
            tableId: tableData?.metaData?.tableMetaData?.table_id,
            columns: tableData.columns?.map((item) => item.name),
            columnProperties: updatedPrope,
            filters: [],
            tableData: []
        }, component?.id);
        setCount(tableData?.totalCount);
        superset?.setDatasetProperties(tableData?.datasetProperties)
        setIsTableLoading(false);
        setSelectedColumn(null)
    }
    const fetchTableData = async (obj) => {
        try {

            // const tableData = await getTableDataByName({ name: selectedComponent?.table_name, limit, skip })?.unwrap();
            const tabledata = isExcelUpload ?
                await filteredDataSkorboard(
                    {
                        tableName: selectedComponent?.table_name,
                        filters: selectedComponent?.filters,
                        skip: obj?.skip ? obj?.skip : skip,
                        limit: obj?.limit ? obj?.limit : limit,
                        sortBy: (obj?.sortColumn && obj?.order !== 'clear') ? obj?.sortColumn : null,
                        direction: (!obj?.order || obj?.order) === 'clear' ? 'ASC' : obj?.order
                    }).unwrap()
                : await filterData(
                    {
                        tableName: selectedComponent?.table_name,
                        filters: selectedComponent?.filters,
                        skip: obj?.skip ? obj?.skip : skip,
                        limit: obj?.limit ? obj?.limit : limit,
                        sortBy: obj?.sortColumn ? obj?.sortColumn : null,
                        direction: obj?.order ? obj?.order : 'ASC'
                    }).unwrap();

            setSelectedComponent({
                ...selectedComponent,
                data: tabledata?.data?.data,
            })
            handlePropertyChange({
                data: tabledata?.data?.data,
            }, null);
            setSkip(skip);
            setLimit(limit)
            setCount(tabledata?.data?.totalCount);

        } catch (err) {
            console.log(err, "Error")
        }
    }
    const handleNewColumn = async () => {
        setQuery("");
        setSelectedColumn(null)
        setComputedColumn({ ...computedColumn, status: true, isNew: true, name: generateColumnName(selectedComponent?.columns), oldName: generateColumnName(selectedComponent?.columns) });
        setErrorComputed({
            status: false,
            message: ""
        })
    }
    const transformToJSON = (data) => {

        // Extract headers and normalize them to lowercase
        const headers = data[0]?.map((item) => item?.toLowerCase());
        const rows = data.slice(1);

        // Filter out completely empty rows (e.g., [''])
        const filteredRows = rows.filter((row) =>
            row.some((cell) => cell !== null && cell !== "" && cell !== undefined)
        );

        // Determine all possible keys
        const allKeys = new Set(headers);

        // Normalize each row into an object
        return filteredRows.map((row) => {
            const obj = {};
            row.forEach((cell, i) => {
                obj[headers[i]] = cell;
            });

            // Add missing keys with null value
            allKeys.forEach((key) => {
                if (!(key in obj)) {
                    obj[key] = null;
                }
            });

            return obj;
        });
    };

    const cleanAndDetermineDataTypes = ({ data, isXLS }) => {
        if (!Array.isArray(data) || data.length === 0) {
            return { cleanedData: [], columns: [] };
        }

        // Get headers and filter out empty ones
        const headers = Object.keys(data[0]).filter(
            (header) => header && header.trim() !== ""
        ).map((header) => header.toLowerCase());

        const convertExcelDateToISO = (excelSerial) => {
            if (!isNaN(excelSerial) && excelSerial > 0) {
                const milliseconds = excelSerial * 24 * 60 * 60 * 1000; // Convert days to milliseconds
                const date = new Date();
                return moment(date).utc().toISOString();
            }
            return null;
        };

        const dateFormat = {};
        // Clean the data rows
        const cleanedData = data.map((row) => {
            const cleanedRow = {};
            headers.forEach((header) => {
                const originalHeader = Object.keys(row).find(
                    (key) => key.toLowerCase() === header
                );
                if (originalHeader) {
                    let value = row[originalHeader];
                    if (!isXLS) {
                        // Convert "NULL" to null
                        if (value === null || value === undefined || value === '' || value === 'NULL') {
                            value = null;
                        }
                        // Convert to number if it's a valid integer
                        else if (!isNaN(value) && value !== "") {
                            value = Number(value);
                        }
                        // Parse valid dates into ISO string format
                        else if (!isNaN(Date.parse(value))) {
                            // let validDate = value; // Keep original value
                            for (let format of dateFormats) {
                                const momentDate = moment(value, format, true); // Check if it's a valid date
                                if (momentDate.isValid()) {
                                    value = momentDate.utc(true).toISOString(); // Convert only if valid
                                    dateFormat[header] = format === 'D/M/YY' ? 'DD/MM/YYYY' : format === 'D/M/YY HH:mm' ? 'DD/MM/YYYY HH:mm' : format
                                    break;
                                }
                            }
                        } // original
                        cleanedRow[header] = value;
                        // else {

                        //     const isoDate = convertToISO(value);
                        //     if (isoDate) {
                        //         value = isoDate;
                        //     }
                        // }

                        // else if (!isNaN(Date.parse(value))) {
                        //     if (containsTimestamp(value)) {
                        //         value = new Date(value).toISOString();
                        //     }
                        // }
                    } else {
                        let validDate = value; // Keep original value
                        for (let format of dateFormats) {
                            const momentDate = moment(value, format, true); // Check if it's a valid date
                            if (momentDate.isValid()) {
                                validDate = momentDate.utc(true).toISOString(); // Convert only if valid
                                dateFormat[header] = format === 'D/M/YY' ? 'DD/MM/YYYY' : format === 'D/M/YY HH:mm' ? 'DD/MM/YYYY HH:mm' : format
                                break;
                            }
                        }
                        cleanedRow[header] = validDate; // Assign either converted or original value
                    }
                }
            });
            return cleanedRow;
        });
        const columns = headers.map((header) => {
            const cleanedColName = header.replace(/^"+|"+$/g, "").trim();
            const columnValues = cleanedData.map((row) => row[header]);
            return { colName: cleanedColName.toLowerCase(), type: determineColumnTypeFile(columnValues), allowNull: true };
        });

        return { cleanedData, columns, dateFormat };
    };



    const determineColumnTypeFile = (values) => {
        const nonEmptyValues = values.filter((value) => value !== null && value !== undefined && value !== "");

        // If all values are null, return "VARCHAR"
        if (values.every(value => value === null)) {
            return "VARCHAR";
        }
        const hasNullValues = values.includes(null);
        if (hasNullValues) {
            return "VARCHAR";
        }
        if (nonEmptyValues.some((value) => !isNaN(Number(value)) && Number(value) % 1 !== 0)) {
            return "FLOAT";
        }
        if (nonEmptyValues.every((value) => !isNaN(Number(value)))) {
            return "INT";
        }
        if (nonEmptyValues.every((value) => typeof value === "boolean")) {
            return "BOOLEAN";
        }
        if (nonEmptyValues.every((value) => !isNaN(Date.parse(value)))) {
            return "TIMESTAMPTZ";
        }
        // const containsTimestamp = (dateString) => {
        //     return /\d{2}:\d{2}(:\d{2})?/.test(dateString); // Check if time (HH:mm or HH:mm:ss) is present
        // };

        // Check if all non-empty values are dates
        // if (nonEmptyValues.every((value) => !isNaN(Date.parse(value)))) {
        //     if (nonEmptyValues.some((value) => containsTimestamp(value))) {
        //         return "TIMESTAMPTZ"; // If any value has a timestamp
        //     }
        //     return "DATE"; // Otherwise, it's just a date
        // }
        return "VARCHAR";
    };

    const readFileContent = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);

            if (file?.type === "text/csv") {
                reader.readAsText(file);
            } else {
                reader.readAsArrayBuffer(file);
            }
        });
    };

    const dateformat = [
        { format: "YYYY-MM-DDTHH:mm:ss.SSSZ" },
        { format: "YYYY-MM-DDTHH:mm:ssZ" },
        { format: "D/M/YY" },
        { format: "D/M/YYYY" },
        { format: "DD-MM-YYYY" },
        { format: "MM-DD-YYYY" },
        { format: "YYYY-MM-DD" },
        { format: "DD/MM/YYYY" },
        { format: "MM/DD/YYYY" },
        { format: "YYYY/MM/DD" },
        { format: "D/M/YY HH:mm" },
        { format: "D/M/YYYY HH:mm" },
        { format: "DD-MM-YYYY HH:mm" },
        { format: "MM-DD-YYYY HH:mm" },
        { format: "YYYY-MM-DD HH:mm" },
        { format: "DD/MM/YYYY HH:mm" },
        { format: "MM/DD/YYYY HH:mm" },
        { format: "YYYY/MM/DD HH:mm" },
        { format: "D/M/YY HH:mm:ss" },
        { format: "D/M/YYYY HH:mm:ss" },
        { format: "DD-MM-YYYY HH:mm:ss" },
        { format: "YYYY-MM-DD HH:mm:ss" },
        { format: "YYYY-MM-DDTHH:mm:ssZ" },
        { format: "YYYY-MM-DDTHH:mm:ss.SSSZ" },
        { format: "YYYY-MM-DDTHH:mm:ssZ" },
        { format: "DD-MM-YY" },
        { format: "MM-DD-YY" },
        { format: "YY-MM-DD" },
        { format: "DD/MM/YY" },
        { format: "MM/DD/YY" },
        { format: "YY/MM/DD" },
        { format: "DD-MM-YY HH:mm" },
        { format: "MM-DD-YY HH:mm" },
        { format: "YY-MM-DD HH:mm" },
        { format: "DD/MM/YY HH:mm" },
        { format: "MM/DD/YY HH:mm" },
        { format: "YY/MM/DD HH:mm" },
        { format: "DD-MM-YY HH:mm:ss" },
        { format: "YY-MM-DD HH:mm:ss" },
        { format: "dddd, MMMM Do YYYY" },
        { format: "ddd, MMM D, YYYY" },
        { format: "MMMM D, YYYY" },
        { format: "Do MMMM YYYY" }
    ];

    const handleNameChange = (newName) => {
        if (!selectedComponent) return;
        setComponents((prevComponents) => {
            const updatedComponents = prevComponents?.map((component) =>
                component?.id === selectedComponent?.id
                    ? { ...component, new_table_name: newName }
                    : component
            );
            const updatedSelectedComponent = updatedComponents?.find(
                (component) => component?.id === selectedComponent?.id
            );
            setSelectedComponent(updatedSelectedComponent);
            return updatedComponents;
        });
    };
    const handleColumnNameChange = (newColumnName) => {
        if (!selectedColumn || !selectedComponent) return;
        if (selectedColumn && selectedComponent) {
            setComponents((prevComponents) => {
                const updatedComponents = prevComponents?.map((component) =>
                    component?.id === selectedComponent?.id
                        ? {
                            ...component,
                            columnProperties: component?.columnProperties?.map((column) =>
                                column?.name === selectedColumn?.name
                                    ? {
                                        ...column,
                                        new_column_name: newColumnName,
                                    }
                                    : column
                            ),
                        }
                        : component
                );
                setSelectedComponent({
                    ...selectedComponent,
                    columnProperties: selectedComponent?.columnProperties?.map((item) => {
                        if (item?.name == selectedColumn?.name) {
                            return {
                                ...item,
                                new_column_name: newColumnName,
                            }
                        }
                        return item
                    })
                })
                return updatedComponents;
            });
        }
    };
    function updateColumnNames(tableData, columnProperties) {
        let columnMap = {};

        // Create a mapping of old column names to new column names
        columnProperties.forEach(prop => {
            if (prop.name !== prop.new_column_name) {
                columnMap[prop.name] = prop.new_column_name;
            }
        });

        // Iterate through the table data and update column names
        return tableData.map(row => {
            let updatedRow = {};
            for (let key in row) {
                let newKey = columnMap[key] || key; // Replace if mapping exists, otherwise keep the same
                updatedRow[newKey] = row[key];
            }
            return updatedRow;
        });
    }
    const handleSaveTable = async (obj) => {
        if (isModalOpen) {
            return;
        }
        if (selectedComponent) {
            const isTableNameEmpty = !selectedComponent.new_table_name?.trim();
            const isColumnNameEmpty = selectedComponent.columnProperties?.some((column) => !column.new_column_name?.trim());

            // Check for empty table or column names
            if (isTableNameEmpty) {
                // Error: Table name cannot be empty. Reverting to default table name.
                showAlert("Table name cannot be empty. Please assign a name.", "error");
                setSelectedComponent((prevSelectedComponent) => ({
                    ...prevSelectedComponent,
                    new_table_name: prevSelectedComponent.table_name, // Reset to default table name
                }));
                return; // Exit early if validation fails
            } else if (isColumnNameEmpty) {
                // Error: Column name cannot be empty. Please assign a name.
                showAlert("Column name cannot be empty. Reverting to default column names.", "error");
                setSelectedComponent((prevSelectedComponent) => ({
                    ...prevSelectedComponent,
                    columnProperties: prevSelectedComponent.columnProperties.map((column) => ({
                        ...column,
                        new_column_name: column.name, // Reset column name to default
                    })),
                }));
                return; // Exit early if validation fails
            }

            // Check for column name duplicates
            const columnNameDuplicates = selectedComponent.columnProperties.some((column) => {
                return selectedComponent.columnProperties.some(
                    (otherColumn) =>
                        otherColumn.name !== column.name && otherColumn.new_column_name === column.new_column_name
                );
            });

            // Check for table name duplicates across all components
            const isDuplicateTableName = components?.some(
                (component) =>
                    component?.new_table_name?.toLowerCase() === selectedComponent?.new_table_name?.toLowerCase() &&
                    component?.id !== selectedComponent?.id
            );

            // Handle column name and table name duplicates
            if (columnNameDuplicates) {
                // Error: Column Name is already in use. Please choose a different column name.
                showAlert("Column names cannot be duplicate. Reverting to default column names.", "error");
                setSelectedComponent((prevSelectedComponent) => ({
                    ...prevSelectedComponent,
                    columnProperties: prevSelectedComponent.columnProperties.map((column) => ({
                        ...column,
                        new_column_name: column.name, // Revert to default column name
                    })),
                }));
                return; // Exit early if validation fails
            } else if (isDuplicateTableName) {
                // Error: Table Name is already in use. Please choose a different table name.
                showAlert("Table name cannot be duplicate. Reverting to default table name.", "error");
                setSelectedComponent((prevSelectedComponent) => ({
                    ...prevSelectedComponent,
                    new_table_name: prevSelectedComponent.table_name, // Revert table name to default
                }));
                return; // Exit early if validation fails
            }
            let { data, ...restProperties } = selectedComponent;
            data = obj?.dataS ? obj?.dataS : data
            restProperties.columnProperties = obj?.columnPropertiesS ? obj?.columnPropertiesS : selectedComponent?.columnProperties
            let columns = obj?.columnsS ? obj?.columnsS : selectedComponent?.columns
            let primaryPropertiesU = obj?.primaryPropertiesS ? obj?.primaryPropertiesS : primaryProperties;
            let columnsPayload = selectedComponent?.columnProperties?.map((column) => {
                if (column?.name !== column?.new_column_name || (column?.name === `${String(selectedComponent?.new_table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`) && selectedComponent?.table_name !== selectedComponent?.new_table_name) {
                    restProperties.columnProperties = restProperties?.columnProperties?.map((innerColumn) => {
                        if (innerColumn?.isComputed && innerColumn.expression) {
                            if (innerColumn.expression.includes(`\`${column.name}\``)) {
                                innerColumn.expression = innerColumn.expression.replace(
                                    new RegExp(`\`${column.name}\``, 'g'),
                                    `\`${column.new_column_name}\``
                                );
                            }
                            if (innerColumn.computed_expression.includes(`\`${column.name}\``)) {
                                innerColumn.computed_expression = innerColumn.computed_expression.replace(
                                    new RegExp(`\`${column.name}\``, 'g'),
                                    `\`${column.new_column_name}\``
                                );
                            }
                        }
                        return innerColumn;
                    });
                    const columnExists = primaryPropertiesU?.columnProperties.some(col =>
                        col.name === column.name || col.new_column_name === column.new_column_name
                    );
                    if (column?.isDataColumn && !columnExists) {
                        selectedComponent?.tableData.forEach(row => {
                            if (Object.prototype.hasOwnProperty.call(row, column.name)) {
                                row[column.new_column_name] = row[column.name];
                                delete row[column.name];
                            }
                        });
                        return {
                            colName: column.new_column_name,
                            newColName: column.new_column_name,
                            type: primaryPropertiesU?.columnProperties?.find((item) => item?.name === column?.name)?.type === column.type ? "" : column.type,
                            allowNull: column.allowNull || true,
                        };
                    } else {
                        return {
                            colName: column.name,
                            newColName: column.new_column_name,
                            type: primaryPropertiesU?.columnProperties?.find((item) => item?.name === column?.name)?.type === column.type ? "" : column.type,
                            allowNull: column.allowNull || true,
                        };
                    }
                } else {
                    return {
                        colName: column.name,
                        type: primaryPropertiesU?.columnProperties?.find((item) => item?.name === column?.name)?.type === column.type ? "" : column.type,
                        allowNull: column.name === `${String(selectedComponent?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id` ? false : true,
                    };
                }
            });

            //  let updatedData = updateColumnNames(selectedComponent?.tableData, restProperties?.columnProperties);
            const payload = {
                tableName: selectedComponent?.table_name,
                columns: columnsPayload,
                properties: restProperties,
                tableData: selectedComponent?.tableData
            };
            payload.columns = columnsPayload.map(column => {
                if (!columns.includes(column.colName)) {
                    payload.properties = {
                        ...payload?.properties,
                        columnProperties: payload.properties?.columnProperties?.map(item => {
                            if (item?.name === column.colName) {
                                return {
                                    ...item,
                                    isDeleted: true
                                }
                            }
                            return item
                        })
                    }
                    //  return { ...column, isDelete: true };
                    return column;
                } else {
                    return column;
                }
            });
            if (selectedComponent?.table_name !== selectedComponent?.new_table_name) {
                payload.tableData = payload.tableData?.map((row) => {
                    return Object.keys(row).reduce((acc, key) => {
                        // Replace 'patient_(1)_id' with 'patient_id'
                        const newKey = key === `${String(selectedComponent?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id` ? `${String(selectedComponent?.new_table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id` : key;
                        acc[newKey] = row[key];
                        return acc;
                    }, {})
                })
                payload.newTableName = selectedComponent?.new_table_name;
                //String(tableNameDb?.replace(/[- ]/g, "_"))?.toLowerCase()
                payload.columns = columnsPayload?.map(item => item.colName === `${String(selectedComponent?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id` ? { ...item, newColName: `${String(selectedComponent?.new_table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`, allowNull: false } : item);

            }
            payload.properties = {
                ...payload?.properties,
                columnProperties: payload.properties?.columnProperties?.map((item) => {
                    if (selectedComponent?.table_name !== selectedComponent?.new_table_name && item?.name === `${String(selectedComponent?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`) {
                        return {
                            ...item,
                            name: `${String(selectedComponent?.new_table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`,
                            new_column_name: `${String(selectedComponent?.new_table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`
                        }
                    }
                    if (item?.name !== item?.new_column_name) {
                        return {
                            ...item,
                            name: item?.new_column_name
                        }
                    }
                    return item
                })
            }
            try {
                await updateTableSkorboard(payload).unwrap();
                // getTableData(selectedComponent, selectedComponent.new_table_name);
                const tableData = await getTableDataSkorBoard({ name: selectedComponent?.table_name !== selectedComponent?.new_table_name ? selectedComponent?.new_table_name : selectedComponent?.table_name, skip: 0, limit: 100 }).unwrap();
                const Distvalues = await getDistValues({
                    tableId: tableData?.data?.metaData?.tableMetaData?.table_id,
                    filters: {
                        searchQuery: "",
                        dateFilter: {},
                        dateColumn: null,
                        columns: [],
                        columnFilters: {
                            column_name: null,
                            searchQuery: ""
                        }
                    }
                })?.unwrap();

                const updatedPrope = tableData?.data?.tableProperties?.columnProperties.map((item) => ({
                    ...item,
                    values: Distvalues?.data == null ? [] : Distvalues?.data[item?.new_column_name]?.values,
                    totalCount: Distvalues?.data == null ? 0 : Distvalues?.data[item?.new_column_name]?.total_count
                }))
                let payLoad = {
                    ...selectedComponent,
                    table_name: selectedComponent?.table_name !== selectedComponent?.new_table_name ? selectedComponent?.new_table_name : selectedComponent?.table_name,
                    // columns: selectedComponent?.columns,
                    columns: tableData?.data?.columns?.map((item) => item?.name),
                    columnProperties: updatedPrope,
                    data: tableData?.data?.tableData,
                    tableData: [],
                }
                // }
                setPrimaryProperties(payLoad)
                setSelectedComponent(payLoad)
                handlePropertyChange(payLoad, null);
                showAlert("Table updated successfully!", "success");
                selectedColumn && !obj?.dataS && await handleSelectColumn(selectedColumn?.name, selectedColumn?.new_column_name);
                setCount(tableData?.data?.totalCount);
                superset?.setDatasetProperties(tableData?.data?.datasetProperties)
            } catch (err) {
                console.log(err);
                // showAlert(err?.message, 'error');
                showAlert(err?.data?.message, 'error')
            }

            // setComponents((prevComponents) =>
            //     prevComponents.map((component) =>
            //         component.id === selectedComponent.id
            //             ? {
            //                 ...component, table_name: component.new_table_name,
            //                 // columnProperties: component?.columnProperties.map((item) =>
            //                 //     ({ ...item, name: item?.new_column_name }))
            //             }
            //             : component
            //     )
            // );

            // setSelectedComponent((prevSelected) => ({
            //     ...prevSelected,
            //     table_name: selectedComponent.new_table_name,
            //     // columnProperties: prevSelected?.columnProperties.map((item) =>
            //     //     ({ ...item, name: item?.new_column_name }))
            // }));
        }
    };
    const generateColumnName = (existingColumns, baseName = 'column') => {
        let newName = baseName;
        let counter = 1;

        while (existingColumns.includes(newName)) {
            newName = `${baseName}${counter}`;
            counter++;
        }

        return newName;
    };

    const handleAddDataColumn = () => {
        // Helper function to generate a unique column name
        const newColumnName = generateColumnName(selectedComponent.columns); // Generate a unique column name
        const newCols = [...selectedComponent.columns, newColumnName];
        const newColumnProperties = selectedComponent.columnProperties.concat([
            {
                name: newColumnName,
                type: 'varchar',
                isDataColumn: true,
                new_column_name: newColumnName
            },
        ]);

        setSelectedComponent({
            ...selectedComponent,
            columns: newCols,
            columnProperties: newColumnProperties,
        });

        handlePropertyChange({ columns: newCols, columnProperties: newColumnProperties }, null);
        showAlert("New data column added at the end of the table", 'info');
    };

    const showAlert = (msg, severity) => {
        setOpen(true);
        setMessage(msg);
        setSeverity(severity);
    };

    const checkColumnsInExpression = (expression, columns) => {
        // Define the regex to find column names enclosed in backticks

        const columnPattern = /`([^`]+)`/g;
        const foundColumns = expression.match(columnPattern); // Extract columns
        const columntouse = columns ? columns : selectedComponent?.columns
        if (!foundColumns) {
            return []; // No columns found
        }

        const missingColumns = [];

        // Iterate through all extracted columns
        for (let columnWithBackticks of foundColumns) {
            // Remove backticks to get the column name
            const columnName = columnWithBackticks.replace(/`/g, "");

            // Check if the column exists in the selectedComponent.columns
            const columnExists = columntouse?.some((col) =>
                col.replace(/`/g, "") === columnName
            );

            // If the column is missing and not already added to missingColumns, add it
            if (!columnExists && !missingColumns.includes(columnWithBackticks)) {
                missingColumns.push(columnWithBackticks);
            }
        }
        return missingColumns;

    };
    const handleSelectColumn = (column, name) => {
        let seCol = selectedComponent?.columnProperties?.find(item => item?.name === column)
        seCol = name ? { ...seCol, name: seCol?.new_column_name } : seCol
        setSelectedColumn(seCol)

        if (seCol?.isComputed) {
            setQuery(seCol?.expression);
            setComputedColumn({
                status: true,
                name: name ? seCol?.new_column_name : column,
                isNew: false,
                oldName: name ? seCol?.new_column_name : column
            });

            // First, check if the expression contains missing columns
            const error = checkColumnsInExpression(seCol?.expression);
            if (error?.length > 0) {
                setErrorComputed({
                    status: true,
                    message: `The following columns are missing in the table: ${error}`
                });
                return;
            }

            const missingInComputedExpression = checkColumnsInExpression(seCol?.computed_expression);
            if (missingInComputedExpression?.length > 0) {
                setErrorComputed({
                    status: true,
                    message: `This Expression contain Computed columns that use missing columns: ${missingInComputedExpression}`
                });
            } else {
                setErrorComputed({ status: false, message: "" });
            }
        } else {
            setQuery("");
            setComputedColumn({
                ...computedColumn,
                status: false,
                name: 'column',
                isNew: true
            });
        }
    }
    const replaceColumnReferences = (expression, columnMap, oldName, newName, newExpression) => {
        return expression.replace(/`([^`]+)`/g, (match, colName, offset, fullExpression) => {
            const colExpression = columnMap[colName];
            if (!colExpression) return match; // No replacement needed if not in columnMap
            let parenthesesStack = 0;
            let isInsideAggregation = false;

            // Analyze the context of the expression
            for (let i = 0; i < offset; i++) {
                if (fullExpression[i] === '(') {
                    parenthesesStack++;
                } else if (fullExpression[i] === ')') {
                    parenthesesStack--;
                }
                const subExpression = fullExpression.slice(Math.max(0, i - 4), i + 1);
                if (/\b(SUM|AVG|MAX|MIN|COUNT)\s*\($/.test(subExpression)) {
                    isInsideAggregation = true;
                }
            }
            if (isInsideAggregation && parenthesesStack > 0) {
                // Inside aggregation, replace only the name
                return colName === oldName ? `\`${newName}\`` : match;
            } else {
                // Replace with the full expression for non-aggregation contexts
                return colName === oldName ? `(${newExpression})` : match;
            }
        });
    };

    const handleCreateComputedColumnSave = async () => {
        try {
            const isDuplicate = selectedComponent.columnProperties.some(
                (column) => column.name === computedColumn?.name?.trim() && !column?.isComputed
            );
            if (isDuplicate) {
                showAlert("Column name already exists. Please choose a different name.");
                return;
            }
            let expression = `${query?.trim()}`;
            const computed_Columns = primaryProperties?.columnProperties.reduce((acc, col) => {
                if (col.isComputed) {
                    acc[col.new_column_name] = col.computed_expression;
                }
                return acc;
            }, {});
            const computed_columns_ex = primaryProperties?.columnProperties.reduce((acc, col) => {
                if (col.isComputed) {
                    acc[col.new_column_name] = col?.expression;
                }
                return acc;
            }, {});
            let cleanedExpression = expression.replace(/\s*([+\-*/(),])\s*/g, '$1');

            cleanedExpression = cleanedExpression.replace(/`([^`]+)`(?=[+\-*/(),]|$)/g, '`$1` ');

            cleanedExpression = cleanedExpression.replace(/\s*([+\-*/(),])\s*/g, '$1');

            let newExpression = cleanedExpression;
            Object.keys(computed_Columns).forEach((colName) => {
                const colExpression = computed_Columns[colName];

                // Regex to find occurrences of the column
                const regex = new RegExp(`\`${colName}\``, 'g');

                newExpression = newExpression.replace(regex, (match, offset, fullExpression) => {
                    let parenthesesStack = 0; // Track open parentheses
                    let isInsideAggregation = false;

                    // Analyze the expression context up to the match
                    for (let i = 0; i < offset; i++) {
                        if (fullExpression[i] === '(') {
                            parenthesesStack++;
                        } else if (fullExpression[i] === ')') {
                            parenthesesStack--;
                        }

                        // Check if we're inside an aggregation function
                        const subExpression = fullExpression.slice(Math.max(0, i - 4), i + 1); // Look back a few chars
                        if (/\b(SUM|AVG|MAX|MIN|COUNT)\s*\($/.test(subExpression)) {
                            isInsideAggregation = true;
                        }
                    }

                    if (isInsideAggregation && parenthesesStack > 0) {
                        // If inside aggregation function, keep backticks around the column
                        return match;
                    } else {
                        return `(${colExpression})`; // Remove backticks from the expression
                    }
                });
            });

            selectedComponent?.columnProperties?.map((column) => {
                column?.expression
            })
            const restProperties = {
                ...primaryProperties,
                columnProperties: (() => {
                    const present = primaryProperties?.columnProperties?.find(
                        (item) => item?.name === computedColumn?.oldName
                    );
                    if (present) {
                        return primaryProperties?.columnProperties?.map((item) => {
                            if (item?.isComputed && item?.expression) {
                                // Check if the computed column's old name is referenced in this column's expression
                                if (item.expression.includes(`\`${computedColumn?.oldName}\``)) {
                                    return {
                                        ...item,
                                        // Update the expression to replace references to the old name with the new name
                                        expression: item.expression.replace(
                                            new RegExp(`\`${computedColumn?.oldName}\``, 'g'),
                                            `\`${computedColumn?.name}\``
                                        ),
                                        // Optionally update the computed_expression if needed

                                        computed_expression: replaceColumnReferences(
                                            item?.expression,
                                            computed_columns_ex,
                                            computedColumn?.oldName,
                                            computedColumn?.name,
                                            newExpression
                                        )
                                    };
                                }
                            }
                            if (item?.name === computedColumn?.oldName) {
                                return {
                                    name: computedColumn?.name,
                                    type: cleanedExpression.toUpperCase().includes('IF') ? 'varchar' : 'int',
                                    isComputed: true,
                                    new_column_name: computedColumn?.name,
                                    expression: cleanedExpression,
                                    computed_expression: newExpression
                                }
                                // Replace the matching column
                            }
                            return item; // Return the original item
                        });
                    } else {
                        return [...(primaryProperties?.columnProperties || []),
                        {
                            name: computedColumn?.name,
                            type: cleanedExpression.toUpperCase().includes('IF') ? 'varchar' : 'int',
                            isComputed: true,
                            new_column_name: computedColumn?.name,
                            expression: cleanedExpression,
                            computed_expression: newExpression
                        },
                        ];
                    }
                })(),
            };

            const result = await createComputedColumn({
                tableName: selectedComponent?.table_name,
                columnName: computedColumn?.isNew ? computedColumn?.name?.trim() : computedColumn?.oldName,
                calculationQuery: `${computedColumn?.name?.trim()}=${newExpression}`,
                properties: restProperties,
                skip: skip,
                limit: limit,
                ids: selectedComponent?.filters?.length > 0 ? selectedComponent?.data?.map((item) => item[`${String(selectedComponent?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`]) : []
            }).unwrap();
            const newCols = computedColumn?.isNew ? [...selectedComponent.columns, result?.data?.columnName] : selectedComponent?.columns?.map(item => {
                if (item === computedColumn?.oldName) {
                    return result?.data?.columnName
                }
                return item;
            });
            const newColumnProperties = computedColumn?.isNew ? selectedComponent.columnProperties.concat([
                {
                    name: result?.data?.columnName,
                    type: cleanedExpression.toUpperCase().includes('IF') ? 'varchar' : 'int',
                    isComputed: true,
                    new_column_name: result?.data?.columnName,
                    expression: cleanedExpression,
                    computed_expression: newExpression,
                    values: result?.data?.uniqueValues[result?.data?.columnName]
                },
            ]) : selectedComponent?.columnProperties?.map(item => {
                if (item?.isComputed && item?.expression) {
                    // Check if the computed column's old name is referenced in this column's expression
                    if (item.expression.includes(`\`${computedColumn?.oldName}\``)) {
                        return {
                            ...item,
                            // Update the expression to replace references to the old name with the new name
                            expression: item.expression.replace(
                                new RegExp(`\`${computedColumn?.oldName}\``, 'g'),
                                `\`${computedColumn?.name}\``
                            ),
                            // Optionally update the computed_expression if needed
                            computed_expression: replaceColumnReferences(
                                item.expression,
                                computed_columns_ex,
                                computedColumn?.oldName,
                                computedColumn?.name,
                                newExpression
                            ),
                            values: result?.data?.uniqueValues[item?.name]
                        };
                    }
                }
                // Update the current column's name, expression, and computed_expression if it matches the one being updated
                if (item?.name === computedColumn?.oldName) {
                    return {
                        ...item,
                        name: result?.data?.columnName,
                        new_column_name: result?.data?.columnName,
                        expression: cleanedExpression,
                        computed_expression: newExpression,
                        values: result?.data?.uniqueValues[item?.name]
                    };
                }
                return item;
            });
            // const updatedData = selectedComponent?.data?.map((item, index) => ({
            //     ...item,
            //     [result?.data?.columnName]: result?.data?.data[index][result?.data?.columnName] || null // Safeguard against mismatched lengths
            // }));

            let updatedData = selectedComponent?.data?.map((row) => ({ ...row }));
            const updateArrays = (arr1, arr2) => {
                return arr2.map((obj, index) => {
                    const updateObj = arr1[index];
                    if (updateObj) {
                        for (const [key, value] of Object.entries(updateObj)) {
                            obj[key] = value; // Update existing keys or add new keys
                        }
                    }
                    return obj; // Return the updated object
                });
            };
            updatedData = updateArrays(result?.data?.data, updatedData);

            selectedColumn ? setSelectedColumn({
                ...selectedColumn,
                type: cleanedExpression.toUpperCase().includes('IF') ? 'varchar' : 'int',
                name: result?.data?.columnName,
                new_column_name: result?.data?.columnName,
                expression: cleanedExpression,
            }) : "";
            setComputedColumn({ ...computedColumn, isNew: false, oldName: result?.data?.columnName });
            return { primaryPropertiesS: restProperties, columnsS: newCols, columnPropertiesS: newColumnProperties, dataS: updatedData }
        } catch (error) {
            showAlert(error?.data?.message, 'error');
            console.log(error, "Error");
            return error
        }
    }
    const handleCreateComputedColumn = async () => {
        try {
            const isDuplicate = selectedComponent.columnProperties.some(
                (column) => column.name === computedColumn?.name?.trim() && !column?.isComputed
            );
            if (isDuplicate) {
                showAlert("Column name already exists. Please choose a different name.");
                return;
            }
            let expression = `${query?.trim()}`;
            const computed_Columns = primaryProperties?.columnProperties.reduce((acc, col) => {
                if (col.isComputed) {
                    acc[col.new_column_name] = col.computed_expression;
                }
                return acc;
            }, {});
            const computed_columns_ex = primaryProperties?.columnProperties.reduce((acc, col) => {
                if (col.isComputed) {
                    acc[col.new_column_name] = col?.expression;
                }
                return acc;
            }, {});
            let cleanedExpression = expression.replace(/\s*([+\-*/(),])\s*/g, '$1');

            cleanedExpression = cleanedExpression.replace(/`([^`]+)`(?=[+\-*/(),]|$)/g, '`$1` ');

            cleanedExpression = cleanedExpression.replace(/\s*([+\-*/(),])\s*/g, '$1');

            const computedColumns = selectedComponent?.columnProperties?.filter(item => item?.isComputed);

            let newExpression = cleanedExpression;
            Object.keys(computed_Columns).forEach((colName) => {
                const colExpression = computed_Columns[colName];

                // Regex to find occurrences of the column
                const regex = new RegExp(`\`${colName}\``, 'g');

                newExpression = newExpression.replace(regex, (match, offset, fullExpression) => {
                    let parenthesesStack = 0; // Track open parentheses
                    let isInsideAggregation = false;

                    // Analyze the expression context up to the match
                    for (let i = 0; i < offset; i++) {
                        if (fullExpression[i] === '(') {
                            parenthesesStack++;
                        } else if (fullExpression[i] === ')') {
                            parenthesesStack--;
                        }

                        // Check if we're inside an aggregation function
                        const subExpression = fullExpression.slice(Math.max(0, i - 4), i + 1); // Look back a few chars
                        if (/\b(SUM|AVG|MAX|MIN|COUNT)\s*\($/.test(subExpression)) {
                            isInsideAggregation = true;
                        }
                    }

                    if (isInsideAggregation && parenthesesStack > 0) {
                        // If inside aggregation function, keep backticks around the column
                        return match;
                    } else {
                        // Remove backticks from the column name and replace with the computed expression
                        const cleanMatch = match.replace(/`/g, '');
                        // Ensure the column expression is also without backticks
                        return `(${colExpression})`; // Remove backticks from the expression
                    }
                });
            });

            selectedComponent?.columnProperties?.map((column) => {
                column?.expression
            })
            const restProperties = {
                ...primaryProperties,
                columnProperties: (() => {
                    const present = primaryProperties?.columnProperties?.find(
                        (item) => item?.name === computedColumn?.oldName
                    );
                    if (present) {
                        return primaryProperties?.columnProperties?.map((item) => {
                            if (item?.isComputed && item?.expression) {
                                // Check if the computed column's old name is referenced in this column's expression
                                if (item.expression.includes(`\`${computedColumn?.oldName}\``)) {
                                    return {
                                        ...item,
                                        // Update the expression to replace references to the old name with the new name
                                        expression: item.expression.replace(
                                            new RegExp(`\`${computedColumn?.oldName}\``, 'g'),
                                            `\`${computedColumn?.name}\``
                                        ),
                                        // Optionally update the computed_expression if needed

                                        computed_expression: replaceColumnReferences(
                                            item?.expression,
                                            computed_columns_ex,
                                            computedColumn?.oldName,
                                            computedColumn?.name,
                                            newExpression
                                        )
                                    };
                                }
                            }
                            if (item?.name === computedColumn?.oldName) {
                                return {
                                    name: computedColumn?.name,
                                    type: cleanedExpression.toUpperCase().includes('IF') ? 'varchar' : 'int',
                                    isComputed: true,
                                    new_column_name: computedColumn?.name,
                                    expression: cleanedExpression,
                                    computed_expression: newExpression
                                }
                                // Replace the matching column
                            }
                            return item; // Return the original item
                        });
                    } else {
                        return [...(primaryProperties?.columnProperties || []),
                        {
                            name: computedColumn?.name,
                            type: cleanedExpression.toUpperCase().includes('IF') ? 'varchar' : 'int',
                            isComputed: true,
                            new_column_name: computedColumn?.name,
                            expression: cleanedExpression,
                            computed_expression: newExpression
                        },
                        ];
                    }
                })(),
            };
            const result = await createComputedColumn({
                tableName: selectedComponent?.table_name,
                columnName: computedColumn?.isNew ? computedColumn?.name?.trim() : computedColumn?.oldName,
                calculationQuery: `${computedColumn?.name?.trim()}=${newExpression}`,
                properties: restProperties,
                skip: skip,
                limit: limit,
                ids: selectedComponent?.filters?.length > 0 ? selectedComponent?.data?.map((item) => item[`${String(selectedComponent?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`]) : []
            }).unwrap();
            const newCols = computedColumn?.isNew ? [...selectedComponent.columns, result?.data?.columnName] : selectedComponent?.columns?.map(item => {
                if (item === computedColumn?.oldName) {
                    return result?.data?.columnName
                }
                return item;
            });
            const newColumnProperties = computedColumn?.isNew ? selectedComponent.columnProperties.concat([
                {
                    name: result?.data?.columnName,
                    type: cleanedExpression.toUpperCase().includes('IF') ? 'varchar' : 'int',
                    isComputed: true,
                    new_column_name: result?.data?.columnName,
                    expression: cleanedExpression,
                    computed_expression: newExpression,
                    values: result?.data?.uniqueValues[result?.data?.columnName]
                },
            ]) : selectedComponent?.columnProperties?.map(item => {
                if (item?.isComputed && item?.expression) {
                    // Check if the computed column's old name is referenced in this column's expression
                    if (item.expression.includes(`\`${computedColumn?.oldName}\``)) {
                        return {
                            ...item,
                            // Update the expression to replace references to the old name with the new name
                            expression: item.expression.replace(
                                new RegExp(`\`${computedColumn?.oldName}\``, 'g'),
                                `\`${computedColumn?.name}\``
                            ),
                            // Optionally update the computed_expression if needed
                            computed_expression: replaceColumnReferences(
                                item.expression,
                                computed_columns_ex,
                                computedColumn?.oldName,
                                computedColumn?.name,
                                newExpression
                            ),
                            values: result?.data?.uniqueValues[item?.name]
                        };
                    }
                }
                // Update the current column's name, expression, and computed_expression if it matches the one being updated
                if (item?.name === computedColumn?.oldName) {
                    return {
                        ...item,
                        name: result?.data?.columnName,
                        new_column_name: result?.data?.columnName,
                        expression: cleanedExpression,
                        computed_expression: newExpression,
                        values: result?.data?.uniqueValues[item?.name]
                    };
                }

                return item;
            });
            // const updatedData = selectedComponent?.data?.map((item, index) => ({
            //     ...item,
            //     [result?.data?.columnName]: result?.data?.data[index][result?.data?.columnName] || null // Safeguard against mismatched lengths
            // }));

            let updatedData = selectedComponent?.data?.map((row) => ({ ...row }));
            const updateArrays = (arr1, arr2) => {
                return arr2.map((obj, index) => {
                    const updateObj = arr1[index];
                    if (updateObj) {
                        for (const [key, value] of Object.entries(updateObj)) {
                            obj[key] = value; // Update existing keys or add new keys
                        }
                    }
                    return obj; // Return the updated object
                });
            };
            updatedData = updateArrays(result?.data?.data, updatedData);
            setPrimaryProperties(restProperties);
            setSelectedComponent({
                ...selectedComponent,
                columns: newCols,
                columnProperties: newColumnProperties,
                data: updatedData,
            })
            handlePropertyChange({
                columns: newCols,
                columnProperties: newColumnProperties,
                data: updatedData
            }, null)
            selectedColumn ? setSelectedColumn({
                ...selectedColumn,
                name: result?.data?.columnName,
                type: cleanedExpression.toUpperCase().includes('IF') ? 'varchar' : 'int',
                new_column_name: result?.data?.columnName,
                expression: cleanedExpression,
            }) : "";
            computedColumn?.isNew && setSelectedColumn({
                name: result?.data?.columnName,
                type: cleanedExpression.toUpperCase().includes('IF') ? 'varchar' : 'int',
                isComputed: true,
                new_column_name: result?.data?.columnName,
                expression: cleanedExpression,
            })
            showAlert(`${computedColumn?.isNew ? `${result?.data?.columnName} column added at the end of the table` : `${result?.data?.columnName} column updated`}`, 'info');
            setComputedColumn({ ...computedColumn, isNew: false, oldName: result?.data?.columnName });
        } catch (error) {
            showAlert(error?.data?.message, 'error');
            console.log(error, " Error");
        }
    }
    const handleImportFile = async (file) => {
        setIsExcelUpload(true)
        let parsedFiles = {}; // Array to hold parsed data for each file
        // for (const file of files) {
        const fileContent = await readFileContent(file);
        try {
            if (file.type === "text/csv") {
                //     // Parse CSV file
                //     const jsonData = parseCSV(fileContent);
                //     const sheetName = file.name.replace(/\.[^/.]+$/, "");
                //     const { cleanedData, columns } = cleanAndDetermineDataTypes({ data: jsonData, isXLS: false });
                const parsedData = Papa.parse(fileContent, { header: true });
                const headers = Object.keys(parsedData.data[0]); // Assuming first row is headers
                const values = parsedData.data.map((row) => Object.values(row));
                let jsonData = [headers, ...values];
                const headers2 = jsonData[0].filter(
                    (header) =>
                        header !== "" && header !== undefined && header !== null
                );
                const adjustedData = await jsonData.map((row, rowIndex) => {
                    if (rowIndex === 0) return headers2;
                    return row.filter(
                        (_, colIndex) =>
                            jsonData[0][colIndex] !== "" &&
                            jsonData[0][colIndex] !== undefined &&
                            jsonData[0][colIndex] !== null
                    );
                });
                jsonData = adjustedData;
                const sheetName = file.name.replace(/\.[^/.]+$/, "");
                const jsonLikeData = await transformToJSON(jsonData);
                const { cleanedData, columns, dateFormat } = cleanAndDetermineDataTypes({ data: jsonLikeData, isXLS: false });
                // const filteredData = await filterDataByColumns(cleanedData, columns)

                parsedFiles = { tableName: sheetName, data: cleanedData, columns }

                return { tableName: sheetName, jsonData: cleanedData, columns: columns, dateFormat }

            } else if (
                file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type === "application/vnd.ms-excel"
            ) {
                // Parse Excel file
                // const workbook = XLSX.read(fileContent, { type: "array" });
                const workbook = XLSX.read(fileContent, {
                    type: "array",
                    cellDates: false // This ensures dates are read as actual Date objects instead of numbers
                });
                // const fileData = {
                //     fileName: file.name,
                //     sheets: [],
                // };
                // Parse each sheet in the workbook
                const tableName = file.name.replace(/\.[^/.]+$/, "");
                let jsonData = [];
                workbook.SheetNames.forEach((sheetName) => {
                    const worksheet = workbook.Sheets[sheetName];
                    jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, cellDates: false, cellText: true });

                    // parsedFiles = { tableName: tableName, data: cleanedData, columns };
                });
                const { cleanedData, columns, dateFormat } = cleanAndDetermineDataTypes({ data: jsonData, isXLS: true });
                return { tableName: tableName, jsonData: cleanedData, columns: columns, dateFormat }

            } else {
                console.error("Unsupported file type:", file.type);
            }
        } catch (error) {
            showAlert(`Error parsing file: ${file.name}`, "error")
            console.error("Error parsing file:", file.name, error);
        }
        //   }

        // try {
        //     console.log("Parsed Files and Sheets:", parsedFiles);
        //     await uplodExcel(parsedFiles).unwrap();
        //     showAlert("Table created successfully!", "success");
        //     setComputedColumn({ status: false, name: "column", isNew: true, oldName: "" });
        //     setQuery("")
        //     await handleOnImportExcel(parsedFiles?.tableName);
        // } catch (err) {
        //     console.log(err?.data?.message, ">>> ERROR");
        //     showAlert(err?.data?.message, "error");
        // }

        // return parsedFiles; // Return the structured data for further processing
    };
    const handleSaveHit = async () => {
        let expression = `${query?.trim()}`;
        let tmp;
        try {
            let cleanedExpression = expression.replace(/\s*([+\-*/(),])\s*/g, '$1');
            cleanedExpression = cleanedExpression.replace(/`([^`]+)`(?=[+\-*/(),]|$)/g, '`$1` ');
            cleanedExpression = cleanedExpression.replace(/\s*([+\-*/(),])\s*/g, '$1');

            if (selectedColumn?.isComputed && (cleanedExpression !== selectedColumn?.expression || computedColumn?.name !== selectedColumn?.name)) {
                try {
                    let result = await handleCreateComputedColumnSave();
                    result.columnName = computedColumn?.name
                    tmp = result;
                    await handleSaveTable(result);// Ensure it finishes before proceeding
                } catch (err) {
                    showAlert(tmp?.data?.message, "error")
                }
            } else {
                await handleSaveTable({});
            }
        } catch (err) {
            showAlert(tmp?.data?.message, "error")
        }
    };

    // const handleUploadExcelFile = async (file) => {
    //     try {
    //         setIsLoading(true);

    //         // Step 1: Processing file (3s)
    //         setLoadingStep("Processing file...");
    //         await new Promise(resolve => setTimeout(resolve, 3000));

    //         // Step 2: Creating datasource (3s)
    //         setLoadingStep("Creating datasource...");
    //         await new Promise(resolve => setTimeout(resolve, 3000));

    //         // Step 3: Initializing column properties (3s)
    //         setLoadingStep("Initializing column properties...");
    //         await new Promise(resolve => setTimeout(resolve, 3000));

    //         setLoadingStep("Uploading Data...");
    //         await new Promise(resolve => setTimeout(resolve, 3000));
    //         // Handle file import
    //         //  const result = await handleImportFile(file);
    //         let parsedFiles = {};
    //         console.log(file, ">>>> File ttt tpo upload");
    //         // const columnTypes = getColumnTypes(
    //         //     result?.columns?.map(item => ({ name: item?.colName, type: item?.type }))
    //         // );

    //         // const defalult = result?.columns.map((item, index) => ({
    //         //     name: item?.colName,
    //         //     type: columnTypes[item?.colName] || "varchar",
    //         //     format: result?.dateFormat?.[item?.colName] || null,
    //         //     currency: "",
    //         //     isPercentage: false,
    //         //     decimal: 0,
    //         //     isComma: false,
    //         //     new_column_name: item?.colName,
    //         //     sequence_number: index + 1,
    //         //     allowNull: item?.colName?.trim() === "id" || item?.colName?.trim()?.endsWith("_id") ? false : true,
    //         //     default_date_format: result?.dateFormat?.[item?.colName] || null
    //         // }));

    //         // parsedFiles = {
    //         //     tableName: result?.tableName,
    //         //     data: result?.jsonData,
    //         //     columns: result?.columns,
    //         //     properties: {
    //         //         columnProperties: [
    //         //             ...defalult,
    //         //             {
    //         //                 name: `${String(result?.tableName?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`,
    //         //                 type: 'int',
    //         //                 new_column_name: `${String(result?.tableName?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`,
    //         //             }
    //         //         ]
    //         //     }
    //         // };

    //         //   console.log("Parsed Files and Sheets:", parsedFiles);

    //         // Step 4: Uploading file (wait until upload completes)

    //         // await uplodExcel(parsedFiles).unwrap(); // Wait until upload is finished
    //         const response = await createTableNew({ file })?.unwrap();


    //         console.log(response, ">>>> Result response .....");

    //         // Step 5: File uploaded successfully (3s)
    //         setLoadingStep("File uploaded successfully!");
    //         await new Promise(resolve => setTimeout(resolve, 3000));

    //         // Reset states
    //         setIsLoading(false);
    //         setLoadingStep("");

    //         showAlert("Table created successfully!", "success");
    //         setComputedColumn({ status: false, name: "column", isNew: true, oldName: "" });
    //         setQuery("");
    //         await handleOnImportExcel(response?.data?.table_name);


    //     } catch (err) {
    //         setIsLoading(false);
    //         setLoadingStep("");
    //         console.log(err, "ERROR");
    //         showAlert(err?.data?.message, "error");
    //     }
    // };


    const handleUploadExcelFile = async (file) => {
        try {
            setIsLoading(true);

            // Function to update loading message after a delay (if API is still pending)
            const showStep = async (message, duration) => {
                if (!apiCompleted) {
                    setLoadingStep(message);
                    await new Promise(resolve => setTimeout(resolve, duration));
                }
            };

            let apiCompleted = false;

            // Start API call in parallel
            const apiCall = createTableNew({ file })?.unwrap().then((response) => {
                apiCompleted = true;
                return response;
            });

            // Show loading steps while waiting for API response
            await Promise.race([
                (async () => {
                    await showStep("Processing file...", 2000);
                    await showStep("Creating datasource...", 2000);
                    await showStep("Initializing column properties...", 2000);
                    await showStep("Uploading Data...", 2000);
                })(),
                apiCall, // If API finishes first, it will skip steps and go to success
            ]);

            // Wait for API response (if not already resolved)
            const response = await apiCall;

            console.log(response, ">>>> Result response .....");

            // Show success message for 2 seconds
            setLoadingStep("File uploaded successfully!");
            setTimeout(() => {
                setLoadingStep(""); // Clear message
                setIsLoading(false);
            }, 2000);

            showAlert("Table created successfully!", "success");

            setComputedColumn({ status: false, name: "column", isNew: true, oldName: "" });
            setQuery("");

            await handleOnImportExcel(response?.data?.table_name);

        } catch (err) {
            setIsLoading(false);
            setLoadingStep("");
            console.log(err, "ERROR");
            showAlert(err?.data?.message, "error");
        }
    };
    const handleAppendData = async (file) => {
        try {
            setIsLoading(true);
            setLoadingStep("Working on it...");
            let parsedFiles = {};
            // const result = await handleImportFile(file);
            // const { cleanedData, columns } = cleanAndDetermineDataTypes({ data: result?.jsonData, isXLS: true });
            parsedFiles = {
                tableName: selectedComponent?.table_name,
                file: file,
            };
            const response = await validateTableData(parsedFiles)?.unwrap();
            setIsLoading(false);
            setValidateModalOpen({
                ...validateModalOpen,
                status: response?.data?.duplicates?.length > 0 || response?.data?.typeErrors?.length > 0,
                data: response?.data?.duplicates,
                // dataToAppend: result?.jsonData,
                typeErrors: response?.data?.typeErrors
            });
            //  if (response?.data?.typeErrors?.length <= 0) {
            const tableData = await getTableDataSkorBoard({ name: selectedComponent?.table_name, skip: 0, limit: 100 }).unwrap();
            setSelectedComponent({
                ...selectedComponent,
                data: tableData?.data?.tableData,
                columnProperties: selectedComponent?.columnProperties?.map((item, index) => ({
                    ...item,
                    values: tableData?.data?.tableProperties?.columnProperties?.[index]?.values || item?.values,
                }))
            });
            handlePropertyChange({
                data: tableData?.data?.tableData,
                columnProperties: selectedComponent?.columnProperties?.map((item, index) => ({
                    ...item,
                    values: tableData?.data?.tableProperties?.columnProperties?.[index]?.values || item?.values,
                }))

            }, null)
            setCount(tableData?.data?.totalCount);
            superset?.setDatasetProperties(tableData?.data?.datasetProperties)
            showAlert(`${response?.data?.total_records} records have been appended.`, "success")
            // }
        } catch (err) {
            setIsLoading(false);
            setValidateModalOpen({ status: false, data: [], dataToAppend: [], typeErrors: [] })
            showAlert(err?.data?.message, 'error');
            console.log(err, "Error")
        }
    }

    const handleProceedAppendData = async () => {
        try {
            await appendData({ tableName: selectedComponent?.table_name, tableData: validateModalOpen?.dataToAppend })
            const tableData = await getTableDataSkorBoard({ name: selectedComponent?.table_name, skip: skip, limit: limit }).unwrap();
            setSelectedComponent({ ...selectedComponent, data: tableData?.data?.tableData });
            handlePropertyChange({ data: tableData?.data?.tableData }, null)
            setCount(tableData?.data?.totalCount);
            superset?.setDatasetProperties(tableData?.data?.datasetProperties)
            showAlert("The data has been appended successfully.", "success")
            setValidateModalOpen({ status: false, data: [], dataToAppend: [], typeErrors: [] })
        } catch (err) {
            console.log(err, "ERROR")
            showAlert(err?.data?.message, "error")
            setValidateModalOpen({ status: false, data: [], dataToAppend: [], typeErrors: [] })
        }
    }
    const handleAddColumnValues = async (searchString, isSearch) => {
        const Distvalues = await getDistValues({
            tableId: selectedComponent?.tableId,
            filters: {
                columnFilters: {
                    column_name: selectedColumn?.name,
                    searchQuery: searchString
                }
            },
            skip: 0,
            limit: isSearch ? 20 : selectedColumn?.values?.length + 20,
        })?.unwrap();
        const updatedColumns = selectedComponent?.columnProperties?.map(column => {
            if (column?.name === selectedColumn?.name) {
                return {
                    ...column,
                    values: Distvalues?.data == null ? [] : Distvalues?.data[column?.name]?.values,
                    totalCount: Distvalues?.data == null ? 0 : Distvalues?.data[column?.name]?.total_count
                }
            }
            return column
        });
        setSelectedComponent((prev) => ({
            ...prev,
            columnProperties: updatedColumns
        }));
        handlePropertyChange({ columnProperties: updatedColumns })
        setSelectedColumn((prev) => {
            return {
                ...prev,
                values: Distvalues?.data == null ? [] : Distvalues?.data[prev?.name]?.values,
                totalCount: Distvalues?.data == null ? 0 : Distvalues?.data[prev?.name]?.total_count
            }
        })

    }
    const handleAddNewDatabaseConnection = async () => {
        console.log("add new db connect");
        superset?.setIsCreateCon(true);
    }
    const handleSelect = (type) => {
        superset?.setSelectedDB(type);
    };

    const handleChange = (e) => {
        superset?.setCredentials({ ...superset?.credentials, [e.target.name]: e.target.value });
    };

    // const fetchSupersetTokens = async () => {
    //     try {
    //         // 1️⃣ Get API Token

    //         const loginResponse = await fetch("http://localhost:8088/api/v1/security/login", {
    //             method: "POST",
    //             headers: { "Content-Type": "application/json" },
    //             body: JSON.stringify({
    //                 username: "admin",
    //                 password: "admin",
    //                 provider: "db",
    //                 //refresh: true,
    //             })
    //         });

    //         const loginData = await loginResponse.json();
    //         const token = loginData.access_token; // Extract the access token
    //         setSupersetLoginToken(token)

    //         // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6dHJ1ZSwiaWF0IjoxNzQxMDc4NDk0LCJqdGkiOiI3NTQyNzUzMC1iOWM5LTQ0OGEtYTVkYS00ZTY2YzEwMjc4ZjMiLCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoxLCJuYmYiOjE3NDEwNzg0OTQsImNzcmYiOiJlZTYzOTU3OS02NzI0LTQyNTUtODdiNi0wNjdmODFkZDAwYmQiLCJleHAiOjE3NDEwNzkzOTR9.NsCnaM27XKtxmhweujaweJjwndmQUG1fetjWeG-f-xU';
    //         if (!token) {
    //             showAlert("Superset authentication failed!")
    //             console.error("Authentication failed!");
    //             return;
    //         }

    //         // 2️⃣ Get CSRF Token
    //         const csrfResponse = await fetch("http://localhost:8088/api/v1/security/csrf_token/", {
    //             headers: { "Authorization": `Bearer ${token}` },
    //             credentials: "include"
    //         });
    //         setSupersetCsrfToken(csrfResponse.json())
    //     } catch (error) {
    //         console.error("Error fetching dashboards:", error);
    //     }
    // };

    const fetchSchemas = async (database) => {
        try {
            // const schemas = await fetch(`http://localhost:8088/api/v1/database/${database?.id}/schemas/?q=(force:!f)`, {
            //     method: "GET",
            //     headers: {
            //         "Authorization": `Bearer ${supersetLoginToken}`,
            //         "Content-Type": "application/json; charset=utf-8",
            //         "X-CSRFToken": supersetCsrfToken
            //     },
            //     credentials: "include"
            // });

            // return await schemas?.json();
            const schemas = await fetachChemas({ database_id: database?.id })?.unwrap();
            console.log(schemas, ">>>> Schemasss");
            return schemas?.data;
        } catch (err) {
            console.log(err, ">>> Errorbhhh");
            showAlert(err?.message, 'error');
            return;
        }
    }
    const fetachTables = async (schema, db) => {
        try {
            const tables = await fetchTable({
                database_id: db?.id,
                schema_name: schema
            })?.unwrap();

            console.log(tables, ">>> Tables");

            return tables?.data

        } catch (err) {
            console.log(err, ">>> Error");
            showAlert(err?.message, 'error');
            return;
        }

    }
    const fetachTableMetadata = async (schema, db, table) => {
        try {
            console.log(db?.backend, ">>>> JJJJJJJ")
            superset?.setDatasettableloading(true)
            const response = await fetachTableMetaData({
                database_id: db?.id,
                schema_name: schema,
                table_name: table?.value,
                db_type: db?.backend
            })?.unwrap();

            console.log(response, ">>> Tables");
            // setTableMetadata(metaData?.data?.columns)
            superset?.setTableMetadata({ metaData: response?.data?.metaData, tableData: response?.data?.tableData })
            superset?.setDatasettableloading(false);
            superset?.setSqlQuery(`${response?.data?.tableData?.query?.sql}`);
            response?.data?.tableData?.data?.length <= 0 && showAlert(`${table?.value} is empty`, 'info');
        } catch (err) {
            console.log(err, "Error");
            showAlert(err?.message, 'error');
            superset?.setDatasettableloading(false);
            return;
        }

    }
    const getQueriedTableData = async (query) => {
        try {
            superset?.setDatasettableloading(true);
            setErrorMessage(null);
            const response = await getTableDataUsingQuery({
                database_id: superset?.selectedDatabase?.id,
                sql: query,
                schema_name: superset?.selectedSchema,
                db_type: superset?.selectedDatabase?.backend,
                table_name: superset?.selectedTable?.value
            })?.unwrap();

            console.log(response, ">>> Response");
            await superset?.setTableMetadata((prev) => ({
                ...prev,
                tableData: response?.data
            }));
            console.log(response?.data, response?.data?.query?.sql, ">>>> UUUU")
            superset?.setDatasettableloading(false);
            superset?.setSqlQuery(`${response?.data?.query?.sql}`);
            response?.data?.length <= 0 && await showAlert(`No data found for the given query. Please try again with different parameters.`, 'info');
            return response?.data;
        } catch (err) {
            // showAlert(err?.data?.message, 'error');
            setErrorMessage(err?.data?.message)
            superset?.setDatasettableloading(false);
            return;
        }

    }

    const fetchDatabaseConnections = async () => {
        try {
            superset?.setIsDataBaseLoading(true)

            // const dbConnctions = await fetch("http://localhost:8088/api/v1/database/?q=(order_column:changed_on_delta_humanized,order_direction:desc,page:0,page_size:25)", {
            //     method: "GET",
            //     headers: {
            //         "Authorization": `Bearer ${supersetLoginToken}`,
            //         "Content-Type": "application/json; charset=utf-8",
            //         "X-CSRFToken": supersetCsrfToken
            //     },
            //     credentials: "include"
            // });

            const dbConnctions = await getAllDatabaseConnections()?.unwrap();
            superset?.setDbConnections(dbConnctions?.data);
            superset?.setIsDataBaseLoading(false)
        } catch (err) {
            console.log(err, "Error");
            showAlert(err?.data?.message, "error")
            superset?.setIsDataBaseLoading(false)
        }
    }
    const handleRemoveDatabse = async (id) => {
        try {
            const result = await removeConnection({
                id,
                loginToken: supersetLoginToken,
                csrfToken: supersetCsrfToken
            })?.unwrap();

            console.log(superset?.dbConnections, ">>> Db connections ");
            console.log(superset?.dbConnections?.filter((db) => db?.id !== id, ">>> Db connections "))

            superset?.setDbConnections(superset?.dbConnections?.filter((db) => db?.id !== id));

            //  await fetchDatabaseConnections();


            console.log(result, ">>> REsult ");
        } catch (err) {
            showAlert(err?.data?.message, "error")
            console.log(err);
        }
    }

    const connectDatabase = async () => {
        try {
            console.log(superset?.credentials, ">>>> Credentials");
            console.log(superset?.selectedDB, ">>> Selected DB");

            const response = await connectDbSuperset({
                loginToken: supersetLoginToken,
                csrfToken: supersetCsrfToken,
                host: superset?.credentials?.host,
                port: superset?.credentials?.port,
                dbName: superset?.credentials?.database,
                password: superset?.credentials?.password,
                userName: superset?.credentials?.username,
                nameToDisplay: superset?.credentials?.displayName,
                dbType: superset?.selectedDB
            })?.unwrap();

            superset?.setCredentials({
                host: "",
                port: "",
                username: "",
                password: "",
                displayName: '',
                database: ""
            });

            superset?.setIsCreateCon(false);
            superset?.setIsDatabaseView(true);
            setActiveNavItem("Database Connections")
            showAlert("Database connected successfully", "success");
            superset?.setSelectedDB(null)
            console.log(response, ">>>> Response ");

            await fetchDatabaseConnections();
        } catch (err) {
            console.error(err, ">>> Error while connecting");

            // Properly handle API error structure
            const errorMessage =
                err?.message || // CustomError message
                err?.data?.message || // API error message
                "An unexpected error occurred while connecting.";

            showAlert(errorMessage, "error");
        }
    }
    const isReadOnlyQuery = (query) => {
        const forbiddenKeywords = /\b(INSERT|UPDATE|DELETE|CREATE|ALTER|DROP|TRUNCATE|REPLACE|GRANT|REVOKE|MERGE)\b/i;
        return !forbiddenKeywords.test(query); // Returns false if a modifying keyword is found
    };
    const processSQLQuery = (query) => {
        // Convert query to lowercase for case-insensitive matching
        const lowerCaseQuery = query.toLowerCase();



        // Remove LIMIT and OFFSET using regex
        let modifiedQuery = query.replace(/\s*limit\s+\d+/gi, "").replace(/\s*offset\s+\d+/gi, "");

        return modifiedQuery.trim(); // Return cleaned query
    };

    // const addNewDataset = async () => {
    //     try {
    //         console.log(superset?.sqlQuery, ">> Sql Query ...");

    //         if (!isReadOnlyQuery(superset?.sqlQuery)) {
    //             showAlert("You do not have permission to modify the data");
    //             return;
    //         }

    //         const cleanedQuery = processSQLQuery(superset?.sqlQuery);
    //         console.log(cleanedQuery, ">>> Cleaned Query ...")
    //         const newDataset = await createDataset({
    //             database_id: superset?.selectedDatabase?.id,
    //             sql: cleanedQuery,
    //             schema_name: superset?.selectedSchema,
    //             table_name: superset?.selectedTable?.value,
    //             db_type: superset?.selectedDatabase?.backend
    //         })?.unwrap();
    //         console.log(newDataset, ">>> new Dataset")
    //         superset?.setIsDatasetView(false);
    //         handleOnImportExcel(superset?.selectedTable?.value)

    //     } catch (err) {
    //         console.error(err, ">>> Error while connecting");
    //         showAlert(err?.data?.message, "error");
    //     }
    // }
    const addDataSetApi = async () => {
        try {
            setIsDatasetLoading(true);
            console.log(superset?.sqlQuery, ">> Sql Query ...");

            if (!isReadOnlyQuery(superset?.sqlQuery)) {
                showAlert("You do not have permission to modify the data");
                setIsDatasetLoading(false);
                return;
            }

            setDatasetLoadingStep("Processing SQL Query...");
            const cleanedQuery = processSQLQuery(superset?.sqlQuery);
            console.log(cleanedQuery, ">>> Cleaned Query ...");

            setDatasetLoadingStep("Creating DataView...");
            const newDataset = await createDataset({
                database_id: superset?.selectedDatabase?.id,
                sql: cleanedQuery,
                schema_name: superset?.selectedSchema,
                table_name: tableNameFromUser?.name,
                table_name_qry: superset?.selectedTable?.value,
                description: tableNameFromUser?.description,
                db_type: superset?.selectedDatabase?.backend
            })?.unwrap();

            console.log(newDataset, ">>> new Dataset");

            setDatasetLoadingStep("Importing Data...");
            await handleOnImportExcel(superset?.selectedTable?.value);

            // Show success message for 2 seconds
            setDatasetLoadingStep("DataView created successfully!");
            setTimeout(() => {
                superset?.setIsDatasetView(false);
                showAlert("DataView created successfully!", "success");

                // Reset states
                setIsDatasetLoading(false);
                setDatasetLoadingStep("");
                superset?.setSelectedSchema(null);
                superset?.setSelectedTable(null);
                superset?.setselectedDatabse(null);
                superset?.setTableMetadata({ metaData: [], tableData: [] });
            }, 2000);
            setTableNameFromUser(null)

        } catch (err) {
            console.error(err, ">>> Error while connecting");
            showAlert(err?.data?.message, "error");
            setIsDatasetLoading(false);
            setDatasetLoadingStep("");
        }
    }

    const addNewDataset = async () => {
        setIsAddDatasetModalOpen(true);
        setTableNameFromUser(null)

    };



    // const addNewDataset = async () => {
    //     try {
    //         setIsDatasetLoading(true);

    //         console.log(superset?.sqlQuery, ">> Sql Query ...");

    //         if (!isReadOnlyQuery(superset?.sqlQuery)) {
    //             showAlert("You do not have permission to modify the data");
    //             setIsDatasetLoading(false);
    //             return;
    //         }

    //         setDatasetLoadingStep("Processing SQL Query...");
    //         await new Promise(resolve => setTimeout(resolve, 2000));

    //         const cleanedQuery = processSQLQuery(superset?.sqlQuery);
    //         console.log(cleanedQuery, ">>> Cleaned Query ...");

    //         setDatasetLoadingStep("Creating Dataset...");
    //         await new Promise(resolve => setTimeout(resolve, 3000));

    //         const newDataset = await createDataset({
    //             database_id: superset?.selectedDatabase?.id,
    //             sql: cleanedQuery,
    //             schema_name: superset?.selectedSchema,
    //             table_name: superset?.selectedTable?.value,
    //             db_type: superset?.selectedDatabase?.backend
    //         })?.unwrap();

    //         console.log(newDataset, ">>> new Dataset");

    //         setDatasetLoadingStep("Finalizing dataset creation...");
    //         await new Promise(resolve => setTimeout(resolve, 2000));

    //         setDatasetLoadingStep("Importing Data...");
    //         await new Promise(resolve => setTimeout(resolve, 2000));

    //         await handleOnImportExcel(superset?.selectedTable?.value);

    //         setDatasetLoadingStep("Dataset created successfully!");
    //         await new Promise(resolve => setTimeout(resolve, 2000));

    //         // Now, set dataset view to false
    //         superset?.setIsDatasetView(false);

    //         showAlert("Dataset created successfully!", "success");

    //         // Reset states
    //         setIsDatasetLoading(false);
    //         setDatasetLoadingStep("");
    //         superset?.setSelectedSchema(null)
    //         superset?.setSelectedTable(null)
    //         superset?.setselectedDatabse(null)
    //         superset?.setTableMetadata({ metaData: [], tableData: [] })

    //     } catch (err) {
    //         console.error(err, ">>> Error while connecting");
    //         showAlert(err?.data?.message, "error");
    //         setIsDatasetLoading(false);
    //         setDatasetLoadingStep("");
    //     }
    // };


    React.useEffect(() => {
        const handleKeyDown = async (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();

                if (selectedComponent) {
                    // Check if new_table_name or new_column_name is empty
                    const isTableNameEmpty = !selectedComponent.new_table_name?.trim();
                    const isColumnNameEmpty = selectedComponent.columnProperties?.some((column) => !column.new_column_name?.trim());

                    if (isTableNameEmpty) {
                        showAlert("Table name cannot be empty. Reverting to default table name.", "error");
                        setSelectedComponent((prevSelectedComponent) => ({
                            ...prevSelectedComponent,
                            new_table_name: prevSelectedComponent.table_name, // Reset to default table name
                        }));
                    } else if (isColumnNameEmpty) {
                        showAlert("Column name cannot be empty. Reverting to default column names.", "error");
                        setSelectedComponent((prevSelectedComponent) => ({
                            ...prevSelectedComponent,
                            columnProperties: prevSelectedComponent.columnProperties.map((column) => ({
                                ...column,
                                new_column_name: column.name, // Reset column name to default
                            })),
                        }));
                    } else {
                        // Check for column name duplicates
                        const columnNameDuplicates = selectedComponent.columnProperties.some((column) => {
                            return selectedComponent.columnProperties.some(
                                (otherColumn) =>
                                    otherColumn.name !== column.name && otherColumn.new_column_name === column.new_column_name
                            );
                        });

                        // Check for table name duplicates across all components
                        const isDuplicateTableName = components?.some(
                            (component) =>
                                component?.new_table_name?.toLowerCase() === selectedComponent?.new_table_name?.toLowerCase() &&
                                component?.id !== selectedComponent?.id
                        );

                        if (columnNameDuplicates) {
                            showAlert("Column names cannot be duplicate. Reverting to default column names.", "error");

                            setSelectedComponent((prevSelectedComponent) => ({
                                ...prevSelectedComponent,
                                columnProperties: prevSelectedComponent.columnProperties.map((column) => ({
                                    ...column,
                                    new_column_name: column.name, // Revert to default column name
                                })),
                            }));
                        } else if (isDuplicateTableName) {
                            showAlert("Table name cannot be duplicate. Reverting to default table name.", "error");

                            setSelectedComponent((prevSelectedComponent) => ({
                                ...prevSelectedComponent,
                                new_table_name: prevSelectedComponent.table_name, // Revert table name to default
                            }));
                        } else {
                            handleSaveHit()
                        }
                    }
                } else {
                    console.error("Cannot save: selectedComponent is null or undefined");
                    showAlert("No component selected to save.", "error");
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedComponent, components, query, computedColumn]);

    React.useEffect(() => {
        if (selectedComponent) {
            if (selectedComponent.new_table_name !== selectedComponent.table_name) {
                setSelectedComponent((prevSelectedComponent) => ({
                    ...prevSelectedComponent,
                    new_table_name: prevSelectedComponent.table_name,
                }));
            }
        }
    }, [selectedComponent?.id]);

    React.useEffect(() => {
        if (selectedComponent) {
            // Reset the new_column_name to default if it's empty
            setSelectedComponent((prevSelectedComponent) => ({
                ...prevSelectedComponent,
                columnProperties: prevSelectedComponent?.columnProperties?.map((column) => ({
                    ...column,
                    new_column_name: column?.new_column_name?.trim() === "" ? column?.name : column.new_column_name, // Reset to default name if empty
                })),
            }));
        }
    }, [selectedComponent?.id, selectedColumn]);

    React.useEffect(() => {
        selectedComponent == null && setIsTableLoading(true);
        selectedComponent == null && handleOnImportExcel();
    }, []);

    React.useEffect(() => {
        //  fetchSupersetTokens();
        fetchDatabaseConnections();
    }, []);
    return (
        <div
            // className='d-flex flex-col h-[90vh] relative'
            className='d-flex flex-col relative'
            style={{ height: "calc(100vh - 57px)" }}
        >
            {isAddDatasetModalOpen &&
                <ReusableReportModal
                    open
                    onClose={() => {
                        setIsAddDatasetModalOpen(false);
                        setTableNameFromUser({ name: null, description: null });
                    }}
                    // title={`${!isCloseReport
                    //     ? reportId === null || isSaveAsReport
                    //         ? "Create New Report"
                    //         : "Update Report"
                    //     : ""
                    //     }`}
                    title={"New DataView Name"}
                >
                    <>
                        <div style={{ width: "30rem", height: "12rem" }}>
                            <div className="flex flex-row items-center  mt-2">
                                <div className="flex flex-col  items-start justify-start w-full gap-1 py-1 px-4">
                                    <p className="m-0 font-bold text-sm whitespace-nowrap">
                                        Name
                                    </p>
                                    <input
                                        type="text"
                                        className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                        value={tableNameFromUser?.name}
                                        onChange={(e) => {
                                            setTableNameFromUser({ ...tableNameFromUser, name: e?.target?.value });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row items-center  mt-2">
                                <div className="flex flex-col  items-start justify-start w-full gap-1 py-1 px-4">
                                    <p className="m-0 font-bold text-sm whitespace-nowrap">
                                        Description
                                    </p>
                                    <input
                                        type="text"
                                        className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                        value={tableNameFromUser?.description}
                                        // onChange={(e) => {
                                        //     setTableNameFromUser({ ...tableNameFromUser, description: e?.target?.value })
                                        // }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const nonSpaceLength = value.replace(/\s/g, '').length;

                                            if (nonSpaceLength <= 400) {
                                                setTableNameFromUser({
                                                    ...tableNameFromUser,
                                                    description: value
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="pb-2 px-4 mt-2 text-right">
                                <button
                                    disabled={!tableNameFromUser?.name || !tableNameFromUser?.description}
                                    onClick={() => {
                                        addDataSetApi();
                                        setIsAddDatasetModalOpen(false);
                                    }}
                                    className={`px-3 text-sm btn-bgcolor py-1 font-semibold text-white rounded-md  disabled:cursor-not-allowed disabled:opacity-40`}
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </>
                </ReusableReportModal>
            }
            {isQueryModalOpen &&
                <ReusableDataModal
                    open
                    onClose={() => {
                        setIsQueryModalOpen(false);
                    }}
                    title={"View Query"}
                >
                    <div className="w-[30rem] min-h-[7rem] overflow-auto custom-scrollbar">
                        <span className="flex flex-row items-center  mt-2 mb-2">
                            <div className="flex flex-col  items-start justify-start w-full gap-1 py-1 px-4">
                               
                                <textarea
                                    className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 resize-none  w-full focus:ring-blue-500 focus:border-blue-500  overflow-auto custom-scrollbar"
                                    // value={superset?.datasetProperties?.query}
                                    value={
                                        components?.find(
                                          (comp) =>
                                            comp.table_name === selectedComponent?.table_name &&
                                            comp.datasetProperties !== null
                                        )?.datasetProperties?.query || ''
                                      }
                                    readOnly
                                    rows={1}
                                    style={{ minHeight: "5rem", maxHeight: "50rem" }}
                                />
                            </div>
                        </span>
                    </div>
                </ReusableDataModal>}
            {isLoading && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                    }}
                >
                    <div
                        style={{
                            borderRadius: "8px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        }}
                        className='bg-[#fff] pt-6 pb-3 w-[30%] text-center flex flex-col gap-4'
                    >
                        {/* <Stack alignItems="center">
                            <CircularProgress size="30px" color="inherit" />
                        </Stack> */}
                        <Stack alignItems="center">
                            {loadingStep === "File uploaded successfully!" ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgcolor="green"
                                    borderRadius="50%"
                                    width="50px"
                                    height="50px"
                                >
                                    <CheckIcon style={{ fontSize: "30px", color: "white" }} />
                                </Box>
                            ) : (
                                <CircularProgress size="30px" color="inherit" />
                            )}
                        </Stack>
                        <p>{loadingStep}</p>
                        {/* <p>Working on it....</p> */}
                    </div>
                </div>
            )}
            {isDatasetLoading && (
                <div
                    style={{
                        position: "absolute",
                        right: "20px",  // Aligned to right
                        bottom: "20px", // Aligned to bottom
                        zIndex: 10,
                    }}
                >
                    <div
                        style={{
                            borderRadius: "8px",
                            // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                            border: '1px solid #8b9dba'
                        }}
                        className={`bg-[#edf0f5]  px-3 py-2  ${datasetLoadingStep === "DataView created successfully!" ? "w-fit" : 'w-[250px]'} h-fit text-center flex justify-center item-center gap-3`}
                    >
                        <Stack alignItems="center" justifyContent="center">
                            {datasetLoadingStep === "DataView created successfully!" ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgcolor="green"
                                    borderRadius="50%"
                                    width="30px"
                                    height="30px"
                                >
                                    <CheckIcon style={{ fontSize: "20px", color: "white" }} />
                                </Box>
                            ) : (
                                <CircularProgress size="25px" sx={{ color: '#0c70a6' }} disableShrink />
                            )}
                        </Stack>
                        <p className={`${datasetLoadingStep === "DataView created successfully!" ? "pt-1" : ''}`}>{datasetLoadingStep}</p>
                    </div>
                </div>
            )}

            <ReusableDataModal
                open={validateModalOpen?.status}
                onClose={() => {
                    setValidateModalOpen({ status: false, data: [], dataToAppend: [], typeErrors: [] })
                }}
                title="Append Data"
            >
                <div className="mx-2 my-2 px-2 py-1 d-flex flex-col">
                    <div className="text-[15px] w-[55rem] mt-1 font-semibold">{validateModalOpen?.typeErrors?.length > 0 ?
                        "The following data could not be appended. Reasons listed below." :
                        `Duplicate data detected.`}</div>
                    {validateModalOpen?.typeErrors?.length > 0 &&
                        <div className="self-end mt-2 max-h-64 max-w-[55rem] overflow-auto custom-scrollbar">
                            <table className="scroll tablestyle  w-[55rem] ">
                                <thead style={{ top: '-1px' }}>
                                    <tr>
                                        <th
                                            style={{
                                                whiteSpace: "nowrap",
                                                padding: "4px 5px",
                                                textAlign: "center",
                                                backgroundColor: '#d1e2eb',
                                            }}
                                            className='tablestyle cursor-pointer'
                                        >
                                            <span className="flex items-center justify-between">
                                                Error message
                                            </span>
                                        </th>
                                        {validateModalOpen?.typeErrors?.length > 0 && Object?.keys(validateModalOpen?.typeErrors[0]?.row)?.map((item) => (
                                            <th
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    padding: "4px 5px",
                                                    textAlign: "center",
                                                    backgroundColor: '#d1e2eb',
                                                }}
                                                className='tablestyle cursor-pointer'
                                            >
                                                <span className="flex items-center justify-between">
                                                    {item}
                                                </span>
                                            </th>
                                        ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {validateModalOpen?.typeErrors?.map((row, rowIndex) => {
                                        return (
                                            <tr>
                                                <td
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        textAlign: "left",
                                                        padding: "2px 5px",
                                                        color: "#c2410c"
                                                    }}
                                                    className="tablestyle">
                                                    {row?.msg}
                                                </td>
                                                {
                                                    Object?.keys(validateModalOpen?.typeErrors[0]?.row)?.map((item, colIndex) => (
                                                        <td
                                                            key={colIndex}
                                                            style={{
                                                                whiteSpace: "nowrap",
                                                                textAlign: "left",
                                                                padding: "2px 5px",
                                                            }}
                                                            className="tablestyle">
                                                            {row?.row[item]}
                                                        </td>
                                                    ))
                                                }
                                            </tr>)
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>}
                    {validateModalOpen?.typeErrors?.length > 0 && validateModalOpen?.data?.length > 0 &&
                        <div className="text-[15px] w-[55rem] mt-1 font-semibold">Duplicate data detected</div>
                    }
                    <div className="self-end mt-2 max-h-96 max-w-[55rem] overflow-auto custom-scrollbar">
                        <table className="scroll tablestyle  w-[55rem] ">
                            <thead style={{ top: '-1px' }}>
                                <tr>
                                    {validateModalOpen?.data?.length > 0 && Object?.keys(validateModalOpen?.data[0])?.map((item) => (
                                        <th
                                            style={{
                                                whiteSpace: "nowrap",
                                                padding: "4px 5px",
                                                textAlign: "center",
                                                backgroundColor: '#d1e2eb',
                                            }}
                                            className='tablestyle cursor-pointer'
                                        >
                                            <span className="flex items-center justify-between">
                                                {item}
                                            </span>
                                        </th>
                                    ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {validateModalOpen?.data?.map((row, rowIndex) => {
                                    return (<tr>
                                        {
                                            Object?.keys(validateModalOpen?.data[0])?.map((item, colIndex) => (
                                                <td
                                                    key={colIndex}
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        textAlign: "left",
                                                        padding: "2px 5px",
                                                    }}
                                                    className="tablestyle">
                                                    {row[item]}
                                                </td>
                                            ))
                                        }
                                    </tr>)
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                    <span className='d-flex items-end justify-end'>
                        <button
                            onClick={() => {
                                setValidateModalOpen({ status: false, data: [] })
                            }}
                            className="px-4 py-1 font-bold text-white rounded-md btn-bgcolor text-sm disabled:opacity-40 disabled:cursor-not-allowed mt-2 mx-2"
                        >
                            Ok
                        </button>
                        {/* {validateModalOpen?.typeErrors?.length <= 0 && <button
                            onClick={handleProceedAppendData}
                            className="px-4 py-1 font-bold text-white rounded-md btn-bgcolor text-sm disabled:opacity-40 disabled:cursor-not-allowed mt-2 mx-2"
                        >
                            Proceed
                        </button>} */}
                    </span>
                </div>
            </ReusableDataModal>
            <ReusableDataModal
                open={superset?.isCreateCon}
                onClose={() => {
                    superset?.setIsCreateCon(false);
                    superset?.setSelectedDB(null)
                    superset?.setCredentials({
                        host: "",
                        port: "",
                        username: "",
                        password: "",
                        displayName: '',
                        database: ""
                    })
                }}
                title="Connect Database"
            >
                <div className="mx-2 my-2 px-2 py-1 d-flex flex-col">
                    <div className="px-4 pb-2">
                        {!superset?.selectedDB ? (
                            <>
                                <h3 className="font-semibold text-base text-[#4f5051] mb-3">Select Database Type</h3>
                                <div className="grid grid-cols-3 gap-4 space-x-1">
                                    {databaseOptions.map((db) => (
                                        <div
                                            key={db.type}
                                            className="flex flex-col items-center justify-center px-4 pt-4 pb-2 gap-3 border rounded-lg cursor-pointer  hover:shadow-[4px_4px_20px_rgb(224,224,224)] transition-shadow"
                                            onClick={() => handleSelect(db.type)}
                                        >
                                            {db.icon}
                                            <span className=" text-sm ">{db.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="flex justify-between items-center mb-2 ">
                                    <h3 className="text-base text-[#4f5051]  font-semibold">Enter {superset?.selectedDB} Credentials</h3>
                                </div>
                                <div className="flex flex-col gap-4 mb-2">
                                    <div className="flex gap-4">
                                        <input
                                            name="host"
                                            placeholder="Host"
                                            onChange={handleChange}
                                            value={superset?.credentials.host}
                                            className="w-1/2 px-2 py-1 border rounded-[5px] text-sm"
                                        />
                                        <input
                                            name="port"
                                            placeholder="Port"
                                            onChange={handleChange}
                                            value={superset?.credentials.port}
                                            className="w-1/2 px-2 py-1 border rounded-[5px] text-sm"
                                        />
                                    </div>

                                    <input
                                        name="database"
                                        placeholder="Database Name"
                                        onChange={handleChange}
                                        value={superset?.credentials.database}
                                        className="w-full px-2 py-1 border rounded-[5px] text-sm"
                                    />

                                    <div className="flex gap-4">
                                        <input
                                            name="username"
                                            placeholder="Username"
                                            onChange={handleChange}
                                            value={superset?.credentials.username}
                                            className="w-1/2 px-2 py-1 border rounded-[5px] text-sm"
                                        />
                                        <input
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            onChange={handleChange}
                                            value={superset?.credentials.password}
                                            className="w-1/2 px-2 py-1 border rounded-[5px] text-sm"
                                        />
                                    </div>

                                    <input
                                        name="displayName"
                                        placeholder="Display Name"
                                        onChange={handleChange}
                                        value={superset?.credentials.displayName}
                                        className="w-full px-2 py-1 border rounded-[5px] text-sm"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <span className='d-flex mt-2 gap-2 justify-end'>
                        <button
                            onClick={() => {
                                superset?.setSelectedDB(null);
                                superset?.setCredentials({
                                    host: "",
                                    port: "",
                                    username: "",
                                    password: "",
                                    displayName: '',
                                    database: ""
                                })

                            }}
                            disabled={!superset?.selectedDB}
                            className="px-4 py-1 font-bold text-white rounded-md btn-bgcolor text-sm disabled:opacity-40 disabled:cursor-not-allowed"
                        >
                            Back
                        </button>
                        <button
                            onClick={() => {
                                connectDatabase();
                            }}
                            disabled={!superset?.selectedDB}
                            className="px-4 py-1 font-bold text-white rounded-md btn-bgcolor text-sm disabled:opacity-40 disabled:cursor-not-allowed "
                        >
                            Connect
                        </button>

                    </span>
                </div>
            </ReusableDataModal>
            <Snackbar
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={5000}
                message={message ? message : "Something went wrong"}
                severity="error"
            >
                <Alert
                    onClose={() => setOpen(false)}
                    severity={severity ? severity : "error"}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {message ? message : "Something went wrong"}
                </Alert>
            </Snackbar>
            <Nav
                isTableTool={selectedComponent !== null}
                activeNavItem={activeNavItem}
                setActiveNavItem={setActiveNavItem}
                selectedColumn={selectedColumn}
                setSelectedColumn={setSelectedColumn}
                // isDatabaseView={isDatabaseView}
                // setIsDatabaseView={setIsDatabaseView}
                // setIsDatasetView={setIsDatasetView}
                superset={superset}
            />
            <Menu
                activeNavItem={activeNavItem}
                setActiveNavItem={setActiveNavItem}
                selectedComponent={selectedComponent}
                isTables={components?.length > 0}
                fetchTableData={fetchTableData}
                count={count}
                selectedColumn={selectedColumn}
                setSelectedColumn={setSelectedColumn}
                setIsExpand={setIsExpand}
                isExpand={isExpand}
                onPropertyChange={handlePropertyChange}
                setSelectedComponent={setSelectedComponent}
                handleNewColumn={handleNewColumn}
                handleImportFile={handleImportFile}
                handleAddDataColumn={handleAddDataColumn}
                showAlert={showAlert}
                hadleSaveHit={handleSaveHit}
                handleAppendData={handleAppendData}
                handleUploadExcelFile={handleUploadExcelFile}
                dateformat={dateformat}
                handleAddNewDatabaseConnection={handleAddNewDatabaseConnection}
                superset={superset}
                // isDatabaseView={isDatabaseView}
                handleAddDataset={() => {
                    superset?.setIsDatasetView(true)
                }
                }
                setIsQueryModalOpen={setIsQueryModalOpen}
                isTableloading={isTableloading}
                components={components}
            // limitOptions={limitOptions}
            // setSelectedLimit={setSelectedLimit}
            // selectedLimit={selectedLimit}
            // isDatasetView={isDatasetView}
            />
            <div className='d-flex grow overflow-auto' style={{ margin: '1px' }} >
                {((components?.length > 0 || superset?.isDatasetView) && !superset?.isDatabaseView) &&
                    <PropertiesPanel
                        components={components}
                        getTableData={getTableData}
                        selectedComponent={selectedComponent}
                        setSelectedComponent={setSelectedComponent}
                        setSelectedColumn={setSelectedColumn}
                        selectedColumn={selectedColumn}
                        onPropertyChange={handlePropertyChange}
                        isExpand={isExpand}
                        computedColumn={computedColumn}
                        setComputedColumn={setComputedColumn}
                        setQuery={setQuery}
                        showAlert={showAlert}
                        setIsModalOpen={setIsModalOpen}
                        isModalOpen={isModalOpen}
                        setErrorComputed={setErrorComputed}
                        checkColumnsInExpression={checkColumnsInExpression}
                        query={query}
                        errorComputed={errorComputed}
                        // isDatasetView={isDatasetView}
                        // dbConnections={dbConnections}
                        fetchSchemas={fetchSchemas}
                        fetachTables={fetachTables}
                        fetachTableMetadata={fetachTableMetadata}
                        // setSelectedTable={setSelectedTable}
                        // setSelectedSchema={setSelectedSchema}
                        // setselectedDatabse={setselectedDatabse}
                        // selectedTable={selectedTable}
                        // selectedSchema={selectedSchema}
                        // selectedDatabase={selectedDatabase}
                        addNewDataset={addNewDataset}
                        superset={superset}
                        errorMessage={errorMessage}
                        isAddDatasetModalOpen={isAddDatasetModalOpen}
                        isDatasetLoading={isDatasetLoading}
                    />
                }
                <MainContainer
                    setErrorMessage={setErrorMessage}
                    onCareSMSClick={handleOnImportCareSms}
                    components={components}
                    selectedComponent={selectedComponent}
                    isTableloading={isTableloading}
                    selectedColumn={selectedColumn}
                    setSelectedColumn={setSelectedColumn}
                    onPropertyChange={handlePropertyChange}
                    setSelectedComponent={setSelectedComponent}
                    limit={limit}
                    filterData={isExcelUpload ? filteredDataSkorboard : filterData}
                    setCount={setCount}
                    computedColumn={computedColumn}
                    setComputedColumn={setComputedColumn}
                    handleUploadExcelFile={handleUploadExcelFile}
                    showAlert={showAlert}
                    createComputedColumn={createComputedColumn}
                    getTableDataSkorBoard={getTableDataSkorBoard}
                    query={query}
                    setQuery={setQuery}
                    handleSelectColumn={handleSelectColumn}
                    generateColumnName={generateColumnName}
                    primaryProperties={primaryProperties}
                    setPrimaryProperties={setPrimaryProperties}
                    handleCreateComputedColumn={handleCreateComputedColumn}
                    errorComputed={errorComputed}
                    setErrorComputed={setErrorComputed}
                    checkColumnsInExpression={checkColumnsInExpression}
                    dateformat={dateformat}
                    handleAddColumnValues={handleAddColumnValues}
                    dateFormats={dateFormats}
                    fetchTableData={fetchTableData}
                    errorMessage={errorMessage}
                    handleRemoveDatabse={handleRemoveDatabse}
                    datasetLoadingStep={datasetLoadingStep}
                    isDatasetLoading={isDatasetLoading}
                    // isDatabaseView={isDatabaseView}
                    // dbConnections={dbConnections}
                    // isDatabseLoading={isDatabseLoading}
                    // isDatasetView={isDatasetView}
                    // tableMetaData={tableMetaData}
                    activeNavItem={activeNavItem}
                    setActiveNavItem={setActiveNavItem}
                    superset={superset}
                    // setIsDatabaseView={setIsDatabaseView}
                    getQueriedTableData={getQueriedTableData}
                    handleAddNewDatabaseConnection={handleAddNewDatabaseConnection}
                // datasettableloading={datasettableloading}
                // setSqlQuery={setSqlQuery}
                // sqlQuery={sqlQuery}

                />
                {/* {components?.length > 0 &&
                    <PropertiesPanel
                        components={components}
                        getTableData={getTableData}
                        selectedComponent={selectedComponent}
                        setSelectedComponent={setSelectedComponent}
                        setSelectedColumn={setSelectedColumn}
                        selectedColumn={selectedColumn}
                        onPropertyChange={handlePropertyChange}
                        isExpand={isExpand}
                    />} */}
            </div>
        </div>
    )
}
export default DataManagement;