import React from "react";
import { Icons } from "../../../assets";
import { useNavigate } from "react-router-dom";

export const subscriptionMap = {
  skorboard_personal: "Skorboard Personal",
  skorboard_business_small: "Skorboard Business Small",
  skorboard_business_medium: "Skorboard Business Medium",
  skorboard_business_enterprise: "Skorboard Business Enterprise",
};

const priceMap = {
  skorboard_personal: 0,
  skorboard_business_small: 0,
  skorboard_business_medium: 0,
  skorboard_business_enterprise: 0,
};

const PlanDetails = ({ user }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/plans");
  };

  return (
    <div className="flex flex-col gap-6 px-12 py-10">
      <h3 className="m-0 text-3xl font-semibold">Plan details</h3>
      <div className="flex flex-row w-full gap-16">
        <div className="flex flex-col flex-1 gap-8 p-6 border shadow-sm rounded-2xl">
          <div className="flex flex-row items-start justify-between">
            <div className="w-12 h-12 flex items-center justify-center rounded-full border-[1px]">
              <img
                src={Icons.Wallet_Icon}
                alt="Wallet"
                className="object-contain w-5 h-5"
              />
            </div>
            <img
              src={Icons.Verified_Icon}
              alt="Verified"
              className="object-contain w-5 h-5"
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <p className="m-0 text-sm text-primarytextgrey">Current plan</p>
              <div className="flex flex-row items-center gap-2 px-2 rounded-md bg-subtleblue w-fit">
                <div className="w-2 h-2 rounded-full bg-primaryblue" />
                <p className="m-0 text-sm text-primaryblue">
                  {user.subscription.lookupKey
                    ? subscriptionMap[user.subscription.lookupKey]
                    : "NA"}
                </p>
              </div>
            </div>
            {user.subscription.lookupKey ? (
              <p className="m-0 text-xl font-semibold">
                ${priceMap[user.subscription.lookupKey]}
                <span className="m-0 text-lg text-primarytextgrey">/month</span>
              </p>
            ) : (
              <p className="m-0 text-xl font-semibold">NA</p>
            )}
          </div>
          <div className="flex flex-col gap-2">
            {/* <p className="m-0 text-sm font-semibold">Billed $801 last month</p> */}
            <p className="m-0 text-sm font-semibold">
              Billed monthly based at the start of the billing cycle
            </p>
          </div>
        </div>
        <div className="flex flex-col flex-1 gap-8 p-6 border shadow-sm rounded-2xl">
          <div className="w-12 h-12 flex items-center justify-center rounded-full border-[1px]">
            <img
              src={Icons.Wallet_Icon}
              alt="Wallet"
              className="object-contain w-5 h-5"
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="m-0 text-sm text-primarytextgrey">
              Interested in another plan?
            </p>
            <p className="m-0 text-xl font-semibold">Switch plan👇</p>
          </div>
          <button
            className="flex flex-row items-center justify-center h-10 text-white rounded-md w-44 bg-primaryblue"
            onClick={onClick}
          >
            <p className="m-0 text-base whitespace-nowrap">View all plans</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanDetails;
