import { Checkbox, CircularProgress, FormControlLabel, Grid } from "@mui/material";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { Tab, Tabs } from "@mui/material";
import { useFilterTableDataMutation, useGetTableDataByNameMutation } from "../../../../../features/report/reportApiSlice";
import { useGetReportTableDataMutation, useGetTableDataMutation } from "../../../../../features/report/skorBoard";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
import AreaChartIcon from "../../../../../assets/svgs/AreaChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import { CheckBox } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import DoubleBarChartImage from "../../../../../assets/images/doublebar-chart-icon.png";
import DoublebarChartIconsvg from "../../../../../assets/svgs/DoublebarChartIconsvg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LinebarChartIconsvg from "../../../../../assets/svgs/linebarChartIconsvg";
import PieChartIcon from "@mui/icons-material/PieChart";
import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import ReusableDashboardModal from "../ReusableDashboardModal"
import ScatterChart from "../../../../../assets/svgs/ScatterChart";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import SearchIcon from '@mui/icons-material/Search';
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { SketchPicker } from "react-color";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import { Tooltip } from "@mui/material";
import { XAxis } from "recharts";
import classNames from "classnames";
import lineBarChartImage from "../../../../../assets/images/linebar-chart-icon.png";
import moment from "moment";
import scatteredChartImage from "../../../../../assets/images/scatter-chart-icon.png";
import CheckIcon from '@mui/icons-material/Check';
const ItemTypes = {
  COLUMN: 'column',
};

const timeGrains = [
  { name: "Second", value: "second" },
  { name: "Minute", value: "minute" },
  { name: "Hour", value: "hour" },
  { name: "Day", value: "day" },
  { name: "Week", value: "week" },
  { name: "Month", value: "month" },
  { name: "Quarter", value: "quarter" },
  { name: "Year", value: "year" },
];

const SidebarSection = ({ title, children, subSection, isOpen, onToggle }) => {
  // const [isOpen, setIsOpen] = React.useState(false);
  // const toggleOpen = () => {
  //     setIsOpen(!isOpen);
  // };

  return (
    <div className="sidebar-section">
      <div
        className={`sidebar-section-header ${subSection ? "px-1" : "px-2"} cursor-pointer 
       }`}
        onClick={onToggle}
        style={{
          backgroundColor: !subSection ? "#c1c2c6" : "#e4e4eb",
          borderRadius: subSection ? "5px" : ""
        }}
      >
        <div className={`d-flex ${subSection ? "" : "justify-between"} items-center`}>
          <span
            className={`font-semibold  ${subSection ? "text-[12px] text-gray-600 py-1" : "text-[12px] text-[#4d5259]" //4b5563
              } `}
            style={{
              color: subSection ? "text-sm text-gray-600" : "text-[#4d5259]",
              paddingLeft: subSection ? "" : "8px",
            }}
          >
            {title}
          </span>
          <button className="toggle-button">
            {!subSection ? (
              isOpen ? (
                <Tooltip title="Remove">
                  <RemoveIcon
                    className="text-[#4d5259]"
                    sx={{ fontSize: "14px" }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Add">
                  <AddIcon
                    className="text-[#4d5259]"
                    sx={{ fontSize: "14px" }}
                  />
                </Tooltip>
              )
            ) : null}
          </button>
        </div>
      </div>
      {isOpen && <div className="sidebar-section-content">{children}</div>}
    </div>
  );
};
const Column = ({ item, removeColumn, showCloseIcon }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.COLUMN,
    item: { column: item },  // Passing the column name only
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={`relative pl-0.5 pr-0.5 text-[#374151] text-[10px] bg-white input-border-custome h-fit w-fit rounded-md flex items-center justify-between mb-0.5 ml-0.5  ${isDragging ? 'opacity-50' : ''
        }`}
      style={{ minWidth: '50px', fontSize: " 0.75rem", borderRadius: "5px" }}
    >
      {item?.columnName}
      {/* <button className="ml-2 text-gray-400 hover:text-red-600" onClick={() => removeColumn(item)}>
        <CloseIcon className="text-sm text-gray-400" />
      </button> */}
      {showCloseIcon && (
        <button className="ml-2 text-gray-400 hover:text-red-600" onClick={() => removeColumn(item)}>
          <CloseIcon className="text-sm text-gray-400" />
        </button>
      )}
    </div>
  );
};

const DropZone = ({ accept, onDrop, children, title, }) => {
  const [{ isOver }, drop] = useDrop({
    accept,
    drop: (item) => {
      onDrop(item)
    },
    // op: (item) => {
    //   // Call the onDrop callback if the condition is not met
    //   if (item !== null) {
    //     console.log(item, ">>> Ojjhju")
    //     onDrop(item);
    //   }
    // },
    // canDrop: (item) => {
    //   if (item === null) {
    //     return false; // Prevent dropping
    //   }
    //   return true; // Allow dropping
    // },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <>
      <p className="prop-label m-0">{title}</p>
      <div
        ref={drop}
        className={`flex flex-wrap p-1 overflow-auto custom-scrollbar min-h-12 max-h-14 input-border-custome rounded w-full ${isOver ? 'bg-blue-100' : ''
          }`}
      >
        {children}
      </div>
    </>
  );
};
function ChartProperties({
  component,
  onPropertyChange,
  setSelectedComponent,
  showAlert,
  flag,
  setFlag,
  reportAreaRef,
  generateChartData,
  echartsRef,
  generatePieChartData,
  generateDoubleChartData,
  openSection,
  setOpenSection,
  isAnimated,
  setIsAnimated,
  setConfirmModalOpendata,
  drillData,
  pieDrillData,
  tables,
  setReportProperty,
  generateAdvanceChartData,
  handleAddColumnValues,
  setValues,
  values
}) {
  const borderColorRef = React.useRef(null);
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [colorPickerBg, setColorPickerBg] = React.useState(false);
  const [colorPickerText, setColorPickerText] = React.useState(false);
  const [colorPickerLine, setColorPickerLine] = React.useState(false);
  const colorPickerRefs = React.useRef({});
  const columnDropdownRef = React.useRef(null);
  const [columnListOpen, setColumnListOpen] = React.useState(false);
  const [colorPickerState, setColorPickerState] = React.useState({});
  const [chartListOpen, setChartListOpen] = React.useState(false);
  const [zAxisListOpen, setzAxisListOpen] = React.useState(false);
  const textColorRef = React.useRef(null);
  const backgroundColorRef = React.useRef(null);
  const trendlineColorRef = React.useRef(null);
  const DropDownRef = React.useRef(null);
  const [selectedChartType, setSelectedChartType] = React.useState(
    component?.properties?.chartType == "bar"
      ? { value: 1, type: "Bar", icon: <BarChartIcon color="primary" /> }
      : { value: 2, type: "Pie", icon: <PieChartIcon color="primary" /> }
  );
  const [checkedItems, setCheckedItems] = React.useState(
    component?.properties?.yAxis || []
  );
  const zAxisRef = React.useRef(null);
  const [isLogScaleY, setIsLogScaleY] = React.useState(false);
  const [selectedLogValues, setSelectedLogValues] = React.useState([]); // State for selected log values
  const zAxisValues = component?.properties?.zAxis || []; // Get Z-axis values
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const logDropDownRef = React.useRef(null);

  const colForPie = component?.properties?.columns?.filter((item) => !component?.properties?.slice?.includes(item));
  const columnsMe = tables?.find((item) => item?.tableMetaData?.table_id == component?.properties?.tableId)?.columnMetaData?.map(item => ({ column_id: item?.column_id, column_name: item?.column_name })) || [];
  const [selectedCols, setSelectedCols] = React.useState(columnsMe);

  const [rowFields, setRowFields] = React.useState([]);
  const [colFields, setColFields] = React.useState([]);
  const [IsAddNewColumnOpen, setIsAddNewColumnOpen] = React.useState(false);
  const [deselectedColumns, setDeselectedColumns] = React.useState([]);
  const [selectedLine, setSelectedLine] = React.useState(component?.properties?.line || "none");

  // dropdowns
  const singleValueOperators = [
    { label: "Equal To", value: "EQUAL_TO" },
    { label: "Not Equal To", value: "NOT_EQUAL_TO" },
    { label: "Less Than", value: "LESS_THAN" },
    { label: "Less or Equal", value: "LESS_OR_EQUAL" },
    { label: "Greater Than", value: "GREATER_THAN" },
    { label: "Greater or Equal", value: "GREATER_OR_EQUAL" },
    { label: "Contains", value: "LIKE" },
    { label: "Case-Insensitive Contains", value: "ILIKE" },
  ];

  const multiValueOperators = [
    { label: "In", value: "IN" },
    { label: "Not In", value: "NOT_IN" },
  ];
  //  const [isLoading, setIsLoading] = React.useState(false);
  const [columnDropRef, setColumnDropRef] = React.useState(null);
  //  const [columnDropdownOpen, setColumnDropdownOpen] = React.useState(false);
  const [selectedCol, setSelectedCol] = React.useState(null);

  const [columns, setColumns] = React.useState([]);
  const [selectedColumn, setSelectedColumn] = React.useState(null);
  const [operators, setOperators] = React.useState([...singleValueOperators, ...multiValueOperators]);
  const [selectedOperator, setSelectedOperator] = React.useState(null);
  // const [values, setValues] = React.useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [isColumnDropdownOpen, setColumnDropdownOpen] = React.useState(false);
  const [isOperatorDropdownOpen, setOperatorDropdownOpen] = React.useState(false);
  const [isValueDropdownOpen, setValueDropdownOpen] = React.useState(false);
  const filterColumnDropdownRef = React.useRef(null);
  const operatorDropdownRef = React.useRef(null);
  const valueDropdownRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);

  // const handleColumnSelect2 = async (column) => {
  //   console.log(column, ">>> Colomn to select")
  //   setSelectedColumn(column);
  //   setSelectedOperator(null);
  //   setSelectedValues([]);
  //   setColumnDropdownOpen(false);
  //   const result = await handleAddColumnValues(column);
  //   console.log(result, ">>>> Result from api ");
  //   setValues(result?.data[column?.column_name]?.values);
  // };

  const [displayedValues, setDisplayedValues] = React.useState([]);
  const [valuePage, setValuePage] = React.useState(1);
  const valuesPerPage = 20;
  const handleColumnSelect2 = async (column) => {
    setSelectedColumn(column);
    setSelectedOperator(null);
    setSelectedValues([]);
    setColumnDropdownOpen(false);
    setIsLoading(true);

    try {
      const result = await handleAddColumnValues(column);
      console.log(result, ">>>> Result from API");

      const allValues = result?.data[column?.column_name]?.values || [];
      setValues(allValues);
      setDisplayedValues(allValues.slice(0, valuesPerPage)); // Load first 20
      setValuePage(1);
    } catch (error) {
      setValues([]);
      setDisplayedValues([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight - 10) { // User reached bottom
      if (displayedValues.length < values.length) {
        const nextPage = valuePage + 1;
        const newValues = values.slice(0, nextPage * valuesPerPage);

        setDisplayedValues(newValues);
        setValuePage(nextPage);
      }
    }
  };

  // const handleColumnSelect2 = async (column) => {
  //   setSelectedColumn(column);
  //   setSelectedOperator(null);
  //   setSelectedValues([]);
  //   setColumnDropdownOpen(false);
  //   setIsLoading(true);

  //   try {
  //     const result = await handleAddColumnValues(column);
  //     console.log(result, ">>>> Result from API");

  //     setValues(result?.data[column?.column_name]?.values || []);
  //   } catch (error) {
  //     setValues([]);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleOperatorSelect = (operator) => {
    setSelectedOperator(operator);
    setSelectedValues([]);
    setOperatorDropdownOpen(false);
  };
  const handleValueSelect = (value) => {
    const isMultiValueOperator = multiValueOperators.some((op) => op.value === selectedOperator?.value);

    if (isMultiValueOperator) {
      setSelectedValues((prev) =>
        prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
      );
    } else {
      setSelectedValues([value]);
      setValueDropdownOpen(false);
    }
  };




  const DAY_MAP = {
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };

  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
        ? value?.map((day) => DAY_MAP[day]).join(", ")
        : type === "date"
          ? moment(value).format("MM/DD/YYYY")
          : value;
  };

  const handleClickOutside = (event) => {
    if (
      textColorRef?.current &&
      !textColorRef?.current?.contains(event.target)
    ) {
      setColorPickerText(false);
    }
    if (
      backgroundColorRef?.current &&
      !backgroundColorRef?.current?.contains(event.target)
    ) {
      setColorPickerBg(false);
    }
    if (
      borderColorRef.current &&
      !borderColorRef.current.contains(event.target)
    ) {
      setColorPickerOpen(false);
    }
    if (
      trendlineColorRef?.current &&
      !trendlineColorRef?.current?.contains(event.target)
    ) {
      setColorPickerLine(false);
    }
    const colorPickerElements = Object.values(colorPickerRefs.current);
    if (!colorPickerElements.some((el) => el && el.contains(event.target))) {
      setColorPickerState({});
    }
    if (
      DropDownRef?.current &&
      !DropDownRef?.current?.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
    if (
      logDropDownRef?.current &&
      !logDropDownRef?.current?.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
    if (
      filterColumnDropdownRef?.current &&
      !filterColumnDropdownRef?.current?.contains(event.target)
    ) {
      setColumnDropdownOpen(false);
    }
    if (
      operatorDropdownRef?.current &&
      !operatorDropdownRef?.current?.contains(event.target)
    ) {
      setOperatorDropdownOpen(false);
    }
    if (
      valueDropdownRef?.current &&
      !valueDropdownRef?.current?.contains(event.target)
    ) {
      setValueDropdownOpen(false);
    }
  };
  const updateChartAxis = async (option) => {
    try {
      let data = [];
      let columnProp = [];
      let tableId;

      if (component?.properties?.chartType === "pie") {
        tableId = option?.slice?.length > 0 ? option?.slice[0]?.tableId : 'null'
      } else if (component?.properties?.chartType === 'funnel' || component?.properties?.chartType === 'big_number_trendline' || component?.properties?.chartType === 'gauge' || component?.properties?.chartType === 'big_number') {
        tableId = option?.dimensions?.length > 0 ? option?.dimensions[0]?.tableId : 'null'
      } else {
        tableId = option?.rowFields?.length > 0 ? option?.rowFields[0]?.tableId : option?.colFields?.length > 0 ? option?.colFields[0]?.tableId : option?.legends?.length > 0 ? option?.legends[0]?.tableId : 'null'
      }


      let result;
      const existingDrillData = option?.isDrill ? drillData : {
        level: 0,
        level1: { xAxis: "", yAxis: "" },
        level2: { xAxis: "", yAxis: "" },
        breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
      };
      const xVal = drillData?.level === 1 ? drillData?.breadcrumbs[1]?.xAxisValue : drillData?.breadcrumbs[2]?.xAxisValue;

      const yVal = drillData?.level === 1 ? drillData?.breadcrumbs[1]?.yAxisValue : drillData?.breadcrumbs[2]?.yAxisValue;

      const drilledX = drillData?.level === 1 ? component?.properties?.colFields[0] : drillData?.level1?.xAxis

      const drilledY = drillData?.level === 2 ? component?.properties?.rowFields[0] : drillData?.level1?.yAxis

      const matchedRecords = component?.properties?.data?.filter(record =>
        record[drilledX] === xVal && record[drilledY] === yVal
      );
      const matchDataForSec = component?.properties?.data?.filter(record =>
        record[component?.properties?.colFields[0]] === drillData?.breadcrumbs[1]?.xAxisValue && record[component?.properties?.yAxis] === drillData?.breadcrumbs[1]?.yAxisValue
      )
      const matchedRecordsSec = matchDataForSec?.filter(record =>
        record[drilledX] === xVal && record[drilledY] === yVal
      );
      const isDrilled = drillData?.level !== 0 ? true : false;

      const drilledData = drillData?.level == 1 ? matchedRecords : matchedRecordsSec;
      const colField = component?.properties?.colFields?.length > 0 && drillData?.level == 1 ? component?.properties?.colFields[1]?.columnName : component?.properties?.colFields[2]?.columnName
      if (
        component?.properties?.chartType === "bar" ||
        component?.properties?.chartType === "barWithLine" ||
        component?.properties?.chartType === "stacked" ||
        component?.properties?.chartType === "line" ||
        component?.properties?.chartType === "area" ||
        component?.properties?.chartType === "scatter"

      ) {
        if (component?.properties?.chartType !== 'barWithLine') {
          result = (option?.xAxis && option?.yAxis) && await generateChartData(
            isDrilled && option?.isDrill ? colField : option?.xAxis,
            option?.yAxis,
            option?.legends ? [option?.legends[0]?.columnName] : [component?.properties?.legends[0]?.columnName],
            null,
            option?.aggType ? option?.aggType : component?.properties?.aggType,
            tableId,
            component?.id,
            component?.properties?.chartType,
            component?.properties?.filters
          );
        } else {
          result = (option?.xAxis && option?.yAxis) && await generateChartData(
            isDrilled && option?.isDrill ? colField : option?.xAxis,
            option?.yAxis,
            option?.legends ? [option?.legends[0]?.columnName] : [component?.properties?.legends[0]?.columnName],
            option?.line?.column_name,
            option?.aggType ? option?.aggType : component?.properties?.aggType,
            tableId,
            component?.id,
            component?.properties?.chartType,
            component?.properties?.filters,
          );
        }

        const dataLength = result?.xValues?.length || 0;
        const zoomEnd = dataLength > 200 ? 2 : 50;
        const newOption = {
          ...component?.properties?.option,
          // series: result?.series,
          series: result?.series?.map((s) => ({
            ...s,
            label: { ...s.label, show: false }
          })),
          // xAxis: {
          //   ...component?.properties?.option?.xAxis,
          //   data: result?.xValues,
          // },
          xAxis: {
            ...component?.properties?.option?.xAxis,
            data: result?.xValues,
            // type: 'category',
            axisLabel: {
              ...component?.properties?.option?.xAxis?.axisLabel,
              formatter: (value) => {
                const colField = option?.colFields?.find(
                  (col) => col.columnName === option?.xAxis
                );
                const dateFormat = colField?.format || null;

                return dateFormat ? moment(value).format(dateFormat) : value;
              },
            },
          },
          // xAxis: {
          //   ...component?.properties?.option?.xAxis,
          //   data: result?.xValues?.map((value) => {
          //     const colField = component?.properties?.colFields?.find(
          //       (col) => col.columnName === option?.xAxis
          //     );
          //     const dateFormat = colField?.format || null;

          //     return dateFormat
          //       ? moment(value).format(dateFormat)
          //       : value;
          //   }),
          // },
          // yAxis: [
          //   {
          //     ...component?.properties?.option?.yAxis,
          //     type: "value",
          //     alignTicks: true,
          //     interval: result?.interval,
          //     name: component?.properties?.yAxis, // Label for bar axis
          //   },
          //   option?.line
          //     ? {
          //       type: "value",
          //       name: option?.line?.column_name, // Label for line axis
          //       position: 'right',
          //       alignTicks: true,
          //       axisLabel: { show: true }, // Show tick values
          //       splitLine: { show: true },
          //     }
          //     : null,
          // ].filter(Boolean),          
          tooltip: {
            ...component?.properties?.option?.tooltip,
            formatter: (params) => {
              const paramList = Array.isArray(params) ? params : [params];


              let rawLabel = paramList[0]?.axisValueLabel || paramList[0]?.name || "";
              let dateFormat =
                option?.colFields?.[0]?.format ||
                option?.rowFields?.[0]?.format ||
                option?.legends?.[0]?.format ||
                option?.line?.format || 
              
                null; // default fallback
// console.log(dateFormat,"dateformatt",rawLabel,"rawlabelll")
//                 if(!dateFormat && !isNaN(Date.parse(rawLabel))){
//                   rawLabel=moment(rawLabel).format("DD/MM/YYYY")
//                   dateFormat="DD/MM/YYYY"
//                 }
               
              const formattedLabel = dateFormat && moment(rawLabel,dateFormat,true).isValid()
                ? moment(rawLabel).format(dateFormat)
                : rawLabel;

              const content = paramList
                .map((param) => {
                  let maybeDate = param?.seriesName || param?.name || "";

                  
      
                  // Format only if it's a valid date
                  const formatted = dateFormat&& moment(maybeDate).isValid()
                    ? moment(maybeDate).format(dateFormat)
                    : maybeDate;

                  const value = param?.value;
                  const color = param?.color || '#000'; 

                  const colorDot = `<span style="display:inline-block;margin-right:4px;width:10px;height:10px;border-radius:50%;background-color:${color};"></span>`;
                  return `${colorDot}${formatted}: ${value}`;
                  // return `${formatted}: ${value}`;
                })
                .join("<br/>");

              return `<b>${formattedLabel}</b><br/>${content}`;
            },
          },

          yAxis: [
            ...component.properties.option.yAxis.map((item) => ({
              ...item,
              interval: result?.interval,
              // name: component?.properties?.yAxis,
            })),
            option?.line
              ? {
                type: "value",
                // name: option?.line?.column_name, // Label for line axis
                position: "right",
                alignTicks: true,
                axisLabel: { show: true }, // Show tick values
                splitLine: { show: true },
              }
              : null,
          ].filter(Boolean),

          // legend: {
          //   ...component?.properties?.option?.legend,
          //   data: result?.series?.map((series) => series.name),
          //   formatter: (name) => {
          //     const seriesItem = result?.series?.find((series) => series.name === name);
          //     const category = seriesItem?.category;
          //     const legendField = option?.legends?.find(
          //       (legend) => legend.columnName === category
          //     );
          //     const dateFormat = legendField?.format || option?.line?.format || null;
          //     return dateFormat ? moment(name).format(dateFormat) : name;
          //   },
          // },

          legend: {
            ...component?.properties?.option?.legend,
            data: result?.series?.map((series) => series.name),
            formatter: (name) => {
              const seriesItem = result?.series?.find((series) => series.name === name);
              const category = seriesItem?.category;
              // const legendField = option?.legends?.find(
              //   (legend) => legend.columnName === category
              // );
              const legendField =
                option?.legends?.find((legend) => legend.columnName === category) ||
                component?.properties?.legends?.find((legend) => legend.columnName === category);
              const dateFormat = legendField?.format || option?.line?.format || null;

              // Check if `name` is a valid date (non-strict) before formatting
              if (moment(name).isValid() && dateFormat) {
                return moment(name).format(dateFormat);
              }

              return name;
            },
          },


          dataZoom: [
            {
              ...component?.properties?.option?.dataZoom?.[0],
              type: "slider",
              start: 0,
              end: zoomEnd,
            },
            {
              type: "inside",
              start: 0,
              end: zoomEnd,
            },
          ],
        };
        onPropertyChange({
          option: newOption,
          data: data?.length > 0 ? data : component?.properties?.data,
          columnProperties: data?.length > 0 ? columnProp : component?.properties?.columnProperties,
          tableId: tableId,
          xAxis: isDrilled && option?.isDrill ? colField : option?.xAxis,
          yAxis: option?.yAxis,
          line: !option?.rowFields?.includes(option?.line) && !option?.colFields?.includes(option?.line) ? option?.line : null,
          zAxis: option?.zAxis,
          isLogScaleY: option?.isLogScaleY,
          isLogScaleZ: option?.isLogScaleZ,
          logColumns: {
            ...component?.properties?.logColumns,
            yAxis: option?.isLogScaleY ? `Log(${option?.yAxis})` : null,
            zAxis:
              option?.isLogScaleZ && option?.line == null
                ? `Log(${option?.zAxis[0]})`
                : option?.isLogScaleZ && option?.line !== null
                  ? `Log(${option?.line})`
                  : null,
          },
          rowFields: option?.rowFields,
          colFields: option?.colFields,
          drillData: existingDrillData,
          slice: option?.colFields,
          isFilter: false,
          legends: option?.legends ? option?.legends : component?.properties?.legends,
          aggType: option?.aggType ? option?.aggType : component?.properties?.aggType
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            data: data?.length > 0 ? data : prev?.properties?.data,
            columnProperties: data?.length > 0 ? columnProp : component?.properties?.columnProperties,
            tableId: tableId,
            option: newOption,
            xAxis: isDrilled && option?.isDrill ? colField : option?.xAxis,
            yAxis: option?.yAxis,
            line: !option?.rowFields?.includes(option?.line) && !option?.colFields?.includes(option?.line) ? option?.line : null,
            zAxis: option?.zAxis,
            rowFields: option?.rowFields,
            colFields: option?.colFields,
            slice: option?.colFields,
            // drillData: {
            //   level: 0,
            //   level1: { xAxis: "", yAxis: "" },
            //   level2: { xAxis: "", yAxis: "" },
            //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
            // }
            drillData: existingDrillData,
            isFilter: false,
            legends: option?.legends ? option?.legends : component?.properties?.legends,
            aggType: option?.aggType ? option?.aggType : component?.properties?.aggType
          },
        }));
        setIsAnimated(true);
      } else if (component?.properties?.chartType === 'big_number_trendline') {

        result = await generateAdvanceChartData({
          dimensions: option?.dimensions,
          tableId,
          componentId: component?.id,
          type: component?.properties?.chartType,
          timeGrain: option?.timeGrain ? option?.timeGrain : component?.properties?.timeGrain
        });

        const seriesData = result?.series?.[0]?.data || [];

        let latestValue = 0;
        let latestDate = null;

        if (seriesData.length > 0) {
          const latestDataPoint = seriesData[seriesData.length - 1]?.value;
          latestDate = new Date(latestDataPoint[0]).toISOString().split("T")[0] || null;
          latestValue = latestDataPoint[1] || 0;
        }

        // const latestDataPoint = result?.series[0]?.data[result?.series[0]?.data.length - 1]?.value;
        // const latestValue = latestDataPoint[1];
        // const latestDate = new Date(latestDataPoint[0]).toISOString().split("T")[0];
        const formatNumber = (num) => {
          if (num >= 1e9) return (num / 1e9).toFixed(1) + "B"; // Billion
          if (num >= 1e6) return (num / 1e6).toFixed(1) + "M"; // Million
          if (num >= 1e3) return (num / 1e3).toFixed(1) + "K"; // Thousand
          return num && num.toString();  // No formatting needed
        };

        // Format the latestValue
        const formattedValue = formatNumber(latestValue);


        const newOption = {
          ...component?.properties?.option,
          lineStyle: {
            ...component?.properties?.option?.lineStyle,
            width: 2,
            color: component?.properties?.option?.lineStyle?.color || "rgba(0, 122, 255, 1)"
          },
          areaStyle: {
            ...component?.properties?.option?.areaStyle,
            color: component?.properties?.option?.areaStyle?.color || "rgba(0, 122, 255, 1)"
          },
          series: result?.series,
          title: {
            ...component?.properties?.option?.title,
            text: seriesData.length > 0 ? formattedValue : "No Data",
            subtext: latestDate || "N/A",
            left: "left",
            top: "3%",
            // textStyle: {
            //   fontSize: 20,
            //   fontWeight: "bold",
            //   color: "#333" // Superset-style dark text
            // },
            // subtextStyle: {
            //   fontSize: 12,
            //   color: "#666"
            // }
          },
          xAxis: {
            type: "time", // ✅ Enables proper time formatting
            show: false, // ✅ Show X-axis to make data readable
            axisLabel: {
              ...component?.properties?.option?.xAxis?.axisLabel,
              formatter: "{yyyy}-{MM}-{dd}",

            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#ddd"
              }
            }
          },
          yAxis: {
            type: "value",
            show: false, // ✅ Show Y-axis for data clarity
            min: 0,
            axisLabel: {
              color: "#555"
            },
            splitLine: {
              show: false // Keeps UI clean
            }
          },
          legend: {
            show: false // ✅ Hides the legend
          },
          grid: {
            left: "5%",
            right: "5%",
            top: "45%",
            bottom: "5%"
          },
          tooltip: {
            trigger: "axis",
            axisPointer: { type: "line" },
            formatter: (params) => {
              const data = params?.[0];

              if (!data || !data.value || data.value.length < 2) {
                return "No data available"; // Handle missing data
              }

              const rawDate = data.value[0];
              const value = data.value[1];

              const date = new Date(rawDate);
              const formattedDate = isNaN(date.getTime()) ? "Invalid Date" : date.toISOString().split("T")[0];
              const formattedValue = isNaN(value) ? "N/A" : value.toLocaleString();

              return `${formattedDate}<br/><b>${formattedValue}</b>`;
            }
          }
        };
        onPropertyChange({
          option: newOption,
          tableId: tableId,
          aggType: option?.aggType ? option?.aggType : component?.properties?.aggType,
          dimensions: option?.dimensions,
          timeGrain: option?.timeGrain ? option?.timeGrain : component?.properties?.timeGrain
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            option: newOption,
            tableId: tableId,
            aggType: option?.aggType ? option?.aggType : component?.properties?.aggType,
            dimensions: option?.dimensions,
            timeGrain: option?.timeGrain ? option?.timeGrain : component?.properties?.timeGrain
          },
        }));
        setIsAnimated(true);
      }
      else if (component?.properties?.chartType === 'funnel' || component?.properties?.chartType === 'gauge') {

        result = await generateAdvanceChartData({
          dimensions: option?.dimensions,
          tableId,
          componentId: component?.id,
          type: component?.properties?.chartType
        });
        const formatValue = (value) => {
          const seriesItem = component?.properties?.chartType === 'gauge' ? result?.series[0][0]?.name : result?.series?.[0]?.name;
          const legendField = option?.dimensions?.find(
            (legend) => legend.columnName === seriesItem
          );
          const dateFormat = legendField?.format || null;
          return dateFormat ? moment(value).format(dateFormat) : value;
        };
        const newOption = {
          ...component?.properties?.option,
          // series: result?.series,
          series: component?.properties?.chartType === 'gauge' ? result?.series[0] : result?.series,
          // legend: {
          //   ...component?.properties?.option?.legend,
          //   data: component?.properties?.chartType === 'gauge' 
          //     ? [
          //         { name: "Common Axis", itemStyle: { color: "#ddd" } }, 
          //         ...result.series[0].map((s, i) => ({
          //           name: s.name,
          //           itemStyle: { color: colors[i-1] } 
          //         }))
          //       ]
          //     : result?.series[0]?.data?.map((series) => series.name)
          // },

          legend: {
            ...component?.properties?.option?.legend,
            data: component?.properties?.chartType === 'gauge' ? result?.series[0]?.slice(1)?.map((series) => series.name) : result?.series[0]?.data?.map((series) => series.name),
            // formatter: formatValue,
            formatter: (value) => {
              const seriesItem = result?.series?.[0]?.name;
              const legendField = option?.dimensions?.find(
                (legend) => legend.columnName === seriesItem
              );
              const dateFormat = legendField?.format || null;

              return dateFormat ? moment(value).format(dateFormat) : value;
            },
          },
          tooltip: {
            trigger: "item",
            formatter: (params) => {
              console.log(params, "params")
              const formattedName = formatValue(params.name);
              return `${formattedName}: ${params.value}`;

            },
          },

          label: {
            show: true,
            position: 'inside',
            formatter: (params) => formatValue(params.name),
          },
          xAxis: {
            ...component?.properties?.option?.xAxis,
            show: false,
          },
          yAxis: {
            ...component?.properties?.option?.yAxis,
            show: false,
          },
        };
        onPropertyChange({
          option: newOption,
          tableId: tableId,
          aggType: option?.aggType ? option?.aggType : component?.properties?.aggType,
          dimensions: option?.dimensions
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            option: newOption,
            tableId: tableId,
            aggType: option?.aggType ? option?.aggType : component?.properties?.aggType,
            dimensions: option?.dimensions
          },
        }));
        setIsAnimated(true);

      }
      else if (component?.properties?.chartType === 'big_number') {
        // const filterToAdd = option?.isFilterApply
        //   ? [
        //     ...component.properties.filters.filter(f => f.columnId !== option?.column?.column_id),
        //     {
        //       column: option?.column?.column_name,
        //       operator: option?.operator?.value,
        //       value: option?.values,
        //       columnId: option?.column?.column_id
        //     }
        //   ]
        //   : component?.properties?.filters;


        const filterToAdd = option?.isClear
          ? option.filters
          : option?.isFilterApply
            ? [
              ...component.properties.filters.filter(f => f.columnId !== option?.column?.column_id),
              {
                column: option?.column?.column_name,
                operator: option?.operator?.value,
                value: option?.values,
                columnId: option?.column?.column_id
              }
            ]
            : component?.properties?.filters;

        result = await generateAdvanceChartData({
          dimensions: option?.dimensions,
          tableId,
          componentId: component?.id,
          type: component?.properties?.chartType,
          filters: filterToAdd
        });
        onPropertyChange({
          bigValue: result?.value,
          tableId: tableId,
          aggType: option?.aggType ? option?.aggType : component?.properties?.aggType,
          dimensions: option?.dimensions,
          filters: filterToAdd
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            bigValue: result?.value,
            tableId: tableId,
            aggType: option?.aggType ? option?.aggType : component?.properties?.aggType,
            dimensions: option?.dimensions,
            filters: filterToAdd
          },
        }));
        setIsAnimated(true);

      }
      else if (component?.properties?.chartType === "double") {
        //  result = generateChartData( option?.xAxis, option?.yAxis, option?.zAxis, null, false);
        const result = await generateDoubleChartData(
          data?.length > 0 ? data : isDrilled && option?.isDrill ? drilledData : component?.properties?.data,
          //  isDrilled && option?.isDrill ? drilledData : component?.properties?.data,
          isDrilled && option?.isDrill ? colField : option?.xAxis,
          option?.yAxis,
          option?.zAxis ? option?.zAxis?.map(item => item?.column_name) : component?.properties?.zAxis?.map(item => item?.column_name),
          true,
          component?.id
        );
        const newOption = {
          ...component?.properties?.option,
          series: result?.series,
          xAxis: {
            ...component?.properties?.option?.xAxis,
            data: result?.xValues,
          },
          yAxis: {
            ...component?.properties?.option?.yAxis,
            interval: result?.interval,
          },
          legend: { data: result?.series?.map((series) => series.name) },
        };
        onPropertyChange({
          option: newOption,
          tableId: data?.length > 0 ? tableId : component?.properties?.tableId,
          data: data?.length > 0 ? data : component?.properties?.data,
          columnProperties: data?.length > 0 ? columnProp : component?.properties?.columnProperties,
          xAxis: isDrilled && option?.isDrill ? colField : option?.xAxis,
          yAxis: option?.yAxis,
          // line: option?.line,
          zAxis: option?.zAxis,
          rowFields: option?.rowFields,
          colFields: option?.colFields,
          // drillData: {
          //   level: 0,
          //   level1: { xAxis: "", yAxis: "" },
          //   level2: { xAxis: "", yAxis: "" },
          //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
          // }
          drillData: existingDrillData,
          isFilter: false
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            tableId: data?.length > 0 ? tableId : component?.properties?.tableId,
            data: data?.length > 0 ? data : component?.properties?.data,
            columnProperties: data?.length > 0 ? columnProp : component?.properties?.columnProperties,
            option: newOption,
            xAxis: isDrilled && option?.isDrill ? colField : option?.xAxis,
            yAxis: option?.yAxis,
            //  line: option?.line,
            zAxis: option?.zAxis,
            rowFields: option?.rowFields,
            colFields: option?.colFields,
            drillData: existingDrillData,
            isFilter: false
          },
        }));
        // echartsRef.current.getEchartsInstance().setOption(newOption, true);
        setIsAnimated(true);
      } else {
        let result = await generatePieChartData(
          option?.slice[0]?.columnName ? option?.slice[0]?.columnName : component?.properties?.slice[0]?.columnName,
          option?.aggType ? option?.aggType : component?.properties?.aggType,
          tableId,
          component?.id,
          component?.properties?.chartType, component?.properties?.filters,);
        const newOption = {
          ...component?.properties?.option,
          series: result?.series,
          xAxis: {
            ...component?.properties?.option?.xAxis,
            show: false,
          },
          yAxis: {
            ...component?.properties?.option?.yAxis,
            show: false,
          },
          // xAxis: {
          //     ...component?.properties?.option?.xAxis,
          //     data: result?.xValues
          // },
          // legend: { data: result?.series[0]?.data?.map((item) => item?.name) },
          legend: {
            ...component?.properties?.option?.legend,
            data: result?.series[0]?.data?.map((item) => item?.name),
            formatter: (name) => {
              const seriesItem = result?.series[0]?.name;

              const legendField = option?.slice?.find(
                (col) => col.columnName === seriesItem
              );
              const dateFormat = legendField?.format || null;
              return dateFormat ? moment(name).format(dateFormat) : name;
            },
          },
          tooltip: {
            trigger: "item",
            enterable: true,
            textStyle: {
              fontSize: 12,
            },
            // formatter: (params) => {
            //   return `<b>${params.name}</b>: ${params.value} `;
            // },
            formatter: (params) => {
              const seriesItem = result?.series[0]?.name;

              const legendField = option?.slice?.find(
                (col) => col.columnName === seriesItem
              );

              const dateFormat = legendField?.format || null;
              const formattedName = dateFormat ? moment(params.name).format(dateFormat) : params.name;

              return `<b>${formattedName}</b>: ${params.value} `;
            },
          },
        };
        delete newOption.dataZoom;

        onPropertyChange({
          option: newOption,
          tableId: tableId,
          data: data?.length > 0 ? data : component?.properties?.data,
          columnProperties: data?.length > 0 ? columnProp : component?.properties?.columnProperties,
          chartType: "pie",
          //   xAxis: option?.xAxis,
          slice: option?.slice,
          pieDrillData: {
            level: 0,
            level1: { slice: "" },
            level2: { slice: "" },
            breadcrumbs: [{ level: 0, sliceValue: "Main" }]
          },
          drillData: existingDrillData,
          colFields: option?.slice,
          isFilter: false,
          aggType: option?.aggType ? option?.aggType : component?.properties?.aggType
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            tableId: tableId,
            data: data?.length > 0 ? data : component?.properties?.data,
            columnProperties: data?.length > 0 ? columnProp : component?.properties?.columnProperties,
            option: newOption,
            chartType: "pie",
            // xAxis: option?.xAxis,
            slice: option?.slice,
            pieDrillData: {
              level: 0,
              level1: { slice: "" },
              level2: { slice: "" },
              breadcrumbs: [{ level: 0, sliceValue: "Main" }]
            },
            drillData: existingDrillData,
            colFields: option?.slice,
            isFilter: false,
            aggType: option?.aggType ? option?.aggType : component?.properties?.aggType
          },
        }));
        setIsAnimated(true);

      }
      setFlag(true)
    } catch (err) {
      console.log(err, "Error")
    }
  };
  // const handleColorPickerToggle = (itemName) => {
  //   setColorPickerState((prev) => ({
  //     ...prev,
  //     [itemName]: !prev[itemName],
  //   }));
  // };

  const handleColorPickerToggle = (itemName) => {
    setColorPickerState((prev) => ({
      [itemName]: !prev[itemName], // Only keep this item open
    }));
  };

  const handleCheckboxChange = (item) => {
    // Check if the item is already in the array
    const itemIndex = component?.properties?.yAxis.indexOf(item);

    if (itemIndex === -1) {
      // Item is not in the array, add it
      const newValues = [...component.properties.yAxis, item];
      setCheckedItems(newValues);
      updateChartAxis({
        yAxis: newValues,
        xAxis: component?.properties?.xAxis,
        line: component?.properties?.line
      });
    } else {
      // Item is in the array, remove it
      const newValues = component?.properties?.yAxis?.filter(
        (value) => value !== item
      );
      setCheckedItems(newValues);
      updateChartAxis({
        yAxis: newValues,
        xAxis: component?.properties?.xAxis,
        line: component?.properties?.line,
      });
    }
  };
  const handleCheckboxChangeLog = (item) => {
    const itemIndex = component?.properties?.logColumns?.double?.indexOf(item);
    if (itemIndex === -1) {
      const newValues = [...component.properties.logColumns.double, item];
      setCheckedItems(newValues);
      updateChartAxis({
        zAxis: component?.properties?.zAxis,
        yAxis: "",
        xAxis: component?.properties?.xAxis,
        line: component?.properties?.line,
        logColumns: newValues,
      });
    } else {
      const newValues = component?.properties?.logColumns?.double?.filter(
        (value) => value !== item
      );
      setCheckedItems(newValues);
      updateChartAxis({
        zAxis: component?.properties?.zAxis,
        xAxis: component?.properties?.xAxis,
        yAxis: "",
        line: component?.properties?.line,
        logColumns: newValues,
      });
    }
    setIsAnimated(true);
  };
  const handleCheckboxChangeZAxis = (item) => {
    const itemIndex = component?.properties?.zAxis.indexOf(item);
    if (itemIndex === -1) {
      const newValues = [...component.properties.zAxis, item];
      setCheckedItems(newValues);
      updateChartAxis({
        zAxis: newValues,
        yAxis: "",
        xAxis: component?.properties?.xAxis,
        line: component?.properties?.line,
        logColumns: component?.properties?.logColumns?.double,
      });
    } else {
      const newValues = component?.properties?.zAxis?.filter(
        (value) => value !== item
      );
      setCheckedItems(newValues);
      updateChartAxis({
        zAxis: newValues,
        xAxis: component?.properties?.xAxis,
        yAxis: "",
        line: component?.properties?.line,
        logColumns: component?.properties?.logColumns?.double?.filter(
          (value) => value !== `Log(${item})`
        ),
      });
    }
    setIsAnimated(true);
  };
  const handleLineChange = (e) => {
    const newValue = e.target.value === "none" ? null : e.target.value;
    const columnMetaData = tables?.find((item) => item?.tableMetaData?.table_id === component?.properties?.tableId)?.columnMetaData
    const line = columnMetaData?.find((item) => item?.column_id === newValue)
    setSelectedLine(line);

    // updateChartAxis({
    //   ...component.properties,
    //   line: newValue,
    //   yAxis: component?.properties?.rowFields[0],
    //   xAxis: component?.properties?.colFields[0],
    //   zAxis: [],
    // });
    updateChartAxis({
      yAxis: component?.properties?.rowFields[0]?.columnName,
      xAxis: component?.properties?.colFields[0]?.columnName,
      zAxis: component?.properties?.zAxis,
      line: line,
      rowFields: component?.properties?.rowFields,
      colFields: component?.properties?.colFields,
      isDrill: true
    });

  };

  const handleTempXAxisChange = (e) => {
    const newValue = e.target.value === "none" ? null : e.target.value;
    // const columnMetaData = tables?.find((item) => item?.tableMetaData?.table_id === component?.properties?.tableId)?.columnMetaData
    // const tempoXAxis = columnMetaData?.find((item) => item?.column_id === newValue)
    // setSelectedLine(tempoXAxis);

    // updateChartAxis({
    //   ...component.properties,
    //   line: newValue,
    //   yAxis: component?.properties?.rowFields[0],
    //   xAxis: component?.properties?.colFields[0],
    //   zAxis: [],
    // });
    updateChartAxis({
      dimensions: component?.properties?.dimensions,
      timeGrain: newValue
    });

  };

  const displayedOptions = tables?.find((item) => item?.tableMetaData?.table_id == component?.properties?.tableId)?.columnMetaData || [];
  // console.log(displayedOptions,"displayoptionsss")
  //   if (selectedLine && selectedLine !== "none" && !displayedOptions?.includes(selectedLine)) {
  //     displayedOptions?.push(selectedLine); // Add selected value back to display list if missing
  //   }

  // let updatedOptions = [...displayedOptions];
  // if (selectedLine && selectedLine !== "none" && !updatedOptions.some(item => item.column_id === selectedLine.column_id)) {
  //   updatedOptions.push(selectedLine);
  // }
  // Get the list of column IDs that should be excluded
  const excludedColumnIds = new Set([
    ...component?.properties?.rowFields?.map((field) => field.columnId) || [],
    ...component?.properties?.legends?.map((legend) => legend.columnId) || [],
    ...component?.properties?.colFields?.map((colField) => colField.columnId) || []
  ]);

  // Filter out excluded columns from displayedOptions
  const filteredOptions = displayedOptions.filter((item) => !excludedColumnIds.has(item.column_id));

  let updatedOptions = [...filteredOptions];
  if (
    selectedLine &&
    selectedLine !== "none" &&
    !updatedOptions.some((item) => item.column_id === selectedLine.column_id)
  ) {
    updatedOptions.push(selectedLine);
  }

  const Fonts = [
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Times",
    "Courier New",
    "Courier",
    "Verdana",
    "Georgia",
    "Palatino",
    "Garamond",
    "Bookman",
    "Trebuchet MS",
    "Comic Sans MS",
    "Impac",
  ];
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const [columnToFilter, setColumnToFilter] = React.useState({ name: "", type: "" });
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectAll, setSelectAll] = React.useState(false);
  const [filterObj, setFilterObj] = React.useState(null);
  const [filterData] = useFilterTableDataMutation();
  const [rangeValue, setRangeValue] = React.useState(0);
  const dropdownRef = React.useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState(columnToFilter?.colData || []);
  const [dateRange, setDateRange] = React.useState([null, null]);

  const stringTypeConditions = [
    { value: "CONTAINS", label: "Contains" },
    { value: "BEGIN_WITH", label: "Begin with" },
    { value: "MATCHES", label: "Matches" },
    { value: "ENDS_WITH", label: "Ends with" },
    { value: "DOES_NOT_CONTAINS", label: 'Does not contain' },
    { value: "DOES_NOT_BEGIN_WITH", label: "Does not begin with" },
    { value: "DOES_NOT_MATCHES", label: "Does not match" },
    { value: "DOES_NOT_ENDS_WITH", label: "Does not end with" }
  ]
  const numericTypeConditions = [
    { value: "EQUALS_TO", label: "Equals to" },
    { value: "GREATER_THAN", label: "Greater than" },
    { value: "LESS_THAN", label: "Less than" },
    { value: "GREATER_THAN_EQUAL", label: "Greater than equal" },
    { value: "LESS_THAN_EQUAL", label: "Less than equal" },
    { value: "DOES_NOT_EQUAL", label: "Does not equal" }
  ]

  const handleToggleDropdown = (event) => {
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setFilterObj({ ...filterObj, values: filteredItems });
    } else {
      setFilterObj({ ...filterObj, values: [] });
    }
  };
  function processData(data, selectedColumns) {
    const treeStructure = {};
    data.forEach(row => {
      let currentLevel = treeStructure;
      selectedColumns.forEach((column, index) => {
        const columnValue = row[column.columnToFilter];
        if (index === selectedColumns.length - 1) {
          currentLevel[columnValue] = (currentLevel[columnValue] || 0) + 1;
        } else {
          if (!currentLevel[columnValue]) {
            currentLevel[columnValue] = {};
          }
          currentLevel = currentLevel[columnValue];
        }
      });
    });
    return treeStructure;
  }
  const handleSelectColumnToFilter = async (value) => {
    await clearFilter();
    const colType = component?.properties?.columnProperties?.find((item) => item?.name === value)?.type;
    const colData = component?.properties?.data?.map((item) => item[value]);
    value == -1 ?
      setColumnToFilter({ name: null, type: null, min: 0, max: 0, colData: [...new Set(colData)] }) : setColumnToFilter({ name: value, type: colType, min: 0, max: 0, colData: [...new Set(colData)] });
    setFilterObj({ columnName: value, columnType: colType, condition: null, conditionValue: "", min: null, max: null, from: null, to: null, values: [] });
    if (colType == "INTEGER") {
      const col = component?.properties?.data?.map(item => parseInt(item[value]));
      const min = Math.min(...col);
      const max = Math.max(...col);
      min === max ? setRangeValue(max) : setRangeValue(min);
      setColumnToFilter({
        name: value,
        type: colType,
        min: min,
        max: max,
        colData: [...new Set(colData)]
      });
      setFilterObj({
        columnName: value,
        columnType: "INT",
        condition: null,
        conditionValue: "",
        min: min,
        max: max,
        from: null,
        to: null,
        values: []
      });
    }
  }
  const clearFilter = async () => {
    if (filterObj?.columnName !== undefined) {
      try {
        const result = await filterData({ ...filterObj, columnName: filterObj?.columnName, columnType: "VARCHAR", condition: null, conditionValue: null, values: [], tableName: component?.properties?.tableName }).unwrap();
        onPropertyChange({
          data: result?.data,
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            data: result?.data,
          },
        }));
        // Object?.entries(component?.properties?.drilledData).length > 0 ? setDrillDownData(component?.properties?.drilledColumns, result?.data) : ""
        setFlag(false);
      } catch (err) {
        showAlert(err?.data?.message, "error")
      }
    }
    setFilterObj(null);
    setColumnToFilter(null);
    setSearchQuery("");
    setSelectAll(false);
  }
  const handleFilterCheckboxChange = (item) => {
    if (filterObj?.values?.includes(item)) {
      setFilterObj({
        ...filterObj,
        values: filterObj?.values?.filter((val) => val !== item),
      });
    } else {
      setFilterObj({ ...filterObj, values: [...filterObj.values, item] });
    }
  };
  const calculateFillPercentage = () => {
    const min = columnToFilter?.min ?? 0;
    const max = columnToFilter?.max ?? 100;
    const percentage = ((rangeValue - min) / (max - min)) * 100;

    return `${isNaN(percentage) ? 100 : percentage}%`;
  };
  const handleRangeChange = (event) => {
    const value = event.target.value;
    setRangeValue(value);
    // Update state for the range value
    setFilterObj({ ...filterObj, max: Number(value) })
  };
  const handleTabChange = (event, newValue) => {
    onPropertyChange({ tableView: newValue });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        tableView: newValue
      },
    }));
    setFlag(false);
  }

  const handleDropToRow = (item) => {
    // Get the current row field
    // Allow up to 3 fields in colFields
    // if (component?.properties?.colFields?.length <= 1) {
    //   showAlert("The Column Field must contain at least one column.", "info")
    //   return;
    // }
    // component?.properties?.tableId && component?.properties?.data?.length > 0 && component?.properties?.tableId !== table?.tableMetaData?.table_id

    const currentRowField = component?.properties?.rowFields[0] ? component?.properties?.rowFields[0] : {};
    // If there is a current row field, add it back to selectedCols
    if (currentRowField) {
      setSelectedCols((prev) => [...prev, currentRowField]);
    }
    // Allow only one field in rowFields, replacing the existing one
    const newRowFields = [item];
    const updatedColFields = component?.properties?.colFields?.filter(col => col?.columnId !== item.columnId);

    setSelectedCols((prev) => prev?.filter((col) => col?.columnName !== item.column));
    onPropertyChange({
      rowFields: newRowFields,
      colFields: updatedColFields,
    });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        rowFields: newRowFields,
        colFields: updatedColFields,
      },
    }));
    setFlag(false);
    updateChartAxis({
      yAxis: newRowFields[0]?.columnName,
      xAxis: updatedColFields[0]?.columnName,
      zAxis: component?.properties?.zAxis?.filter((i) => i !== item?.column),
      // legends: component?.properties?.legends,
      legends: component?.properties?.legends?.length <= 0 ? [item] : component?.properties?.legends,
      rowFields: newRowFields,
      colFields: updatedColFields
    })
  };
  const handleDropToLegend = (item) => {
    // if (item.column === component?.properties?.rowFields[0]) {
    //   showAlert("The Row Field must contain at least one column.", "info")
    //   return;
    // }

    const updatedRowFields = component?.properties?.rowFields?.filter(row => row?.columnId !== item?.columnId);
    const newLegends = [item];

    onPropertyChange({ legends: newLegends });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        legends: newLegends
      },
    }));
    setFlag(false);
    updateChartAxis({
      yAxis: component?.properties?.rowFields[0]?.columnName,
      xAxis: component?.properties?.colFields[0]?.columnName,
      //  zAxis: newLegends?.map((item) => item?.columnName),
      legends: newLegends,
      rowFields: component?.properties?.rowFields,
      colFields: component?.properties?.colFields
    })
  };
  const handleDropToCol = (item) => {
    if (component?.properties?.colFields.length < 3) {
      if (
        !component?.properties?.colFields.some((col) => col.columnId === item.columnId)// Prevent duplicates

      ) {
        const updatedRowFields = component?.properties?.rowFields?.filter(row => row?.columnId !== item?.columnId);
        const newColFields = [...component.properties.colFields, item];

        setSelectedCols((prev) => prev?.filter((col) => col?.columnName !== item.column));
        onPropertyChange({ rowFields: updatedRowFields, colFields: newColFields });

        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            rowFields: updatedRowFields,
            colFields: newColFields,
          },
        }));

        setFlag(false);

        updateChartAxis({
          yAxis: updatedRowFields[0]?.columnName,
          xAxis: newColFields[0]?.columnName,
          zAxis: component?.properties?.zAxis?.filter((i) => i !== item?.column),
          // legends: component?.properties?.legends?.length <= 0 ? [item] : component?.properties?.legends,
          legends: component?.properties?.legends,
          rowFields: updatedRowFields,
          colFields: newColFields
        });
      } else {
        // Show an alert if item is already present in colFields
        showAlert("This column is already added!", "warning");
      }
    }
  };

  const handleDropToColForPieChart = (item) => {
    if (component?.properties?.colFields.length < 3) {
      if (
        !component?.properties?.slice.some((sliceItem) => sliceItem.columnId === item.columnId) && // Prevent duplicates
        component?.properties?.slice.length < 3
      ) {
        const updatedSlice = [...component.properties.slice, item];

        setSelectedCols((prev) => prev?.filter((col) => col?.columnName !== item.column));
        onPropertyChange({ slice: updatedSlice });

        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            slice: updatedSlice,
          },
        }));

        updateChartAxis({
          slice: updatedSlice,
        });

        setFlag(false);
      } else {
        // Show an alert if item is already present in slice
        showAlert("This column is already added!", "warning");
      }
    }
  };

  // const handleDropToDimensions = (item) => {
  //   // if (component?.properties?.colFields.length < 3) {
  //   if (component?.properties?.chartType === 'big_number_trendline') {
  //     console.log(item, ">>> Item >>>>>");
  //     const updatedSlice = [item];

  //     updateChartAxis({
  //       dimensions: updatedSlice,
  //     });
  //     setFlag(false);

  //   } else {
  //     if (
  //       !component?.properties?.dimensions?.some((sliceItem) => sliceItem.columnId === item.columnId)
  //     ) {
  //       const updatedSlice = [...component.properties.dimensions, item];
  //       updateChartAxis({
  //         dimensions: updatedSlice,
  //       });

  //       setFlag(false);
  //     } else {
  //       // Show an alert if item is already present in slice
  //       showAlert("This column is already added!", "warning");
  //     }
  //   }
  //   // }
  // }

  const handleDropToDimensions = (item) => {
    if (component?.properties?.chartType === 'big_number_trendline') {
      if (item.type === "timestamp with time zone" || item.type === "date") {
        const updatedSlice = [item];

        updateChartAxis({
          dimensions: updatedSlice,
        });
        setFlag(false);
      } else {
        showAlert("Only 'date' columns can be used for this widget!", "info");
      }
    } else {
      if (
        !component?.properties?.dimensions?.some((sliceItem) => sliceItem.columnId === item.columnId)
      ) {
        const updatedSlice = [...component.properties.dimensions, item];
        updateChartAxis({
          dimensions: updatedSlice,
        });

        setFlag(false);
      } else {
        // Show an alert if item is already present in slice
        showAlert("This column is already added!", "warning");
      }
    }
  };
  const removeColFieldForPieChart = (column) => {
    // Remove the specified column from the slice array
    const updatedSlice = component?.properties?.slice?.filter((col) => col !== column);

    // Update the component state and properties
    setSelectedCols([...selectedCols, column]);
    onPropertyChange({ slice: updatedSlice });

    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        slice: updatedSlice,
      },
    }));

    // Update the pie chart using the updated slice
    updateChartAxis({
      //xAxis: updatedSlice[0], // Use the first element in slice for xAxis
      slice: updatedSlice,
    });

    setFlag(true);
  };

  const removeDimensions = (column) => {
    // Remove the specified column from the slice array
    const updatedSlice = component?.properties?.dimensions?.filter((col) => col !== column);



    // Update the pie chart using the updated slice
    updateChartAxis({
      //xAxis: updatedSlice[0], // Use the first element in slice for xAxis
      dimensions: updatedSlice,
    });

    setFlag(true);
    setSelectedColumn(null)
    setSelectedOperator(null);
    setSelectedValues([]);
  };
  const removeSelectedColumn = (column) => {
    setSelectedCols((prev) => prev?.filter((col) => col !== column));
  };
  const removeRowField = (column) => {
    const updatedRowFields = component?.properties?.rowFields?.filter(row => row?.columnId !== column?.columnId);
    setRowFields((prev) => prev?.filter((col) => col?.columnId !== column?.columnId));
    setSelectedCols([...selectedCols, column])
    // onPropertyChange({ rowFields: updatedRowFields, });
    // setSelectedComponent((prev) => ({
    //   ...prev,
    //   properties: {
    //     ...prev.properties,
    //     rowFields: updatedRowFields,
    //   },
    // }));
    setFlag(false);
    updateChartAxis({
      yAxis: updatedRowFields[0]?.columnName,
      xAxis: component?.properties?.colFields[0]?.columnName,
      zAxis: component?.properties?.zAxis,
      legends: component?.properties?.legends,
      rowFields: updatedRowFields,
      colFields: component?.properties?.colFields
    })
  };
  const removeColField = (column) => {
    // if (component?.properties?.colFields?.length <= 1) {
    //   showAlert("The Column Field must contain at least one column.", "info")
    //   return;
    // }
    const updatedColFields = component?.properties?.colFields?.filter(col => col?.columnId !== column?.columnId);
    let newLineValue = component?.properties?.line;

    if (component?.properties?.line === column) {
      // If the line matches the removed column, set it to "none"
      newLineValue = "none";
    } else if (updatedColFields.length > 0 && updatedColFields[0] === newLineValue) {
      // If the first column matches the current line after removal, set it to "none"
      newLineValue = "none";
    }
    setSelectedCols([...selectedCols, column])
    setColFields((prev) => prev?.filter((col) => col?.columnId !== column?.columnId));
    // onPropertyChange({ colFields: updatedColFields, line: newLineValue });
    // setSelectedComponent((prev) => ({
    //   ...prev,
    //   properties: {
    //     ...prev.properties,
    //     colFields: updatedColFields,
    //     line: newLineValue
    //   },
    // }));
    setFlag(false);
    updateChartAxis({
      yAxis: component?.properties?.rowFields[0]?.columnName,
      xAxis: updatedColFields[0]?.columnName,
      zAxis: component?.properties?.zAxis,
      legends: component?.properties?.legends,
      rowFields: component?.properties?.rowFields,
      colFields: updatedColFields,
      line: newLineValue === "none" ? null : newLineValue,
    })
  };
  const removeLegends = (column) => {
    const updatedLegends = component?.properties?.legends?.filter(row => row?.columnId !== column?.columnId);


    onPropertyChange({ legends: updatedLegends, });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        legends: updatedLegends,
      },
    }));
    setFlag(false);
    updateChartAxis({
      yAxis: component?.properties?.rowFields[0]?.columnName,
      xAxis: component?.properties?.colFields[0]?.columnName,
      zAxis: component?.properties?.zAxis,
      legends: updatedLegends,
      rowFields: component?.properties?.rowFields,
      colFields: component?.properties?.colFields
    })
  };

  const handleColumnSelect = (item) => {
    const isExist = component?.properties?.zAxis?.some((col) => col?.column_id === item?.column_id);
    if (!isExist) {
      // setSelectedCols(selectedColumn?.filter((col) => col !== item));
      const newZAxis = [...(component.properties.zAxis || []), item]
      updateChartAxis({
        yAxis: component?.properties?.rowFields[0]?.columnName,
        xAxis: component?.properties?.colFields[0]?.columnName,
        zAxis: newZAxis,
        line: null,
        rowFields: component?.properties?.rowFields,
        colFields: component?.properties?.colFields,
        isDrill: true
      });
    } else {
      // setSelectedCols([...selectedColumn, item]);
      const newZAxis = component?.properties?.zAxis?.filter((i) => i?.column_id !== item?.column_id)
      updateChartAxis({
        yAxis: component?.properties?.rowFields[0]?.columnName,
        xAxis: component?.properties?.colFields[0]?.columnName,
        zAxis: newZAxis,
        line: null,
        rowFields: component?.properties?.rowFields,
        colFields: component?.properties?.colFields,
        drillData: component?.properties?.drillData
      });
    }
  };
  const handleFilterApply = async () => {
    updateChartAxis({
      dimensions: component?.properties?.dimensions,
      column: selectedColumn,
      operator: selectedOperator,
      values: (selectedOperator?.value === 'IN' || selectedOperator?.value === 'NOT_IN') ? selectedValues : selectedValues[0],
      isFilterApply: true
    })
    setSelectedColumn(null)
    setSelectedOperator(null);
    setSelectedValues([]);
  }
  const handleRemoveFilter = (columnId) => {
    const updatedFilters = component.properties.filters.filter(filter => filter.columnId !== columnId);
    updateChartAxis({ ...component.properties, isClear: true, filters: updatedFilters });
  };


  React.useEffect(() => {
    if (flag) {
      // setDeselectedColumns([]);
      //  setSelectedColumn(columnsMe);
      const columns = columnsMe?.filter((item) => !component?.properties?.rowFields?.includes(item) && !component?.properties?.colFields?.includes(item));
      const colForPie = columnsMe?.filter((item) => !component?.properties?.slice?.includes(item));
      component?.properties?.chartType === 'pie' ? setSelectedCols(colForPie) : setSelectedCols(columns);
      setRowFields([]);
      setColFields([]);
    }
  }, [component]);
  React.useEffect(() => {
    if (searchQuery === '') {
      setFilteredItems(columnToFilter?.colData || []);
    } else {
      setFilteredItems(
        columnToFilter?.colData?.filter((item) => {
          return item.toString().toLowerCase().includes(searchQuery.toLowerCase());
        }) || []
      );
    }
  }, [searchQuery, columnToFilter]);

  return (
    <>
      <div>
        <span className="flex items-center gap-2 px-3 pt-3 pb-2">
          <p className="font-semibold text-[12px]  text-gray-500 m-0">Title:</p>
          <input
            type="text"
            className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-full"
            value={component?.properties?.name || ""}
            onChange={(e) => {
              const newValue = e.target.value;
              onPropertyChange({ name: newValue });
              setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                  ...prev.properties,
                  name: newValue,
                },
              }));
              setFlag(false);
              setIsAnimated(false);
            }}
          />
        </span>
        <span
        // className="pivottab"
        >
          <div className="flex flex-col items-start justify-start w-full gap-2 pt-1 pb-2 px-3">
            {component?.properties?.chartType !== "pie"
              && component?.properties?.chartType !== "funnel"
              && component?.properties?.chartType !== 'big_number_trendline'
              && component?.properties?.chartType !== 'gauge'
              && component?.properties?.chartType !== 'big_number'
              && (
                <>
                  <span className="w-full">
                    <DropZone accept="COLUMN"
                      onDrop={handleDropToCol}
                      // title={component?.properties?.chartType === 'pie' ? 'Slice:' : 'Column Field:'}
                      title="X-axis"
                    >
                      {component?.properties?.colFields?.map((item, index) => (
                        <Column key={item} item={item} index={index} removeColumn={() => removeColField(item)} showCloseIcon={true} />
                      ))}
                    </DropZone>
                  </span>
                  <span className="w-full">
                    <DropZone accept="COLUMN" onDrop={handleDropToRow}
                      title="Y-axis"
                    >
                      {component?.properties?.rowFields?.map((item, index) => (
                        <Column
                          key={item}
                          item={item}
                          index={index}
                          removeColumn={() => removeRowField(item)}
                          showCloseIcon={true}
                        />
                      ))}
                    </DropZone>
                  </span>
                  <span className="w-full">
                    <DropZone accept="COLUMN"
                      onDrop={handleDropToLegend}
                      // title={component?.properties?.chartType === 'pie' ? 'Slice:' : 'Column Field:'}
                      title="Legends"
                    >
                      {component?.properties?.legends?.map((item, index) => (
                        <Column key={item} item={item} index={index} removeColumn={() => removeLegends(item)} showCloseIcon={true} />
                      ))}
                    </DropZone>
                  </span>

                </>
              )}
            {component?.properties?.chartType === "pie" && (
              <span className="w-full">
                <DropZone accept="COLUMN" onDrop={handleDropToColForPieChart}
                  title="Slice:"
                >
                  {/* {component?.properties?.slice?.map((item, index) => (
                      <Column key={item} item={item} index={index} removeColumn={() => removeColFieldForPieChart(item)} showCloseIcon={true} />
                    ))} */}
                  {component?.properties?.slice?.map((item, index) => {
                    return (
                      <Column
                        key={item}
                        item={item}
                        index={index}
                        removeColumn={() => removeColFieldForPieChart(item)}
                        showCloseIcon={true}
                      />
                    );
                  })}
                </DropZone>
              </span>
            )}
            {(component?.properties?.chartType === 'funnel' ||
              component?.properties?.chartType === 'big_number_trendline' ||
              component?.properties?.chartType === 'gauge' ||
              component?.properties?.chartType === 'big_number'
            ) && (
                <span className={`w-full ${component?.properties?.chartType === "big_number" ? "mt-2" : ""}`}>
                  <DropZone accept="COLUMN" onDrop={handleDropToDimensions}
                    title="Dimensions:"
                  >
                    {/* {component?.properties?.slice?.map((item, index) => (
                     <Column key={item} item={item} index={index} removeColumn={() => removeColFieldForPieChart(item)} showCloseIcon={true} />
                   ))} */}
                    {component?.properties?.chartType === 'big_number_trendline' && component?.properties?.dimensions?.length <= 0 && (
                      <span className="text-gray-400 text-xs mb-1 mt-1 text-center">
                        Only 'date' columns can be used for this widget!
                      </span>
                    )}
                    {component?.properties?.dimensions?.map((item, index) => {
                      return (
                        <Column
                          key={item}
                          item={item}
                          index={index}
                          removeColumn={() => removeDimensions(item)}
                          showCloseIcon={true}
                        />
                      );
                    })}
                  </DropZone>
                </span>
              )}
          </div>
          <div className="flex flex-col ">
            {component?.properties?.chartType === "big_number_trendline" && (
              <span className=" d-flex pb-2 px-3 gap-2 ml-[1px]">
                <p className="m-0  whitespace-nowrap font-semibold text-[12px]  text-gray-500  self-center">
                  Time Grain:
                </p>
                <select
                  // value={component?.properties?.line?.columnName || 'none'}
                  value={component?.properties?.timeGrain || 'none'}
                  onChange={handleTempXAxisChange}
                  className={`block px-0.5 py-0.5 sidebar-section-text bg-white  cursor-pointer input-border-custome rounded-[5px] w-[110px]
                    `}
                // disabled={component?.properties?.rowFields?.length <= 0 || component?.properties?.colFields?.length <= 0}
                >

                  {timeGrains?.map((item, index) => (
                    <option key={index} value={item?.value}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </span>
            )}
            {component?.properties?.chartType === 'big_number' && (
              <span className="w-full flex flex-col mx-3 mb-2">
                <p className="prop-label m-0">Filters:</p>
                <div
                  className={`flex flex-wrap p-1 overflow-auto custom-scrollbar min-h-12 max-h-14 input-border-custome rounded w-[calc(100%-1.8rem)]`}
                >
                  {component?.properties?.filters?.map((item) => (
                    <div
                      key={item?.column}
                      className={`relative pl-0.5 pr-0.5 text-[#374151] text-[10px] bg-white input-border-custome h-fit w-fit rounded-md flex items-center justify-between mb-0.5 ml-0.5 `}
                      style={{ minWidth: '50px', fontSize: " 0.75rem", borderRadius: "5px" }}
                    >
                      {item?.column}
                      <button className="ml-2 text-gray-400 hover:text-red-600"
                        onClick={() => handleRemoveFilter(item.columnId)}
                      >
                        <CloseIcon className="text-sm text-gray-400" />
                      </button>
                    </div>
                  ))}
                </div>
              </span>
            )}

            {component?.properties?.chartType === 'big_number' && (
              <div className="w-full space-y-3">
                <div
                  className={`relative w-full px-3 ${component?.properties?.dimensions?.length <= 0 ? "opacity-50" : ""}`}
                  ref={filterColumnDropdownRef}
                >
                  <div
                    className={`flex items-center justify-between px-1 py-0.5 sidebar-section-text bg-white  ${component?.properties?.dimensions?.length <= 0 ? "cursor-not-allowed" : "cursor-pointer"}  input-border-custome rounded-[5px] `}
                    onClick={() => {
                      // setColumnDropdownOpen(!isColumnDropdownOpen)
                      if (component?.properties?.dimensions?.length > 0) {
                        setColumnDropdownOpen(!isColumnDropdownOpen);
                      }
                    }}
                  >
                    <span>{selectedColumn?.column_name || "Select Column"}</span>
                    <KeyboardArrowDownIcon />
                    {/* {isLoading ? <CircularProgress size={18} color="inherit" /> : <KeyboardArrowDownIcon />} */}
                  </div>
                  {isColumnDropdownOpen && (
                    <div className="absolute w-[calc(100%-1.8rem)] bg-white input-border-custome sidebar-section-text rounded-[5px] shadow-md mt-1 max-h-40 overflow-y-auto z-10 custom-scrollbar">
                      {displayedOptions.length > 0 ? (
                        displayedOptions?.map((col, index) => (
                          <div key={index} className="cursor-pointer p-2 hover:bg-gray-100" onClick={() => handleColumnSelect2(col)}>
                            {col?.column_name}
                          </div>
                        ))
                      ) : (
                        <div className=" p-2 text-center sidebar-section-text">No columns available</div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className={`relative w-full px-3 ${component?.properties?.dimensions?.length <= 0 ? "opacity-50" : ""}`}
                  ref={operatorDropdownRef}
                >
                  <div
                    className={`flex items-center justify-between px-1 py-0.5 sidebar-section-text bg-white  ${component?.properties?.dimensions?.length <= 0 ? "cursor-not-allowed" : "cursor-pointer"}  input-border-custome rounded-[5px] `}
                    onClick={() => {
                      // setOperatorDropdownOpen(!isOperatorDropdownOpen)
                      if (component?.properties?.dimensions?.length > 0) {
                        setOperatorDropdownOpen(!isOperatorDropdownOpen)
                      }
                    }}
                  >
                    <span>{selectedOperator?.label || "Select Operator"}</span>
                    <KeyboardArrowDownIcon />
                  </div>
                  {isOperatorDropdownOpen && (
                    <div className="absolute w-[calc(100%-1.8rem)] bg-white input-border-custome sidebar-section-text rounded-[5px] shadow-md mt-1 max-h-40 overflow-y-auto z-10 custom-scrollbar">
                      {operators.map((op, index) => (
                        <div key={index} className="cursor-pointer p-2 hover:bg-gray-100" onClick={() => handleOperatorSelect(op)}>
                          {op?.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div
                  className={`relative w-full px-3 ${component?.properties?.dimensions?.length <= 0 ? "opacity-50" : ""}`}
                  ref={valueDropdownRef}
                >
                  <div
                    className={`flex items-center justify-between px-1 py-0.5 sidebar-section-text bg-white  ${component?.properties?.dimensions?.length <= 0 ? "cursor-not-allowed" : "cursor-pointer"}  input-border-custome rounded-[5px] `}
                    onClick={() => {
                      // setValueDropdownOpen(!isValueDropdownOpen)
                      if (component?.properties?.dimensions?.length > 0) {
                        setValueDropdownOpen(!isValueDropdownOpen)
                      }
                    }}
                  >
                    <span
                      className="max-h-10 overflow-hidden overflow-y-auto w-full custom-scrollbar"
                      style={{ display: "block", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                      title={selectedValues.length > 0 ? selectedValues.join(", ") : "Select Value"}
                    >
                      {selectedValues.length > 0 ? selectedValues.join(", ") : "Select Value"}
                    </span>
                    {/* <KeyboardArrowDownIcon /> */}
                    {isLoading ? <CircularProgress size="24px" color="inherit" /> : <KeyboardArrowDownIcon />}
                  </div>
                  {isValueDropdownOpen && (
                    <div
                      className="absolute w-[calc(100%-1.8rem)] bg-white input-border-custome sidebar-section-text rounded-[5px] shadow-md mt-1  z-10 "
                    >
                      {displayedValues.length > 0 ? (
                        <>
                          <div className="max-h-40 overflow-y-auto custom-scrollbar" onScroll={handleScroll}>
                            {displayedValues.map((val, index) => (
                              <div
                                key={index}
                                // className={`cursor-pointer p-2 hover:bg-gray-100 ${selectedValues.includes(val) ? "bg-gray-200" : ""}`}
                                className="cursor-pointer p-2 hover:bg-gray-100 flex justify-between items-center "
                                onClick={() => handleValueSelect(val)}
                                title={val}
                              >
                                {/* {val} */}
                                <span className="truncate w-[90%] overflow-hidden">{val}</span>
                                {selectedValues.includes(val) && <CheckIcon className="text-[#075985] text-sm" />}
                              </div>
                            ))}
                          </div>

                        </>
                      ) : (
                        <div className="p-2 text-center sidebar-section-text">No values available</div>
                      )}
                    </div>
                  )}
                </div>
                <span className="w-full px-3 flex justify-end">
                  <button
                    onClick={handleFilterApply}
                    disabled={component?.properties?.dimensions?.length <= 0 ||
                      !selectedColumn ||
                      !selectedOperator ||
                      selectedValues.length <= 0}
                    className="px-3 py-1 mb-3 font-bold text-white rounded-md btn-bgcolor text-xs disabled:opacity-40 disabled:cursor-not-allowed"
                  >
                    Apply
                  </button>
                </span>

                {/* {component?.properties?.filters?.map((item) => (
                  <div>
                    {item?.column}
                  </div>
                ))} */}


              </div>
              // <div className="relative w-full mb-2" ref={columnDropRef}>
              //   <span className="text-gray-600 font-semibold text-sm"></span>
              //   <div
              //     className={`flex items-center justify-between menu-value h-[30px] bg-white pl-2 pr-1 border border-gray-300 w-full focus:outline-none rounded-[5px] cursor-pointer transition-all ${dropdownOpen ? "border-gray-600 shadow-md" : ""
              //       }`}
              //     // onClick={() => setDropdownOpen(!dropdownOpen)}
              //     onClick={() => setColumnDropdownOpen(!columnDropdownOpen)}
              //   >
              //     <span className='text-[13px] text-[#7e8387]'
              //     >
              //       Columns
              //     </span>
              //     {isLoading ? (
              //       <CircularProgress size={18} color="inherit" />
              //     ) : (
              //       <KeyboardArrowDownIcon className="text-[#7e8387]" />
              //     )}
              //   </div>
              //   {columnDropdownOpen && (
              //     <div
              //       className="absolute z-10 bg-white border border-gray-300 rounded-[5px] shadow-md mt-1 w-full max-h-40 overflow-y-auto  transition-all custom-scrollbar"
              //     >
              //       {displayedOptions?.length > 0 ? (
              //         displayedOptions.map((option, index) => (
              //           <div
              //             key={index}
              //             className="cursor-pointer text-sm hover:bg-gray-100 px-3 py-2"
              //             onClick={() => {
              //               handleOnColumnSelect(option);
              //               setDropdownOpen(false);
              //             }}

              //           >
              //             {/* {label === 'Databases' ? `${option?.backend + " " + option?.database_name}` : label === 'Tables' ? option?.value : option} */}

              //             {option?.column_name}
              //           </div>
              //         ))
              //       ) : (
              //         <div className="text-gray-500 p-2 text-center text-sm">No options available</div>
              //       )}
              //     </div>
              //   )}
              // </div>
            )}
            {component?.properties?.chartType === "barWithLine" && (
              <span className=" d-flex pb-2 px-3 gap-2 ml-[1px]">
                <p className="m-0  whitespace-nowrap font-semibold text-[12px]  text-gray-500  self-center">
                  Select Line:
                </p>
                <select
                  // value={component?.properties?.line?.columnName || 'none'}
                  value={component?.properties?.line?.column_id || 'none'}
                  onChange={handleLineChange}
                  className={`block px-0.5 py-0.5 sidebar-section-text bg-white  cursor-pointer input-border-custome rounded-[5px] w-[110px] custom-scrollbar overflow-y-auto
                    ${component?.properties?.rowFields?.length <= 0 || component?.properties?.colFields?.length <= 0 ? "opacity-50 pointer-events-none" : "opacity-100 cursor-pointer"
                    }`}
                  disabled={component?.properties?.rowFields?.length <= 0 || component?.properties?.colFields?.length <= 0}
                >
                  <option value="none">none</option>
                  {filteredOptions?.map((item, index) => (
                    <option key={index} value={item?.column_id}>
                      {item?.column_name}
                    </option>
                  ))}
                </select>
              </span>
            )}
            <span className=" d-flex pb-2 px-3 gap-2">
              <p className="m-0  whitespace-nowrap font-semibold text-[12px]  text-gray-500  self-center">
                Aggregation:
              </p>
              <select
                value={component?.properties?.aggType || "Count"}
                onChange={(e) => {
                  (component?.properties?.rowFields?.length > 0 || component?.properties?.colFields?.length > 0) && updateChartAxis({
                    yAxis: component?.properties?.yAxis,
                    xAxis: component?.properties?.xAxis,
                    zAxis: component?.properties?.zAxis,
                    legends: component?.propertis?.legends,
                    rowFields: component?.properties?.rowFields,
                    colFields: component?.properties?.colFields,
                    line: component?.properties?.line,
                    aggType: e.target.value,
                    slice: component.properties.slice
                  })
                  setFlag(false);
                }}
                disabled={component?.properties?.rowFields?.length <= 0 && component?.properties?.colFields?.length <= 0}
                className={`${component?.properties?.rowFields?.length <= 0 && component?.properties?.colFields?.length <= 0 ? "cursor-not-allowed" : "cursor-pointer "} block disabled:opacity-75 px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-fit`}
              >
                {["Count", "Sum", "Average", "Maximum", "Minimum"]?.map(
                  (item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  )
                )}
              </select>
            </span>
            {component?.properties?.chartType === "double" && (
              <span className=" d-flex pb-2 px-3 gap-2 ml-[20px]">
                <p className="m-0  whitespace-nowrap font-semibold text-[12px]  text-gray-500  self-center">
                  Columns:
                </p>
                <div
                  className="relative flex bg-white input-border-custome h-[22px] flex-row items-center gap-1 divide-x-2 rounded-[5px] lg:w-[110px]"
                  ref={DropDownRef}
                >
                  <span className="flex flex-row border-0 items-center h-full overflow-scroll flex-nowrap no-scrollbar border-gray-400 ">
                    <div className="m-0 d-flex items-center text-xs pr-1 pl-[7px] text-gray-800 whitespace-nowrap">
                      Select column
                    </div>
                  </span>
                  <button
                    className="border-0 dropdown"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <KeyboardArrowDownIcon className="text-gray-800" />
                  </button>
                  {isDropdownOpen && (
                    <div
                      className="absolute flex flex-col overflow-auto custom-scrollbar bg-white border rounded-md max-h-52 top-7 z-10 -right-[2px]"
                      style={{
                        boxShadow:
                          "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      {selectedCols?.map((item, index) => (
                        <div
                          key={index}
                          className="hover:bg-primarylightgrey border-b d-flex px-2 py-2 items-center gap-2"
                        >
                          <label className="flex flex-row items-center gap-2">
                            <input
                              type="checkbox"
                              className="w-4 h-4 checked:lightblue cursor-pointer"
                              checked={component?.properties?.zAxis?.some(i => i?.column_id === item?.column_id)}
                              onChange={() => handleColumnSelect(item)}
                            />
                            <p className="m-0 cursor-pointer text-xs font-base">
                              {item?.column_name}
                            </p>
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </span>
            )}
          </div>
        </span>
        <SidebarSection
          title="Appearance"
          subSection={false}
          isOpen={openSection === "appearance"}
          onToggle={() => handleToggle("appearance")}
        >

          <span className="flex flex-col py-1 ml-[27px]">
            <p className="m-0 sidebar-section-label whitespace-nowrap self-start">
              Border:
            </p>
            <span className="flex gap-2">
              <span>
                <select
                  value={component?.properties?.borderType || "solid"}
                  onChange={(e) => {
                    onPropertyChange({ borderType: e.target.value });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        borderType: e.target.value,
                      },
                    }));
                    setFlag(false);
                    setIsAnimated(false);
                  }}
                  className="block px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] cursor-pointer w-[120px]"
                >

                  {["Solid", "Dotted", "Dashed", "Double", "None"]?.map(
                    (item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    )
                  )}
                </select>
              </span>
              <span
                className="relative flex "
                ref={borderColorRef}
              >

                <span className="flex items-center border-custome ">
                  <span
                    className="w-5 h-5 sketch-border"
                    style={{
                      backgroundColor:
                        component?.properties?.borderColor || "#000000",
                    }}
                  />
                  <span
                    className="w-5 h-4 flex items-center cursor-pointer"
                    onClick={() => {
                      setColorPickerOpen(!colorPickerOpen);
                      setColorPickerBg(false);
                      setColorPickerText(false);
                    }}
                  >
                    <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                  </span>
                </span>
                {colorPickerOpen && (
                  <div
                    // className="absolute z-10 right-0 bottom-0 mb-[26px] cursor-pointer"
                    className={`absolute z-10 right-0 cursor-pointer ${(component?.properties?.chartType === "pie" || component?.properties?.chartType === "big_number_trendline" || component?.properties?.chartType === "funnel" || component?.properties?.chartType === "gauge") ? "top-0 mt-[26px]" : "bottom-0 mb-[26px]"}`}
                  >
                    <SketchPicker
                      color={
                        component?.properties?.borderColor || "rgba(0,0,0,1)"
                      }
                      onChange={(color) => {
                        const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                        onPropertyChange({ borderColor: rgbaColor });
                        setSelectedComponent((prev) => ({
                          ...prev,
                          properties: {
                            ...prev.properties,
                            borderColor: rgbaColor,
                          },
                        }));
                        setFlag(false);
                        setIsAnimated(false);
                      }}
                      className="color-picker"
                    />
                  </div>
                )}
              </span>
            </span>
          </span>

          <span className="flex gap-3">
            <span className="d-flex flex-col py-1 ml-[27px]">
              <p className="m-0  whitespace-nowrap sidebar-section-label self-start">
                Border Width:
              </p>
              <input
                type="number"
                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                value={component?.properties?.borderWidth || 0}
                onChange={(e) => {
                  let newValue = e.target.value;
                  if (newValue !== "") {
                    newValue = newValue.replace(/^0+/, "") || "0";
                  }
                  if (parseInt(newValue) < 0) {
                    newValue = "0";
                  }
                  onPropertyChange({ borderWidth: newValue });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      borderWidth: newValue,
                    },
                  }));
                  setFlag(false);
                  setIsAnimated(false);
                }}
              />
            </span>
            {
              (component?.properties?.chartType === "big_number" || component?.properties?.chartType === "big_number_trendline" || component?.properties?.chartType === "funnel" || component?.properties?.chartType === "gauge") && (
                <span className="d-flex flex-col py-1">
                  <p className="m-0  whitespace-nowrap sidebar-section-label self-start">
                    Font size:
                  </p>
                  <input
                    type="number"
                    className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                    value={
                      component?.properties?.option?.xAxis?.axisLabel?.fontSize || 11
                    }
                    onChange={(e) => {
                      let newValue = e.target.value;
                      if (newValue !== "") {
                        newValue = newValue.replace(/^0+/, "") || "0";
                      }
                      if (parseInt(newValue) < 0) {
                        newValue = "0";
                      }
                      const option = {
                        ...component?.properties?.option,
                        xAxis: {
                          ...component?.properties?.option?.xAxis,
                          axisLabel: {
                            ...component?.properties?.option?.xAxis?.axisLabel,
                            fontSize: newValue,
                          },
                        },
                        yAxis: {
                          ...component?.properties?.option?.yAxis,
                          axisLabel: {
                            ...component?.properties?.option?.yAxis?.axisLabel,
                            fontSize: newValue,
                          },
                        },
                        title: {
                          ...component?.properties?.option?.title,
                          textStyle: {
                            ...component?.properties?.option?.title?.textStyle,
                            fontSize: newValue,
                          },
                          subtextStyle: {
                            ...component?.properties?.option?.title?.subtextStyle,
                            fontSize: newValue,
                          },
                        },
                        legend: {
                          ...component?.properties?.option?.legend,
                          textStyle: {
                            ...component?.properties?.option?.legend?.textStyle,
                            fontSize: newValue,
                          },
                        },
                      };
                      onPropertyChange({ option: option });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          option: option,
                        },
                      }));
                      setFlag(false);
                      setIsAnimated(false);
                    }}
                  />
                </span>
              )
            }
          </span>
          {(component?.properties?.chartType === "big_number" || component?.properties?.chartType === "big_number_trendline" || component?.properties?.chartType === "funnel" || component?.properties?.chartType === "gauge") && (
            <span className="flex flex-col py-1 ml-[27px]">
              <p className="m-0 sidebar-section-label whitespace-nowrap self-start">
                Font Family:
              </p>
              <span className="flex gap-2">
                <span>
                  <select
                    value={
                      component?.properties?.option?.xAxis?.axisLabel?.fontFamily
                    }
                    onChange={(e) => {
                      const option = {
                        ...component?.properties?.option,
                        xAxis: {
                          ...component?.properties?.option?.xAxis,
                          axisLabel: {
                            ...component?.properties?.option?.xAxis?.axisLabel,
                            fontFamily: e.target.value,
                          },
                        },
                        yAxis: {
                          ...component?.properties?.option?.yAxis,
                          axisLabel: {
                            ...component?.properties?.option?.yAxis?.axisLabel,
                            fontFamily: e.target.value,
                          },
                        },
                        title: {
                          ...component?.properties?.option?.title,
                          textStyle: {
                            ...component?.properties?.option?.title?.textStyle,
                            fontFamily: e.target.value,
                          },
                          subtextStyle: {
                            ...component?.properties?.option?.title?.subtextStyle,
                            fontFamily: e.target.value,
                          },
                        },
                        legend: {
                          ...component?.properties?.option?.legend,
                          textStyle: {
                            ...component?.properties?.option?.legend?.textStyle,
                            fontFamily: e.target.value,
                          },
                        },
                      };
                      if (Array.isArray(option.series)) {
                        option.series = option.series.map((seriesItem) => {
                          if (seriesItem.type === "gauge") {
                            return {
                              ...seriesItem,
                              axisLabel: {
                                ...seriesItem.axisLabel,
                                fontFamily: e.target.value,
                              },
                            };
                          }
                          return seriesItem;
                        });
                      }
                      onPropertyChange({ option: option });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          option: option,
                        },
                      }));
                      setFlag(false);
                      setIsAnimated(false);
                    }}
                    className="block px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] cursor-pointer w-[120px]"
                  >
                    <option value={"Inter"}>Inter</option>
                    {Fonts?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </span>
                <span
                  className="relative flex "
                  ref={textColorRef}
                >
                  <span className="flex items-center border-custome ">
                    <span
                      className="w-5 h-5 sketch-border"
                      style={{
                        backgroundColor:
                          component?.properties?.option?.xAxis?.axisLabel?.color ||
                          "rgba(0, 0, 0, 1)",
                      }}
                    />
                    <span
                      className="w-5 h-4 flex items-center cursor-pointer"
                      onClick={() => {
                        setColorPickerText(!colorPickerText);
                        setColorPickerBg(false);
                        setColorPickerOpen(false);
                      }}
                    >
                      <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                    </span>
                  </span>
                  {colorPickerText && (
                    <div
                      // className="absolute z-10 bottom-0 right-0 mb-[26px] cursor-pointer"
                      className={`absolute z-10 right-0 cursor-pointer ${(component?.properties?.chartType === "pie" || component?.properties?.chartType === "gauge") ? "top-0 mt-[26px]" : "bottom-0 mb-[26px]"}`}
                    >
                      <SketchPicker
                        color={
                          component?.properties?.option?.xAxis?.axisLabel?.color ||
                          "rgba(0, 0, 0, 1)"
                        }
                        onChange={(color) => {
                          const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                          const option = {
                            ...component?.properties?.option,
                            xAxis: {
                              ...component?.properties?.option?.xAxis,
                              axisLabel: {
                                ...component?.properties?.option?.xAxis?.axisLabel,
                                color: rgbaColor,
                              },
                            },
                            yAxis: {
                              ...component?.properties?.option?.yAxis,
                              axisLabel: {
                                ...component?.properties?.option?.yAxis?.axisLabel,
                                color: rgbaColor,
                              },
                            },
                            title: {
                              ...component?.properties?.option?.title,
                              textStyle: {
                                ...component?.properties?.option?.title?.textStyle,
                                color: rgbaColor,
                              },
                              subtextStyle: {
                                ...component?.properties?.option?.title?.subtextStyle,
                                color: rgbaColor,
                              },
                            },
                            legend: {
                              ...component?.properties?.option?.legend,
                              textStyle: {
                                ...component?.properties?.option?.legend?.textStyle,
                                color: rgbaColor,
                              },
                            },
                          };
                          if (Array.isArray(option.series)) {
                            option.series = option.series.map((seriesItem) => {
                              if (seriesItem.type === "gauge") {
                                return {
                                  ...seriesItem,
                                  axisLabel: {
                                    ...seriesItem.axisLabel,
                                    color: rgbaColor, // Apply color to the axisLabel
                                  },
                                  axisTick: {
                                    ...seriesItem.axisTick,
                                    lineStyle: {
                                      ...seriesItem.axisTick?.lineStyle,
                                      color: rgbaColor,
                                    },
                                  },
                                };
                              }
                              return seriesItem;
                            });
                          }
                          onPropertyChange({ option: option });
                          setSelectedComponent((prev) => ({
                            ...prev,
                            properties: {
                              ...prev.properties,
                              option: option,
                            },
                          }));
                          setFlag(false);
                          setIsAnimated(false);
                        }}
                        className="color-picker"
                      />
                    </div>
                  )}
                </span>
              </span>
            </span>
          )}

          <div className="d-flex py-1 ml-[27px] flex-col pb-2 ">
            <p className="m-0  whitespace-nowrap  self-start sidebar-section-label">
              Background Color:
            </p>
            <span className="relative flex" ref={backgroundColorRef}>
              <span className="flex items-center border-custome ">
                <span
                  className="w-5 h-5 sketch-border"
                  style={{
                    backgroundColor:
                      component?.properties?.option?.backgroundColor || "rgba(255, 255, 255, 1)",
                  }}
                />
                <span
                  className="w-5 h-4 flex items-center cursor-pointer"
                  onClick={() => {
                    setColorPickerBg(!colorPickerBg);
                    setColorPickerOpen(false);
                    setColorPickerText(false);
                    setColorPickerLine(false);
                  }}
                >
                  <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                </span>
              </span>
              {colorPickerBg && (
                <div
                  // className="absolute z-10 bottom-0 mb-[32px] cursor-pointer"
                  className={`absolute z-10  cursor-pointer ${(component?.properties?.chartType === "pie" || component?.properties?.chartType === "funnel") ? "top-0 mt-[26px]" : "bottom-0 mb-[26px]"}`}
                >
                  <SketchPicker
                    color={
                      component?.properties?.option?.backgroundColor ||
                      "rgba(255, 255, 255, 1)"
                    }
                    onChange={(color) => {
                      const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                      const option = {
                        ...component?.properties?.option,
                        backgroundColor: rgbaColor,
                      };
                      onPropertyChange({ option: option });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          option: option,
                        },
                      }));
                      setFlag(false);
                      setIsAnimated(false);
                    }}
                    className="color-picker"
                  />
                </div>
              )}
            </span>
          </div>
          {/* {component?.properties?.chartType === "gauge" && (
            <span className="d-flex flex-col ml-[27px] py-1">
              <p className="m-0  whitespace-nowrap sidebar-section-label self-start">
                Row Limit:
              </p>
              <select
                type="number"
                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                value={component?.properties?.option?.rowLimit || 1}
                onChange={(e) => {
                  const newValue = parseInt(e.target.value, 10);

                  const option = {
                    ...component?.properties?.option,
                    rowLimit: newValue,
                  };

                  onPropertyChange({ option: option });

                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      option: option,
                    },
                  }));

                  setFlag(false);
                  setIsAnimated(false);
                }}
              >
                {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>


            </span>
          )} */}
          {component?.properties?.chartType === "gauge" && (
            <span className="flex ml-[27px] py-1 pb-2">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  className="cursor-pointer"
                  checked={component?.properties?.option?.series?.[0]?.progress?.overlap === true}
                  onChange={(e) => {
                    const checked = e.target.checked;

                    // Create the updated option with the correct overlap value
                    const updatedOption = {
                      ...component?.properties?.option,
                      series: component?.properties?.option?.series?.map((progress, index) =>
                        index === 0
                          ? { ...progress, progress: { ...progress.progress, overlap: checked } } // Update overlap in the first series
                          : progress
                      ),
                    };

                    // Update the component state with the new options
                    onPropertyChange({ option: updatedOption });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        option: updatedOption,
                      },
                    }));
                  }}
                />
                <span className="block text-[#6b7280] font-semibold text-[12px] cursor-pointer">
                  Overlap Arc
                </span>
              </label>
            </span>
          )}

          {component?.properties?.chartType === "big_number_trendline" && (
            <div className="d-flex py-1 ml-[27px] flex-col pb-2 ">
              <p className="m-0  whitespace-nowrap  self-start sidebar-section-label">
                Trendline Color:
              </p>
              <span className="relative flex" ref={trendlineColorRef}>
                <span className="flex items-center border-custome ">
                  <span
                    className="w-5 h-5 sketch-border"
                    style={{
                      backgroundColor:
                        component?.properties?.option?.TrendLineColor || "rgba(0, 122, 255, 1)",
                    }}
                  />
                  <span
                    className="w-5 h-4 flex items-center cursor-pointer"
                    onClick={() => {
                      setColorPickerLine(!colorPickerLine)
                      setColorPickerBg(false);
                      setColorPickerOpen(false);
                      setColorPickerText(false);
                    }}
                  >
                    <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                  </span>
                </span>
                {colorPickerLine && (
                  <div
                    // className="absolute z-10 bottom-0 mb-[32px] cursor-pointer"
                    className={`absolute z-10  cursor-pointer ${(component?.properties?.chartType === "pie" || component?.properties?.chartType === "funnel") ? "top-0 mt-[26px]" : "bottom-0 mb-[26px]"}`}
                  >
                    <SketchPicker
                      color={
                        component?.properties?.option?.TrendLineColor ||
                        "rgba(0, 122, 255, 1)"
                      }
                      onChange={(color) => {
                        const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                        const option = {
                          ...component?.properties?.option,
                          TrendLineColor: rgbaColor,

                          lineStyle: {
                            ...component?.properties?.option?.lineStyle,
                            color: rgbaColor,
                          },
                          areaStyle: {
                            ...component?.properties?.option?.areaStyle,
                            color: rgbaColor,
                          }

                        };
                        onPropertyChange({ option: option });
                        setSelectedComponent((prev) => ({
                          ...prev,
                          properties: {
                            ...prev.properties,
                            option: option,
                          },
                        }));
                        setFlag(false);
                        setIsAnimated(false);
                      }}
                      className="color-picker"
                    />
                  </div>
                )}
              </span>
            </div>
          )}
        </SidebarSection>
        {component?.properties?.chartType !== "funnel" && component?.properties?.chartType !== "big_number_trendline" && component?.properties?.chartType !== 'gauge' && component?.properties?.chartType !== 'big_number' && (
          <SidebarSection
            title={component?.properties?.chartType === "pie" ? "Slice" : "Axis"}
            subSection={false}
            isOpen={openSection === "axis"}
            onToggle={() => handleToggle("axis")}
          >
            {component?.properties?.chartType !== "big_number_trendline" && (
              <span className="flex flex-col pt-2 ml-[27px]">
                <p className="m-0 sidebar-section-label whitespace-nowrap self-start">
                  Labels:
                </p>
                <span className="flex gap-2">
                  <span>
                    <select
                      value={
                        component?.properties?.option?.xAxis?.axisLabel?.fontFamily
                      }
                      onChange={(e) => {
                        const option = {
                          ...component?.properties?.option,
                          xAxis: {
                            ...component?.properties?.option?.xAxis,
                            axisLabel: {
                              ...component?.properties?.option?.xAxis?.axisLabel,
                              fontFamily: e.target.value,
                            },
                          },
                          // yAxis: {
                          //   ...component?.properties?.option?.yAxis,
                          //   axisLabel: {
                          //     ...component?.properties?.option?.yAxis?.axisLabel,
                          //     fontFamily: e.target.value,
                          //   },
                          // },
                          yAxis: component?.properties?.chartType === "pie"
                            ? {
                              ...component?.properties?.option?.yAxis,
                              axisLabel: {
                                ...component?.properties?.option?.yAxis?.axisLabel,
                                fontFamily: e.target.value,
                              },
                            }
                            : component.properties.option.yAxis.map((item) => ({
                              ...item,
                              axisLabel: {
                                ...item.axisLabel,
                                fontFamily: e.target.value,
                              },
                            })),
                          // yAxis: component.properties.option.yAxis.map((item) => ({
                          //   ...item,
                          //   axisLabel: {
                          //     ...item.axisLabel,
                          //     fontFamily: e.target.value,
                          //   },
                          // })),
                          legend: {
                            ...component?.properties?.option?.legend,
                            textStyle: {
                              ...component?.properties?.option?.legend?.textStyle,
                              fontFamily: e.target.value,
                            },
                          },
                        };
                        onPropertyChange({ option: option });
                        setSelectedComponent((prev) => ({
                          ...prev,
                          properties: {
                            ...prev.properties,
                            option: option,
                          },
                        }));
                        setFlag(false);
                        setIsAnimated(false);
                      }}
                      className="block px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] cursor-pointer w-[120px]"
                    >
                      <option value={"Inter"}>Inter</option>
                      {Fonts?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </span>
                  <span
                    className="relative flex "
                    ref={textColorRef}
                  >
                    <span className="flex items-center border-custome ">
                      <span
                        className="w-5 h-5 sketch-border"
                        style={{
                          backgroundColor:
                            component?.properties?.option?.xAxis?.axisLabel?.color ||
                            "rgba(0, 0, 0, 1)",
                        }}
                      />
                      <span
                        className="w-5 h-4 flex items-center cursor-pointer"
                        onClick={() => {
                          setColorPickerText(!colorPickerText);
                          setColorPickerBg(false);
                          setColorPickerOpen(false);
                        }}
                      >
                        <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                      </span>
                    </span>
                    {colorPickerText && (
                      <div
                        // className="absolute z-10 bottom-0 right-0 mb-[26px] cursor-pointer"
                        className={`absolute z-10 right-0 cursor-pointer ${component?.properties?.chartType === "pie" ? "top-0 mt-[26px]" : "bottom-0 mb-[26px]"}`}
                      >
                        <SketchPicker
                          color={
                            component?.properties?.option?.xAxis?.axisLabel?.color ||
                            "rgba(0, 0, 0, 1)"
                          }
                          onChange={(color) => {
                            const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                            const option = {
                              ...component?.properties?.option,

                              xAxis: {
                                ...component?.properties?.option?.xAxis,
                                axisLabel: {
                                  ...component?.properties?.option?.xAxis?.axisLabel,
                                  color: rgbaColor,
                                },
                              },
                              // yAxis: {
                              //   ...component?.properties?.option?.yAxis,
                              //   axisLabel: {
                              //     ...component?.properties?.option?.yAxis?.axisLabel,
                              //     color: rgbaColor,
                              //   },
                              // },
                              yAxis: component?.properties?.chartType === "pie"
                                ? {
                                  ...component?.properties?.option?.yAxis,
                                  axisLabel: {
                                    ...component?.properties?.option?.yAxis?.axisLabel,
                                    color: rgbaColor,
                                  },
                                }
                                : component.properties.option.yAxis.map((item) => ({
                                  ...item,
                                  axisLabel: {
                                    ...item.axisLabel,
                                    color: rgbaColor,
                                  },
                                })),
                              // yAxis: component.properties.option.yAxis.map((item) => ({
                              //   ...item,
                              //   axisLabel: {
                              //     ...item.axisLabel,
                              //     color: rgbaColor,
                              //   },
                              // })),
                              legend: {
                                ...component?.properties?.option?.legend,
                                textStyle: {
                                  ...component?.properties?.option?.legend?.textStyle,
                                  color: rgbaColor,
                                },
                              },
                            };
                            onPropertyChange({ option: option, textColor: rgbaColor });
                            setSelectedComponent((prev) => ({
                              ...prev,
                              properties: {
                                ...prev.properties,
                                option: option,
                                textColor: rgbaColor
                              },
                            }));
                            setFlag(false);
                            setIsAnimated(false);
                          }}
                          className="color-picker"
                        />
                      </div>
                    )}
                  </span>
                </span>
              </span>
            )}
            {component?.properties?.chartType !== "big_number_trendline" && (
              <span className="flex py-1 gap-3 ">
                <span className={`py-1 flex flex-col ml-[27px] ${component?.properties?.chartType === "pie" ? "ml-[27px]" : ""}`}>
                  <p className="m-0  whitespace-nowrap sidebar-section-label self-start ">
                    Labels Size:
                  </p>
                  <input
                    type="number"
                    className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                    value={
                      component?.properties?.option?.xAxis?.axisLabel?.fontSize || 11
                    }
                    onChange={(e) => {
                      let newValue = e.target.value;
                      if (newValue !== "") {
                        newValue = newValue.replace(/^0+/, "") || "0";
                      }
                      if (parseInt(newValue) < 0) {
                        newValue = "0";
                      }
                      const option = {
                        ...component?.properties?.option,
                        xAxis: {
                          ...component?.properties?.option?.xAxis,
                          axisLabel: {
                            ...component?.properties?.option?.xAxis?.axisLabel,
                            fontSize: newValue,
                          },
                        },
                        // yAxis: {
                        //   ...component?.properties?.option?.yAxis,
                        //   axisLabel: {
                        //     ...component?.properties?.option?.yAxis?.axisLabel,
                        //     fontSize: newValue,
                        //   },
                        // },
                        yAxis: component?.properties?.chartType === "pie"
                          ? {
                            ...component?.properties?.option?.yAxis,
                            axisLabel: {
                              ...component?.properties?.option?.yAxis?.axisLabel,
                              fontSize: newValue,
                            },
                          }
                          : component.properties.option.yAxis.map((item) => ({
                            ...item,
                            axisLabel: {
                              ...item.axisLabel,
                              fontSize: newValue,
                            },
                          })),
                        // yAxis: component.properties.option.yAxis.map((item) => ({
                        //   ...item,
                        //   axisLabel: {
                        //     ...item.axisLabel,
                        //     fontSize: newValue,
                        //   },
                        // })),
                        legend: {
                          ...component?.properties?.option?.legend,
                          textStyle: {
                            ...component?.properties?.option?.legend?.textStyle,
                            fontSize: newValue,
                          },
                        },
                      };
                      onPropertyChange({ option: option });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          option: option,
                        },
                      }));
                      setFlag(false);
                      setIsAnimated(false);
                    }}
                  />
                </span>
                {component?.properties?.chartType !== "pie" && (
                  <span className=" py-1 flex flex-col">
                    <p className="m-0  whitespace-nowrap sidebar-section-label self-start ">
                      Interval:
                    </p>
                    <input
                      type="number"
                      className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                      value={component?.properties?.option?.yAxis[0]?.interval}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        if (newValue !== "") {
                          newValue = newValue.replace(/^0+/, "") || "0";
                        }
                        if (parseInt(newValue) < 0) {
                          newValue = "0";
                        }
                        const option = {
                          ...component?.properties?.option,
                          // yAxis: {
                          //   ...component?.properties?.option?.yAxis,
                          //   interval: Number(newValue),
                          // },
                          yAxis: component.properties.option.yAxis.map((item) => ({
                            ...item,
                            interval: Number(newValue),
                          })),
                        };
                        onPropertyChange({ option: option });
                        setSelectedComponent((prev) => ({
                          ...prev,
                          properties: {
                            ...prev.properties,
                            option: option,
                          },
                        }));
                        setFlag(false);
                        setIsAnimated(false);
                      }}
                    />
                  </span>
                )}
              </span>
            )}
            {component?.properties?.chartType !== "pie" && component?.properties?.chartType !== "funnel" && component?.properties?.chartType !== "big_number_trendline" && (
              <span className="d-flex flex-col  py-1 ml-[27px] pb-2">
                <p className="block m-0  whitespace-nowrap  self-start sidebar-section-label">
                  Labels Rotation(x):
                </p>
                <input
                  type="number"
                  className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                  value={component?.properties?.option?.xAxis?.axisLabel?.rotate || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    // if (newValue !== '') {
                    //     newValue = newValue.replace(/^0+/, '') || '0';
                    // }
                    // if (parseInt(newValue) < 0) {
                    //     newValue = '0';
                    // }
                    const option = {
                      ...component?.properties?.option,
                      xAxis: {
                        ...component?.properties?.option?.xAxis,
                        axisLabel: {
                          ...component?.properties?.option?.xAxis?.axisLabel,
                          rotate: newValue,
                        },
                      },
                    };
                    onPropertyChange({ option: option });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        option: option,
                      },
                    }));
                    setFlag(false);
                    setIsAnimated(false);
                  }}
                />
              </span>
            )}
            {component?.properties?.chartType !== "pie" && component?.properties?.chartType !== "funnel" && (
              <span className="flex ml-[27px] py-1 pb-2">
                <label className="flex items-center space-x-2 ">
                  <input
                    type="checkbox"
                    className="cursor-pointer"
                    checked={component?.properties?.option?.dataZoom?.[0]?.show === true}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const updatedOption = {
                        ...component?.properties?.option,
                        dataZoom: component?.properties?.option?.dataZoom?.map((zoom, index) =>
                          index === 0 ? { ...zoom, show: checked } : zoom
                        ),
                      };

                      onPropertyChange({ option: updatedOption });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          option: updatedOption,
                        },
                      }));
                    }}
                  />
                  <span className="block text-[#6b7280] font-semibold text-[12px] cursor-pointer">Show Zoombar</span>
                </label>
              </span>
            )}
          </SidebarSection>
        )}
        {component?.properties?.chartType !== "pie" && component?.properties?.chartType !== "funnel" && component?.properties?.chartType !== "big_number_trendline" && component?.properties?.chartType !== 'gauge' && component?.properties?.chartType !== 'big_number' &&
          <SidebarSection
            title="Tooltip"
            subSection={false}
            isOpen={openSection === "tooltip"}
            onToggle={() => handleToggle("tooltip")}
          >
            <span className="flex pl-5 py-2">
              <label className="flex items-center space-x-2 ">
                <input
                  type="checkbox"
                  className="cursor-pointer"
                  // defaultChecked={true}
                  checked={component?.properties?.option?.tooltip?.trigger === "axis"}
                  onChange={(e) => {
                    const updatedOption = {
                      ...component?.properties?.option,
                      tooltip: {
                        ...component?.properties?.option?.tooltip,
                        trigger: e.target.checked ? "axis" : "item",
                      },
                    };
                    onPropertyChange({ option: updatedOption });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        option: updatedOption,
                      },
                    }));
                  }}
                />
                <span className="block text-[#6b7280] font-semibold text-[12px] cursor-pointer">Rich Tooltip</span>
              </label>
            </span>
          </SidebarSection>
        }
        {component?.properties?.chartType !== "big_number_trendline" && component?.properties?.chartType !== 'big_number' && (
          <SidebarSection
            title="Legends"
            subSection={false}
            isOpen={openSection === "legends"}
            onToggle={() => handleToggle("legends")}
          >
            <div
              style={{ maxHeight: "44vh", overflow: "auto", height: '40vh' }}
              className="py-2 custom-scrollbar"
            >
              {component?.properties?.chartType === "bar" ||
                component?.properties?.chartType === "barWithLine" ||
                component?.properties?.chartType === "stacked" ||
                component?.properties?.chartType === "double" ||
                component?.properties?.chartType === "line" ||
                component?.properties?.chartType === "area" ||
                component?.properties?.chartType === "scatter"
                ? component?.properties?.option?.series?.map((item) => (
                  <div className="d-flex items-center gap-2 px-3 py-1">
                    <div
                      ref={(el) => (colorPickerRefs.current[item.name] = el)}
                      className="relative"
                    >
                      <input
                        type="text"
                        className="block p-1 text-sm  text-gray-900  border border-gray-300  w-full focus:ring-blue-500 focus:border-blue-500"
                        value={""}
                        onClick={() => {
                          handleColorPickerToggle(item?.name);
                        }}
                        readOnly
                        style={{
                          backgroundColor: item?.itemStyle?.color || "#fff",
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                      />
                      {colorPickerState[item.name] && (
                        <div
                          className="absolute z-10 mt-[30px] top-0 "
                          style={{
                            // top: "-180px",
                            // left: "40px",
                            cursor: "pointer",
                          }}
                        >
                          <SketchPicker
                            color={item?.itemStyle?.color || "rgba(0, 0, 0, 1)"}
                            onChange={(color) => {
                              const rgbaColor = `rgba(${color?.rgb?.r}, ${color?.rgb?.g}, ${color?.rgb?.b},${color?.rgb?.a})`;
                              const option = {
                                ...component?.properties?.option,
                                series:
                                  component?.properties?.option?.series?.map(
                                    (seriesItem) => {
                                      if (seriesItem?.name === item?.name) {
                                        return {
                                          ...seriesItem, // Copy existing series item
                                          itemStyle: {
                                            ...seriesItem?.itemStyle, // Copy existing itemStyle
                                            color: rgbaColor, // Update color
                                          },
                                          ...(seriesItem.lineStyle && {
                                            lineStyle: { ...seriesItem?.lineStyle, color: rgbaColor },
                                          })
                                        };
                                      }
                                      return seriesItem;
                                    }
                                  ),
                              };
                              onPropertyChange({ option: option });
                              setSelectedComponent((prev) => ({
                                ...prev,
                                properties: {
                                  ...prev.properties,
                                  option: option,
                                },
                              }));
                              setFlag(false);
                              setIsAnimated(false);
                            }}
                            className="color-picker"
                          />
                        </div>
                      )}
                    </div>
                    <div className="m-0 prop-label whitespace-nowrap  self-center">
                      {/* {parseNull(item?.name, "NA")} */}
                      {
                        (() => {
                          const dateFormat = component?.properties?.legends?.[0]?.format || component?.properties?.line?.format;
                          const name = item?.name;

                          if (moment(name).isValid() && dateFormat) {
                            return moment(name).format(dateFormat);
                          } else {
                            return parseNull(name, "NA");
                          }
                        })()
                      }
                      {/* {
                        (() => {
                          const dateFormat = component?.properties?.legends[0]?.format  ;
                          const name = item?.name;

                          if (dateFormat) {
                            return moment(name).format(dateFormat);
                          } else {
                            return parseNull(name, "NA");
                          }
                        })()
                      } */}

                    </div>
                  </div>
                ))
                : component?.properties?.option?.series && component?.properties?.option?.series[0]?.data?.map((item) => (
                  <div className="d-flex items-center gap-2 px-3 py-1">
                    <div
                      ref={(el) => (colorPickerRefs.current[item.name] = el)}
                      className="relative"
                    >
                      <input
                        type="text"
                        className="block p-1 text-sm text-gray-900  border border-gray-300  w-full focus:ring-blue-500 focus:border-blue-500"
                        value={""}
                        onClick={() => {
                          handleColorPickerToggle(item.name);
                        }}
                        readOnly
                        style={{
                          backgroundColor: item?.itemStyle?.color || "#fff",
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                      />
                      {colorPickerState[item.name] && (
                        <div
                          className="absolute z-10 mt-1"
                          style={{
                            // top: "-180px",
                            // left: "40px",
                            cursor: "pointer",
                          }}
                        >
                          <SketchPicker
                            color={item?.itemStyle?.color || "rgba(0, 0, 0, 1)"}
                            onChange={(color) => {
                              const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b},${color.rgb.a})`;
                              const updatedSeries =
                                component?.properties?.option?.series[0]?.data?.map(
                                  (seriesItem) =>
                                    seriesItem.name === item.name
                                      ? {
                                        ...seriesItem,
                                        itemStyle: {
                                          ...seriesItem.itemStyle,
                                          color: rgbaColor,
                                        },
                                      }
                                      : seriesItem
                                );
                              const updatedOption = {
                                ...component?.properties?.option,
                                series: [
                                  {
                                    ...component?.properties?.option?.series[0],
                                    data: updatedSeries,
                                  },
                                ],
                              };
                              onPropertyChange({ option: updatedOption });
                              setSelectedComponent((prev) => ({
                                ...prev,
                                properties: {
                                  ...prev.properties,
                                  option: updatedOption,
                                },
                              }));
                              setFlag(false);
                              setIsAnimated(false);
                            }}
                            className="color-picker"
                          />
                        </div>
                      )}
                    </div>
                    <div className="m-0 prop-label whitespace-nowrap  self-center">
                      {/* {parseNull(item?.name, "NA")} */}
                      {
                        (() => {
                          const dateFormat = component?.properties?.slice[0]?.format || component?.properties?.dimensions[0]?.format;
                          const name = item?.name;

                          if (dateFormat) {
                            return moment(name).format(dateFormat);
                          } else {
                            return parseNull(name, "NA");
                          }
                        })()
                      }
                    </div>
                  </div>
                ))}

              {component?.properties?.chartType === "gauge" &&
                component?.properties?.option?.series?.slice(1)?.map((item) => (
                  <div className="d-flex items-center gap-2 px-3 py-1">
                    <div
                      ref={(el) => (colorPickerRefs.current[item.name] = el)}
                      className="relative"
                    >
                      <input
                        type="text"
                        className="block p-1 text-sm  text-gray-900  border border-gray-300  w-full focus:ring-blue-500 focus:border-blue-500"
                        value={""}
                        onClick={() => {
                          handleColorPickerToggle(item?.name);
                        }}
                        readOnly
                        style={{
                          backgroundColor: item?.itemStyle?.color || "#fff",
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                      />
                      {colorPickerState[item.name] && (
                        <div
                          className="absolute z-10 mt-[30px] top-0 "
                          style={{
                            // top: "-180px",
                            // left: "40px",
                            cursor: "pointer",
                          }}
                        >
                          <SketchPicker
                            color={item?.itemStyle?.color || "rgba(0, 0, 0, 1)"}
                            onChange={(color) => {
                              const rgbaColor = `rgba(${color?.rgb?.r}, ${color?.rgb?.g}, ${color?.rgb?.b},${color?.rgb?.a})`;
                              const option = {
                                ...component?.properties?.option,
                                series:
                                  component?.properties?.option?.series?.map(
                                    (seriesItem) => {
                                      if (seriesItem?.name === item?.name) {
                                        return {
                                          ...seriesItem, // Copy existing series item
                                          itemStyle: {
                                            ...seriesItem?.itemStyle, // Copy existing itemStyle
                                            color: rgbaColor, // Update color
                                          },
                                          axisLine: {
                                            ...seriesItem?.axisLine,
                                            lineStyle: {
                                              ...seriesItem?.axisLine?.lineStyle,
                                              color: [
                                                [seriesItem?.axisLine?.lineStyle?.color?.[0]?.[0] || 0, rgbaColor],
                                                [1, "#ddd"],
                                              ],
                                            },
                                          },
                                        };
                                      }
                                      return seriesItem;
                                    }
                                  ),
                              };
                              onPropertyChange({ option: option });
                              setSelectedComponent((prev) => ({
                                ...prev,
                                properties: {
                                  ...prev.properties,
                                  option: option,
                                },
                              }));
                              setFlag(false);
                              setIsAnimated(false);
                            }}
                            className="color-picker"
                          />
                        </div>
                      )}
                    </div>
                    <div className="m-0 prop-label whitespace-nowrap  self-center">
                      {parseNull(item?.name, "NA")}
                    </div>
                  </div>
                ))}
            </div>
          </SidebarSection>
        )}
      </div>
    </>
  );
}
export default ChartProperties;