import React from "react";
import { PlayArrow } from "@mui/icons-material";
import { useCreateOrganisationMutation } from "../../../features/organisation/organisationApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updateUser } from "../../../features/auth/authSlice";
import { skorboardmain_logo } from "../../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentMethodModal from "./components/PaymentMethodModal";
import { useCreateSubscriptionMutation } from "../../../features/billing/billingApiSlice";
import { useGetUserMutation } from "../../../features/user/userApiSlice";
import Loading from "../../../components/Loading";

// All 50 states of USA
const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const BusinessInfo = ({ step, userEmail, handleStepChange, minStep }) => {
  const [errMsg, setErrMsg] = React.useState(null);
  const [name, setName] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [street, setStreet] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [paymentFormOpen, setPaymentFormOpen] = React.useState(false);

  const location = useLocation();
  // Parse the query string
  const queryParams = new URLSearchParams(location.search);
  const plan = queryParams.get("plan");

  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const [createOrganisation, { isLoading }] = useCreateOrganisationMutation();
  const [createSubscription, { isLoading: createLoading }] =
    useCreateSubscriptionMutation();
  const [getUser] = useGetUserMutation();

  const loading = isLoading || createLoading;

  const isButtonDisabled = () => {
    return !name || !street || !city || !state || isLoading;
  };

  const dispatch = useDispatch();

  const fetchUser = async () => {
    try {
      const { data } = await getUser().unwrap();

      if (data != null) {
        dispatch(
          updateUser({
            user: data,
          })
        );
      }
    } catch (error) {
      console.error("Failed to fetch user: ", error);
    }
  };

  const handleSuccess = async () => {
    const response = await createSubscription({
      lookupKey: plan,
    }).unwrap();
    if (response.data) {
      await fetchUser();
      navigate("/home");
    }
  };

  const handleBusinessInfoSubmit = async () => {
    try {
      setPaymentFormOpen(false);
      const { data } = await createOrganisation({
        name,
        email: user.email,
        address: `${street}, ${city}, ${state}`,
      }).unwrap();

      handleSuccess();

      // dispatch(
      //   updateUser({
      //     user: data.user,
      //   })
      // );

      // handleStepChange(step + 1);
      // navigate("/home");
    } catch (error) {
      if (!error.status) setErrMsg("No response from server");
      else if (error.status === 400 || error.status === 404)
        setErrMsg(error.data.message);
      else setErrMsg("An error occurred. Please try again.");
    }
  };

  return (
    <div className="relative flex flex-col w-full gap-8 mt-8 sm:w-11/12 base:py-18">
      {loading && <Loading loading={loading} />}
      <div className="flex flex-col gap-8">
        <img
          src={skorboardmain_logo}
          alt="logo"
          className="-ml-2 logo-skorboard2"
        />
        <h1 className="m-0 text-2xl font-bold">
          Tell us about your Organization
        </h1>
      </div>
      <div className="flex flex-col w-80 gap-6">
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Legal Business Name
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="text"
            name="text"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            placeholder="John Doe's Company"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Website
          </label>
          <input
            type="url"
            id="url"
            name="url"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            placeholder="www.johndoe.com"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Business Address
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="email"
            name="email"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            placeholder="123 Main Street"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            City
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="text"
            name="text"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            placeholder="New York City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            State
            <span className="text-red-500">*</span>
          </label>
          <select
            name="state"
            id="state"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            value={state}
            onChange={(e) => setState(e.target.value)}
          >
            <option value="" className="text-primarygrayborder">
              Select your State
            </option>
            {states.map((state) => (
              <option value={state} key={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
        {errMsg && (
          <div className="flex flex-col gap-2">
            <p className="text-red-500">{errMsg}</p>
          </div>
        )}
        <button
          disabled={isButtonDisabled()}
          onClick={async () => {
            if (
              user?.subscription?.lookupKey == null ||
              (user?.subscription?.hasDefaultPaymentMethod === false &&
                user?.subscription?.lookupKey !== plan)
            ) {
              setPaymentFormOpen(true);
            } else {
              handleBusinessInfoSubmit();
            }
          }}
          className="flex items-center justify-center w-full gap-2 p-2 mt-8 font-semibold text-white rounded-lg bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
        >
          Next
          <div className="relative flex flex-row">
            <PlayArrow
              style={{
                color: "#fff",
                width: "18px",
                height: "18px",
                objectFit: "contain",
              }}
            />
            <PlayArrow
              style={{
                color: "#fff",
                width: "18px",
                height: "18px",
                objectFit: "contain",
                position: "absolute",
                left: -5,
              }}
            />
          </div>
        </button>
        {/* <button
          disabled={minStep === step}
          onClick={() => handleStepChange(step - 1)}
          className="flex items-center justify-center w-full gap-2 p-2 mt-8 font-semibold text-black bg-white rounded-lg shadow-sm"
        >
          Go back to previous step
        </button> */}
      </div>
      <PaymentMethodModal
        paymentFormOpen={paymentFormOpen}
        setPaymentFormOpen={setPaymentFormOpen}
        handleSuccess={handleBusinessInfoSubmit}
      />
      <p className="w-full mt-12 text-xs text-center">
        2024 Skorboard Inc. All rights reserved.
      </p>
    </div>
  );
};

export default BusinessInfo;
